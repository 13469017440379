import React from 'react';

import excelFileParser from './batchExcelParser';
import StepLoading from './StepLoading';

import { connect } from 'react-redux';
import { parseFile } from './uploaderActions';

function FileParserComponent({ file, clientId, scNumber, callback }) {
  const handleParseResult = React.useCallback(() => {
    // console.log('fileParser useEffect');
    excelFileParser(file, clientId, scNumber).then((parsed) => {
      callback(parsed);
    });
  }, [file, callback, clientId, scNumber]);

  React.useEffect(() => {
    handleParseResult();
  }, [handleParseResult]);

  return (
    <div>
      <StepLoading message={`Parsing ${file.name}...`} />
    </div >
  );
}

const mapStateToProps = (state) => ({
  file: state.uploader.file,
  scNumber: state.uploader.scNumber,
  clientId: state.uploader.clientId
});

const mapDispatchToProps = (dispatch) => ({
  callback: (data) => {
    dispatch(parseFile(data))
  }
});

const FileParser = connect(mapStateToProps, mapDispatchToProps)(FileParserComponent);
export default FileParser;
