import React from "react";
import ClaimHeaderFileTablePagination from "./ClaimHeaderFileTablePagination";
import ClaimHeaderFileTableHeader from "./ClaimHeaderFileTableHeader";
import ClaimHeaderFileTableBody from "./ClaimHeaderFileTableBody";

function ClaimHeaderFileTable() {

	return (
		<div>
			<table id="portalSubmissionReportTable"
			       className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
				<ClaimHeaderFileTableHeader/>
				<ClaimHeaderFileTableBody/>
			</table>
			<ClaimHeaderFileTablePagination/>
		</div>
	)
}

export default ClaimHeaderFileTable;