/** uploader actions */
export const SELECT_FILE = 'SELECT_FILE_CORRECTION';
export const PARSE_FILE = 'PARSE_FILE_CORRECTION';
export const VALIDATE_DATA = 'VALIDATE_DATA_CORRECTION';
export const SET_ERRORS = 'SET_ERRORS_CORRECTION';
export const UPLOAD_DATA = 'UPLOAD_DATA_CORRECTION';
export const MOVE_STEP_UP = 'MOVE_STEP_UP_CORRECTION';
export const MOVE_STEP_DOWN = 'MOVE_STEP_DOWN_CORRECTION';
export const RESET = 'RESET_CORRECTION';
export const REVALIDATE = 'REVALIDATE_CORRECTION';

/** uploader action creators */
export const moveStepDown = () => ({ type: MOVE_STEP_DOWN })
export const moveStepUp = () => ({ type: MOVE_STEP_UP })

// const validateData = (data) => ({ type: VALIDATE_DATA, data });
export const parseFile = (data) => ({ type: PARSE_FILE, data });

export const setErrors = (errors) => ({ type: SET_ERRORS, errors });
export const reset = () => ({ type: RESET });
export const revalidate = (data) => ({ type: REVALIDATE, data });

// const uploadFile = (file) => ({ type: UPLOAD_FILE, file });