import * as XLSX from 'xlsx';
import moment from 'moment';

function parse(file) {
  return new Promise((resolve, reject) => {
    excelReader(file)
      .then((rows) => {
        const mapped = rows.map(rowMapper);
        resolve(mapped);
      })
      .catch((errors) => {
        // console.error(errors);
        resolve([]);
      });
  });
}

const formatDateToStr = (dateStr) => {
  try {
    if (!dateStr) {
      return '';
    }

    return moment(dateStr).format('YYYYMMDD');
  } catch (error) {}

  return '';
};

function rowMapper(row) {
  return {
    storeNumber: row[0],
    registerNumber: row[1],
    dateOfPurchase: formatDateToStr(row[2]),
    transactionNumber: row[3] ? row[3].toLocaleString('fullwide', { useGrouping: false }) : '',
    repairTicketNumber: row[4],
    firstName: row[5],
    lastName: row[6],
    phone: row[7],
    unitCost: row[8],
    status: row[9],
    claimId: row[10],
    address: row[11],
    city: row[12],
    state: row[13],
    zip: row[14],
    failureDate: formatDateToStr(row[15]),
  };
}

function excelReader(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary', cellDates: true });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, range: 1 });
      resolve(dataParse);
    };
    reader.readAsBinaryString(file);
  });
}

export default parse;
