import config from '../../services/config.json';
import caller from '../../services/caller';
import userManager from '../../services/userManager';

export const clientService = (function () {
  const service = {
    getClientList: async () => {
      const user = userManager.getUser();
			console.log('user ', user)
      if (user.isClaimsAdmin()) {
        return await service.fetchClientsAdmin();
      } else if (user.isServicer()) {
				if(user.clientId) {
					const clientDetails = await service.fetchClientById(user.clientId)
					return clientDetails ? [clientDetails] : []
				} else if(user.scNumber!==undefined && user.scNumber !== '-1' && user.scNumber?.length>0) {
					return await service.fetchClientsServicer(user.scNumber);
				}
      } else if (user.isClient()) {
        const client = await service.fetchClientById(user.clientId);
        return [client];
      }
    },
    fetchClientsAdmin: async () => {
      try {
        const clientId = userManager.clientId ? userManager.clientId : '';
        const url = `${config.url.clientProfile}/${clientId}`;
        const data = await caller.get(url);
        return JSON.parse(data);
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    fetchClientsServicer: async (scNumber) => {
      try {
        const url = `${config.url.servicerDetails}/clients/?scNumber=${scNumber}`
        const clients = await caller.get(url);
        return JSON.parse(clients);

      } catch (error) {
        console.error(error);
        return [];
      }
    },
    fetchClientById: async (clientId) => {
      try {
        const url = `${config.url.clientProfile}/${clientId}`
        const clients = await caller.get(url);
        const parsed = JSON.parse(clients);
        return parsed[0];

      } catch (error) {
        console.error(error);
        return null;
      }
    }

  }
  return service;
})();
