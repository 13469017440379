const ROLE_CLAIMS_ADMINISTRATOR = 'CLAIMS_ADMINISTRATOR';
const ROLE_CLAIMS_ANALYST = 'CLAIMS_ANALYST';
const ROLE_SERVICE_USER = 'SERVICE_USER';
const ROLE_CLIENT = 'CLIENT';

const ITEM_KEY_TOKEN = 'jca_token';
const ITEM_KEY_USER = 'jca_user';

export function getUser() {
  // const isDev = 'development' === process.env.NODE_ENV;
  // const user = isDev ? devUser() : prodUser();
  const user = prodUser();
  // console.log(`${process.env.NODE_ENV} user is ${JSON.stringify(user)}`);
  return user ? wrapWithFunctions(user) : null;
}

function wrapWithFunctions(user) {
  return {
    ...user,
    isClaimsAdmin: () => ROLE_CLAIMS_ADMINISTRATOR === user.role || ROLE_CLAIMS_ANALYST === user.role,
    isClient: () => ROLE_CLIENT === user.role,
    isServicer: () => ROLE_SERVICE_USER === user.role,
  }
}

export function setUser(user) {
  return localStorage.setItem(ITEM_KEY_USER, Buffer.from(JSON.stringify(user)).toString('base64'));
}

export function setToken(token) {
  return localStorage.setItem(ITEM_KEY_TOKEN, token);
}

export function getToken() {
  return localStorage.getItem(ITEM_KEY_TOKEN);
}

function prodUser() {
  const storedUser = localStorage.getItem(ITEM_KEY_USER);
  return storedUser ? JSON.parse(Buffer.from(storedUser, 'base64').toString('utf-8')) : null;
}

// function devUser() {
//   const user = {
//     clientId: '4790491',
//     scNumber: '0058476',
//     pwdChangedTime: null,
//     createTimestamp: null,
//     mail: 'andrew.eleptico@asurion.com',
//     uid: '',
//     role: ROLE_CLAIMS_ADMINISTRATOR,
//     userName: 'devmode-user'
//   }

//   return user;
// }

export function reset() {
  setUser(null);
  setToken(null);
}

const userManFns = {
  getUser, setUser, setToken, getToken, reset
}

export default userManFns;
