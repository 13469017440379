import PortalSubmissionForm from './PortalSubmissionForm'
import ClaimHeaderFileTable from "./ClaimHeaderFileTable";
import React from "react";
import Header from "../Header";
import Footer from "../Footer";

function PortalSubmission() {
	return (
		<div>
			<Header title='Portal Submission Report' subtitle='JCA Portal' />

			<div className="container">
				<div className="section">
					<div className="form">
						<div className="hero is-fullheight">
							<div className="box">
								<PortalSubmissionForm />
								<ClaimHeaderFileTable />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default PortalSubmission;