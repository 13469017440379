import React from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import jsonToXls from './claimHeaderFileXls';
import caller from '../../services/caller';
import config from '../../config/config.json';

function ClaimHeaderFileTableBody({ claimHeaderFileList = [] }) {
  function downloadFileName(batchId) {
    const urlWithParams = new URLSearchParams({ batchId: batchId });
    caller
      .get(config.api.downloadClaimHeaderFile + '?' + urlWithParams)
      .then((response) => {
        let jsonResponse = JSON.parse(response);

        console.log(jsonResponse);

        let wb = XLSX.utils.book_new();
        let workSheet = XLSX.utils.aoa_to_sheet(jsonToXls(jsonResponse));
        XLSX.utils.book_append_sheet(wb, workSheet, 'Claims');
        XLSX.writeFile(wb, jsonResponse.fileName);
      })
      .catch((error) => {
        console.error(error);
        //swallow the error and don't log anything
      });
  }

  return (
    <tbody>
      {claimHeaderFileList.map(function (claimHeaderFile, index) {
        return (
          <tr key={index}>
            <td>
              {isXLS(claimHeaderFile.fileName) ? (
                <button className='button is-size-6 is-text has-text-link has-text-left' style={{ padding: '0' }} onClick={() => downloadFileName(claimHeaderFile.batchId)}>
                  {claimHeaderFile.fileName}
                </button>
              ) : (
                <span className='is-size-6'>{claimHeaderFile.fileName}</span>
              )}
            </td>
            <td>{claimHeaderFile.clientId}</td>
            <td>{claimHeaderFile.scNumber}</td>
            <td>{claimHeaderFile.batchId}</td>
            <td className='is-size-7'>{claimHeaderFile.processType}</td>
            <td>{claimHeaderFile.totalClaims}</td>
            <td>{claimHeaderFile.totalRejected}</td>
            <td>{claimHeaderFile.status}</td>
            <td>{claimHeaderFile.createdDate}</td>
            {/* <td className="is-size-6"><Link to={`/downloadreports?batchId=${claimHeaderFile.batchId}`}>download</Link></td> */}
          </tr>
        );
      })}
    </tbody>
  );
}

const mapStateToProps = (state) => {
  return {
    claimHeaderFileList: state.portalSubmission.claimHeaderList,
  };
};

const isXLS = (fileName = '') => {
  try {
    return fileName.toLowerCase().endsWith('xls') || fileName.toLowerCase().endsWith('xlsx');
  } catch (e) {
    return false;
  }
};

export default connect(mapStateToProps)(ClaimHeaderFileTableBody);
