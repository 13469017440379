import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routeService } from './routeService';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route {...rest} render={props => (
      routeService.isLogin() && restricted ? <Redirect to="/login" /> 
      : <Component {...props} />
    )} />
  );
};

export default PublicRoute;
