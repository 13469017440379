import userManager from '../../services/userManager';
const httpClient = require('../../services/httpClient.ts');
const config = require('../../config/config.json');

export const loginService = (function loginService() {
  return {
    authenticate: async (username, password) => {
      try {
        // console.log(`authentication: ${username}`);
        const url = `${config[process.env.REACT_APP_ENVIRONMENT]['AUTH_URL']}`;
        const data = {
          method: 'AUTHENTICATION',
          body: { username, password }
        }
        let result = await httpClient.post(url, data);
        console.log('auth result', result);
        if (401 === result.statusCode) {
          return false;
        }

        const { token, adUser } = result; 
        userManager.setUser(adUser);
        userManager.setToken(token);
        
        return result;
      }
      catch (e) {
        console.error(e);
        return null;
      }
    }
  };
})();
