import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import StepScreen from './StepScreen';

function JCATemplate(props) {
  return (
    <div className="hero is-fullheight">
        <div>
          <Header title={props.title} subtitle='Jewelry Claims Portal' />
          <div className="container">
            {props.children}
          </div>
        </div>
        <Footer />
      </div>
  );
}

export default function NsfPaymentRelease () {
  const title = 'NSF Payment Release';
  return (
    <JCATemplate title={title}>
      <div>
        <StepScreen />
      </div>
    </JCATemplate>
  );
}