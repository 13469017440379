import React from 'react';

class LoadingModal extends React.Component {
  render() {
    const { active, message, subMessage, isDark = true } = this.props;
    const textClassName = isDark ? 'has-text-white' : '';

    return (
      <div className={active ? "modal is-active" : "modal"}>
        {isDark ? <div className="modal-background" ></div> : null}
        <div className="modal-content">
          <div className="section">
            <h1 className={`title has-text-centered ${textClassName}`}>{message}</h1>
            <h2 className={`subtitle has-text-centered ${textClassName}`}>{subMessage}</h2>
            <br />
            <div className={`has-text-centered ${textClassName}`}>
              <span className="icon">
                <i className="fa fa-spinner fa-spin fa-3x"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingModal;
