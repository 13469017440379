import React from 'react';
import StepLoading from './StepLoading';
import ErrorDisplay from './ValidationErrorDisplay';
import ValidDisplay from './ValidationValidDisplay';
import { isValid } from './requiredFields';

import { connect } from 'react-redux';
import { moveStepUp, setErrors } from './uploaderActions';
import { routeService } from '../routing/routeService';

import ajv from './ajv/ajv-ext';
const validator = ajv();

function FileContentValidatorComponent({ data = [], validation, callback }) {
  // console.log('validator component');
  const requestArr = data.claimHeaderList;

  React.useEffect(() => {
    // console.log('validator use effect');
    validate(requestArr, data).then((result) => {
      callback(result);
    });
  }, [data, requestArr, callback]);

  if (validation.running) {
    return <StepLoading message={`Validating requests...`} />;
  }

  if (validation.errors && validation.errors.length) {
    return <ErrorDisplay />;
  } else {
    return <ValidDisplay />;
  }
}

function validate(requestArr = [], data = {}) {
  return new Promise((resolve, reject) => {
    const result = [];
    requestArr.forEach((request, index) => {
      validator(request);

      if (!validator.errors) {
        validator.errors = [];
      }

      if (!routeService.hasControl('sameClientUpload')) {
        if (
          request.ServiceCenterNo &&
          request.ServiceCenterNo !== -1 &&
          request.ServiceCenterNo !== '-1' &&
          request.ServiceCenterNo !== data.scNumber
        ) {
          validator.errors.push({
            message: 'Invalid service center number',
            errorCode: 'DT-002',
            column: 'serviceCenterNumber',
          });
        }
      }

      if ('' + request.ClientNo !== '' + data.clientId) {
        validator.errors.push({
          message: 'Invalid Client Number',
          errorCode: 'DT-002',
          column: 'clientNumber',
        });
      }

      if (
        request.ClientNo &&
        (request.ClientNo === '4790491' || request.ServiceCenterNo === 4790491) &&
        (request.StoreNoPurchase === 9999 || request.StoreNoPurchase === '9999')
      ) {
        validator.errors.push({
          message: 'Invalid store number purchased',
          errorCode: 'DT-002',
          column: 'storeNumberPurchased',
        });
      }

      const isZero = !request.claimsLines || !request.claimsLines.length || !request.TotalCost;
      const isX999 =
        request.claimsLines &&
        request.claimsLines.length === 1 &&
        request.claimsLines[0].repairCode === 'X999';

      const isBYOT = request.FailureReasonCode === 'BYOT';
      const isP010 =
        request.claimsLines &&
        request.claimsLines.length === 1 &&
        request.claimsLines[0].repairCode === 'P010';
      const isP008 =
        request.claimsLines &&
        request.claimsLines.length === 1 &&
        request.claimsLines[0].repairCode === 'P008';

      // console.log('isBYOT', isBYOT);
      // console.log('isP010', isP010);
      // console.log('isP008', isP008);

      const zeroExceptionRule1 = isX999;
      const zeroExceptionRule2 =
        isBYOT &&
        request.claimsLines?.map((x) => x.repairCode)?.every((i) => i === 'P008' || i === 'P010');

      // if claims is zero and does not belong to the exception rule
      if (isZero && !zeroExceptionRule1 && !zeroExceptionRule2) {
        validator.errors.push({
          message: `Claim has zero total cost or Empty amount lines`,
          errorCode: 'DT-002',
          column: 'amountLines',
        });
      }

      if (request.FailureReasonCode === 'BYOT' && !request.ServiceCenterShipDate) {
        validator.errors.push({
          message: 'BYOT has empty ServiceCenterShipDate',
          errorCode: 'DT-002',
          column: 'ServiceCenterShipDate',
        });
      }

      const requiredFieldsErrors = isValid(request);
      //console.log(`RequiredFieldsError: ` + JSON.stringify(requiredFieldsErrors));
      if (requiredFieldsErrors && requiredFieldsErrors.length) {
        validator.errors = [].concat(validator.errors).concat(requiredFieldsErrors);
      }

      // console.log(validator.errors);

      if (validator.errors && validator.errors.length) {
        result.push({
          index: index,
          row: request,
          errors: validator.errors,
        });
      }
      resolve(result);
    });
  });
}

const mapStateToProps = (state) => ({
  data: state.uploader.parsed,
  validation: state.uploader.validation,
});

const mapDispatchToProps = (dispatch) => ({
  callback: (errors) => {
    if (errors && errors.length) {
      dispatch(setErrors(errors));
    } else {
      dispatch(moveStepUp());
    }
  },
});

const FileContentValidator = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FileContentValidatorComponent);
export default FileContentValidator;
