import React from 'react';
import Header from './Header';

class PageNotFound extends React.Component {
  render() {
    return (
      <div>
        <Header title='Jewelry Claim Workbench' />
        <div className="hero is-fullheight bgImageDiamondCover">
          <div className="">
            <br />
            <br />
            <div className="section">
              <article className="message is-danger" style={{ fontSize: "1.2rem" }}>
                <div className="message-body">
                  <div className="content">
                    <div className="messageContent">
                      Sorry. Page Not Found! 
                </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
