import React from 'react';
import { connect } from 'react-redux';

import StepLoading from './StepLoading';
import { moveStepUp } from './uploaderActions';
import { correctionService } from './correctionService';


function UploadRequestComponent({ data, callback }) {

  React.useEffect(() => {
    correctionService.submitCorrections(data).then(() => {
      console.log('Corrections successfully submitted!');
      callback();
    });
  });

  return <StepLoading message="Uploading Corrections..." />
}

const mapStateToProps = (state) => ({
  data: state.correction.parsed
});

const mapDispatchToProps = (dispatch) => ({
  callback: () => {
    dispatch(moveStepUp());
  }
});

const UploadingProcess = connect(mapStateToProps, mapDispatchToProps)(UploadRequestComponent);
export default UploadingProcess;
