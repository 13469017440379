import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Loading from '../LoadingModal';
import ClaimsInfoTable from './ClaimsInfoTable';
import ClaimsInfoSearch from './ClaimsInfoSearch';
import WorkbenchInfo from './WorkbenchInfo';
import ClaimStatusCount from './ClaimStatusCount';
import ClaimsInfoPagination, { usePagination } from './ClaimsInfoTablePagination';
import userManager from '../../services/userManager';
import { queryClaimInfoByStatusAndGroup, queryClaimSearch } from './workbenchServices';

const stateProto = {
  claimStatus: '',
  repairTicketNumber: ''
}

function useClaimsInfo(clientId, scNumber, assignedGroup, repairTicketNumber, claimStatus, activePage = 0, totalCountPerPage = 10) {
  const [claimsInfo, setClaimsInfo] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);


  const onSuccess = React.useCallback((result) => {
    const records = (JSON.parse(result));
    setClaimsInfo(records);
    setLoading(false);
  }, []);

  const onFailure = React.useCallback(error => {
    console.error(error);
    setClaimsInfo([]);
    setTotalCount(0);
    setLoading(false);
  }, []);

  // effect when repair ticket number changes
  React.useEffect(() => {
    if (!repairTicketNumber) {
      return;
    }

    setLoading(true);
    let max = totalCountPerPage * activePage;
    let offset = max - totalCountPerPage;

    queryClaimSearch(repairTicketNumber, clientId, scNumber, assignedGroup, offset, max)
      .then(onSuccess)
      .catch(onFailure);
  }, [clientId, scNumber, assignedGroup, repairTicketNumber, activePage, totalCountPerPage, onSuccess, onFailure]);

  // effect when claim status changes
  React.useEffect(() => {
    if (!claimStatus || repairTicketNumber) {
      return;
    }

    setLoading(true);
    let max = totalCountPerPage * activePage;
    let offset = max - totalCountPerPage;

    queryClaimInfoByStatusAndGroup(claimStatus, clientId, scNumber, assignedGroup, offset, max)
      .then(onSuccess)
      .catch(onFailure);
  }, [clientId, scNumber, assignedGroup, repairTicketNumber, claimStatus, activePage, totalCountPerPage, onSuccess, onFailure]);


  const clear = () => {
    setClaimsInfo([]);
  }

  React.useEffect(() => {
    if (claimsInfo && claimsInfo.length > 0) {
      setTotalCount(claimsInfo[0].RESULT_COUNT);
    } else {
      setTotalCount(0);
    }
  }, [claimsInfo]);

  React.useEffect(() => {
    if (!claimStatus && !repairTicketNumber) {
      clear();
    }
  }, [claimStatus, repairTicketNumber]);

  return {
    claimsInfo,
    loading,
    // clear,
    totalCount
  }
}

function ClientWorkBench(props) {

  const { clientId, scNumber } = props;
  const user = userManager.getUser();
  const assignedGroup = user.role;

  const [state, setState] = React.useState(stateProto);
  const pagination = usePagination(0);
  const claimsInfo = useClaimsInfo(clientId, scNumber, assignedGroup, state.repairTicketNumber, state.claimStatus, pagination.activePage);

  React.useEffect(() => {
    pagination.setTotalItemsCount(claimsInfo.totalCount)
  }, [pagination, claimsInfo.totalCount]);

  const handleStatusCountClick = (claimStatus) => {
    pagination.setActivePage(1);
    setState({
      ...state,
      claimStatus
    })
  }

  const clear = () => {
    pagination.setActivePage(1);
    setState({...stateProto});
  }

  const handleSearchClick = (repairTicketNumber) => {
    pagination.setActivePage(1);
    setState({
      ...state,
      repairTicketNumber
    })
  }

  const handleRefreshClick = () => {
    const { repairTicketNumber, claimStatus } = state;
    clear();
    
    setTimeout(() => {
      setState({
        ...state,
        claimStatus,
        repairTicketNumber
      })
    }, 150);
  }

  const { claimStatus, repairTicketNumber } = state;

  return (
    <div className="hero is-fullheight bgImageDiamondCover">

      <Header title="Jewelry Claim Workbench" />

      <div className="columns">
        <div className="column is-3">
          <WorkbenchInfo
            clientId={clientId}
            scNumber={scNumber}
            assignedGroup={assignedGroup} />

          <ClaimStatusCount
            clientId={clientId}
            scNumber={scNumber}
            assignedGroup={assignedGroup}
            onStatusClick={handleStatusCountClick} />
        </div>

        <div className="column">
          <ClaimsInfoSearch onSearchClick={handleSearchClick} />
          <ClaimsInfoTable
            claimsInfo={claimsInfo.claimsInfo}
            assignedGroup={assignedGroup}
            claimStatus={claimStatus}
            repairTicketNumber={repairTicketNumber}
            loading={claimsInfo.loading}
            onRefreshClick={handleRefreshClick}
            totalCount={claimsInfo.totalCount}
          />
          <div className="has-background-white">
            <ClaimsInfoPagination {...pagination} />
          </div>
        </div>
      </div>
      <Footer />
      <Loading isDark={false} active={claimsInfo.loading} />
    </div>
  );
}


export default ClientWorkBench;
