export const requiredFields = [
  'CustFirstName',
  'CustLastName',
  'StreetAddress',
  'City',
  'State',
  'ZipCode',
  'Country',
  'FailureReasonCode',
  'FailureDate',
  'ServiceCenterShipDate'
];

/**
 * Check if all required fields are present
 * @param {Object} claim 
 */
export function isValid(claim) {
  const errors = [];
  for (let field of requiredFields) {
    if(!claim[field]) {
      errors.push({
        message: `${field} is missing`,
        errorCode: "DT-002",
        column: field
      });
    }
  }

  return errors;
}