import { combineReducers } from "redux";
import uploader from './components/uploader/uploaderReducers';
import correction from './components/correction/uploaderReducers';
import entitlement from './components/entitlement/entitlementReducers';
import portalSubmission from './components/portalsubmission/portalSubmissionReducer';

const PortalApp = combineReducers({
  uploader,
  correction,
  portalSubmission,
  entitlement
});

export default PortalApp;