import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import moment from 'moment';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
    '&': {
      padding: 20
    }
  },
  heading: {
    paddingBottom: 5,
    paddingTop: 10,
    fontWeight: "bold"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReadOnlyTextField({ value, label }) {
  const inputProps = {
    readOnly: true,
  };

  return (
    <TextField
      variant="outlined"
      InputProps={inputProps}
      label={label}
      value={value} />
  );
}

export default function FullScreenDialog(props) {
  const classes = useStyles();
  // console.log('Edit Modal');
  const [row, setRow] = React.useState({ ...props.data.row });

  const handleApply = () => {
    props.onApply(props.data.index, row);
    props.onClose();
  }

  const handleChange = (event) => {
    let { name, value } = event.target;
    value = value ? value : undefined;
    const updated = { ...row };
    updated[name] = value;
    setRow(updated);
  }

  // const computeTotal = (claimsLines = []) => {
  //   let laborSum = 0;
  //   let taxSum = 0;
  //   let shipSum = 0;
  //   let partsSum = 0;

  //   claimsLines.forEach(amt => {
  //     laborSum += amt.laborAmt || 0;
  //     taxSum += amt.taxAmt || 0;
  //     shipSum += amt.shipAmt || 0;
  //     partsSum += amt.partsAmt || 0;
  //   })

  //   return {
  //     laborSum,
  //     taxSum,
  //     shipSum,
  //     partsSum,
  //     total: laborSum + taxSum + shipSum + partsSum
  //   }
  // }

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Edit Correction Details
            </Typography>
            <Button autoFocus color="inherit" onClick={handleApply}>
              Apply
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <p className={classes.heading}>Correction Details</p>
          <Divider />
          <div>
            <ReadOnlyTextField variant="outlined" onChange={handleChange} label="Client ID" name="clientId" value={row.clientId || ''} />
            <ReadOnlyTextField variant="outlined" onChange={handleChange} label="Claim Status" name="claimStatus" value={row.claimStatus || ''} />
            <ReadOnlyTextField variant="outlined" onChange={handleChange} label="Sent Date" name="sentDate" value={row.sentDate || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Store Number" name="storeNumber" value={row.storeNumber || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Register Number" name="registerNumber" value={row.registerNumber || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Purchase Date" name="purchaseDate" value={row.purchaseDate || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Transaction Number" name="transactionNumber" value={row.transactionNumber || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Repair Ticket Number" name="repairTicketNumber" value={row.repairTicketNumber || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Firstname" name="firstname" value={row.firstname || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Lastname" name="lastname" value={row.lastname || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Phonenumber" name="phonenumber" value={row.phonenumber || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Claim Amount" name="claimAmount" value={row.claimAmount || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Status" name="status" value={row.status || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Claim Id" name="claimId" value={row.claimId || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Address" name="address" value={row.address || ''} />
            <TextField variant="outlined" onChange={handleChange} label="City" name="city" value={row.city || ''} />
            <TextField variant="outlined" onChange={handleChange} label="State" name="state" value={row.state || ''} />
            <TextField variant="outlined" onChange={handleChange} label="Zip" name="zip" value={row.zip || ''} />
            <DatePickerCustom request={row} field="failureDate" label="Failure Date" />
            <TextField variant="outlined" onChange={handleChange} label="NewPurchStoreNumber" name="newPurchStoreNumber" value={row.newPurchStoreNumber || ''} />
            <TextField variant="outlined" onChange={handleChange} label="NewPurchRegisterNumber" name="newPurchStoreRegisterNumber" value={row.newPurchStoreRegisterNumber || ''} />
            <DatePickerCustom request={row} field="newPurchaseDate" label="New Purchase Date" />
            <TextField variant="outlined" onChange={handleChange} label="NewPurchTransactionNo" name="newPurchTransNumber" value={row.newPurchTransNumber || ''} />
          </div>

        </div>
      </Dialog>
    </div>
  );
}

function getValidDate(dateStr) {
  const date = moment.utc(dateStr);
  if (date.isValid()) {
    return date.toDate();
  };

  return;
}

function useDateInput(request, field) {
  const dateStr = request[field];
  const [date, setDate] = React.useState(getValidDate(dateStr));

  const handleDateChange = (date) => {
    console.log('selected date', date);
    setDate(date);
    request[field] = moment.utc(date).format('YYYY-MM-DD');
  }

  return {
    value: date,
    onChange: handleDateChange
  }
}

function DatePickerCustom({ request, field, label = field }) {
  const dateState = useDateInput(request, field);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="yyyy-MM-dd"
        margin="normal"
        id={field}
        name={field}
        label={label}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...dateState}
      />
    </MuiPickersUtilsProvider>
  );
}