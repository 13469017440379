import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import InfoMessage from '../InfoMessage';
import CustomDatePicker from '../common/CustomDatePicker';
import userManager from '../../services/userManager';
import { reportService } from './reportService';
import { ClientSelectorFormComponent as ClientSelector } from '../client/ClientSelector'
import CustomSnackbar, { SEVERITY, useSnackbar } from '../common/CustomSnackbar';

const getInitialState = (user) => ({
  startDate: '',
  endDate: '',
  clientId: user.clientId,
  reportType: 'RECONCILIATION',
  batchId: '',
  email1: user.mail,
  email2: '',
  email3: '',
  source: 'PORTAL',
  status: 'NEW',
  requestedBy: user.userName
});

function ClaimsReporting() {
  return (
    <div>
      <Header title='Claims Reporting' subtitle='JCA Portal' />

      <div className="container">
        <div className="section">
          <Form />
        </div>
      </div>

      <Footer />
    </div>
  );
}

function Form() {

  const user = userManager.getUser();
  const [request, setRequest] = React.useState(getInitialState(user));

  const resetForm = () => {
    setRequest(getInitialState(user));
  }

  const snackbarProps = useSnackbar();

  const onSubmit = async () => {
    if (!request.clientId) {
      snackbarProps.handleOpen(SEVERITY.error, 'Client is required');
      return;
    }

    try {
      const data = {
        ...request,
        reportEmail: [request.email1, request.email2, request.email3].filter(email => !!email).join(',')
      }
      delete data.email1;
      delete data.email2;
      delete data.email3;

      await reportService.submit(data);
      snackbarProps.handleOpen(SEVERITY.success, 'Report request submission successful!');
      resetForm();
    } catch (error) {
      console.error(error);
      snackbarProps.handleOpen(SEVERITY.error, 'Report request submission failed. Please contact administrator.');
    }
  }

  const onChange = (event) => {
    const { name, value } = event.target;
    const obj = { ...request };
    obj[name] = value;
    setRequest(obj);
  }

  return (
    <div className="form">
      <div className="hero is-fullheight" style={{ padding: "1em" }}>

        <div className="columns">
          <div className="column is-5 is-offset-1">

            <div className="box">

              <div className="field">
                <label className="label">Report Type</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select>
                      <option value="RECONCILIATION">Reconciliation Report</option>
                    </select>
                  </div>
                </div>
              </div>

              <ClientSelector value={request.clientId} name="clientId" onChange={onChange} required={true} />

              <div className="field is-grouped">
                <div className="control">
                  <label className="label">From Date</label>
                  {/* <input className="input" type="date" placeholder="YYYY-MM-DD" /> */}
                  <CustomDatePicker request={request} field="startDate" label={null} size="small" style={{ marginTop: "unset", maxWidth: "195px" }} />
                </div>
                <div className="control">
                  <label className="label">To Date</label>
                  {/* <input className="input" type="date" placeholder="YYYY-MM-DD" /> */}
                  <CustomDatePicker request={request} field="endDate" label={null} size="small" style={{ marginTop: "unset", maxWidth: "195px" }} />
                </div>
              </div>

              <div className="field">
                <label className="label">Batch ID</label>
                <div className="control">
                  <input className="input" type="text" name="batchId" onChange={onChange} />
                </div>
              </div>

              <div className="field">
                <label className="label">Report will be sent to the following emails:</label>
                <div className="control" style={{ marginBottom: "8px" }}>
                  <input className="input is-readonly" type="email" value={request.email1} name="email1" readOnly="readOnly" />
                </div>
                <div className="control" style={{ marginBottom: "8px" }}>
                  <input id="email2" className="input" type="email" placeholder="Additional Email 1" name="email2" onChange={onChange} />
                </div>
                <div className="control" style={{ marginBottom: "8px" }}>
                  <input id="email3" className="input" type="email" placeholder="Additional Email 2" name="email3" onChange={onChange} />
                </div>
              </div>

              <div className="buttons">
                <button className="button is-info" onClick={onSubmit}>
                  <span className="icon">
                    <i className="fas fa-paper-plane"></i>
                  </span>
                  <span>Submit</span>
                </button>
                <button className="button">
                  <span className="icon">
                    <i className="fas fa-trash"></i>
                  </span>
                  <span>Clear</span>
                </button>
              </div>

            </div>
          </div>

          <div>
            <CustomSnackbar {...snackbarProps} />
          </div>

          <div className="column is-offset-1" >
            <InfoMessage title="Search Tips" messageComponent={SearchTips} />
          </div>
        </div>
      </div>
    </div>
  );
}

function SearchTips() {
  return (
    <ul>
      <li>Search using more than one criteria will reduce the amount of data returned.             </li>
      <li>Try to use the batch number or transaction number in your search.                        </li>
      <li>When using the date range keep the range as small as possible for faster/slower results. </li>
      <li>Larger results from date range searches will be limited to a thousand records.           </li>
      <li>When using the Consumer Phone Number please use numbers and dashes only.                 </li>
      <li>Please use the correct format for searches using the Transaction Number.                 </li>
    </ul>
  );
}

export default ClaimsReporting;
