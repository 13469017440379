import React from 'react';
import userManager from '../../services/userManager';

export default function WorkbenchInfo({ clientId, scNumber }) {

  const user = userManager.getUser();
  const { userName, role: assignedGroup } = user;

  return (
    <div className="notification is-size-6">

      <div className="columns has-text-centered has-text-light has-background-dark">
        <div className="column">
          <div className="title is-5">Welcome {userName}</div>
        </div>
      </div>

      <div className="columns">
        <div className="column has-text-right is-4">
          <span className="label">Group</span>
        </div>
        <div className="column">
          {assignedGroup}
        </div>
      </div>

      <div className="columns">
        <div className="column has-text-right is-4">
          <span className="label">Client ID</span>
        </div>
        <div className="column">
          {clientId}
        </div>
      </div>

      <div className="columns">
        <div className="column has-text-right is-4">
          <span className="label">Service Center</span>
        </div>
        <div className="column">
          {scNumber}
        </div>
      </div>

    </div>
  );
}