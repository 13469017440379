import React, { useState } from "react";
import { connect } from 'react-redux';
import { searchClaimHeaderFile, clearHeaderFileSearch } from './portalSubmissionAction';

function PortalSubmissionForm({ onSearchClaimHeaderFile, onClear }) {

	let initFields = {
		scNumber: '',
		batchId: '',
		processType: '',
		fileName: '',
		startDate: '',
		endDate: ''
	}

	const[fields, setFields] = useState(initFields);

	function onChangeFields(e) {
		setFields({...fields, [e.target.name]:  e.target.value});
	}

	function clear() {
		setFields(initFields);
		onClear();
	}

	function submit() {
		onSearchClaimHeaderFile(fields, 1);
	}

	return (
		<div className="columns">
			<div className="column">
				<div className="field">
					<label className="label">SC Number</label>
					<div className="control">
						<input name="scNumber"
						       onChange={onChangeFields}
						       value={fields.scNumber}
						       className="input">
						</input>
					</div>
				</div>

				<div className="field">
					<label className="label">Process Type</label>
					<div className="control">
						<div className="select is-fullwidth">
							<select name="processType"
							        onChange={onChangeFields}
							        value={fields.processType}>
								<option value="">All</option>
								{/* <option value="ORIGINAL">Original Submission</option>
								<option value="RESUBMISSION">Resubmission</option> */}
								<option value="PORTAL_SUBMISSION">Portal Submission</option>
								<option value="FTP_UPLOAD">FTP Upload</option>
							</select>
						</div>
					</div>
				</div>

				<div className="field is-grouped">
					<div className="control">
						<label className="label">From Date</label>
						<input name="startDate"
						       onChange={onChangeFields}
						       value={fields.startDate}
						       className="input"
						       type="date"
						       placeholder="YYYY-MM-DD" />
					</div>
					<div className="control">
						<label className="label">To Date</label>
						<input name="endDate"
						       onChange={onChangeFields}
						       value={fields.endDate}
						       className="input"
						       type="date"
						       placeholder="YYYY-MM-DD" />
					</div>
				</div>
			</div>

			<div className="column">
				<div className="field">
					<label className="label">File Name</label>
					<div className="control">
						<input name="fileName"
						       onChange={onChangeFields}
						       value={fields.fileName}
						       className="input"
						       type="text" />
					</div>
				</div>

				<div className="field">
					<label className="label">Batch ID</label>
					<div className="control">
						<input name="batchId"
						       onChange={onChangeFields}
						       value={fields.batchId}
						       className="input"
						       type="text" />
					</div>
				</div>

				<br/>

				<div className="buttons">
					<button className="button is-info" onClick={submit}>
						<span className="icon">
							<i className="fas fa-paper-plane"/>
						</span>
						<span>Submit</span>
					</button>
					<button className="button" onClick={clear}>
						<span className="icon">
							<i className="fas fa-trash"/>
						</span>
						<span>Clear</span>
					</button>
				</div>
			</div>
		</div>
	)
}

const mapDispatchToProps = dispatch => {
	return {
		onSearchClaimHeaderFile: (param, activePage) => {
			dispatch(searchClaimHeaderFile(param, activePage));
		},
		onClear: () => {
			dispatch(clearHeaderFileSearch());
		}
	};
};



export default connect(null, mapDispatchToProps)(PortalSubmissionForm);