import React from 'react';

export default function ClaimsInfoSearch(props) {
  const [repairTicketNumber, setRepairTicketNumber] = React.useState('');

  const handleChange = (event) => {
    const value = event.target.value;
    setRepairTicketNumber(value);
  }

  const handleSearchClick = (e) => {
    e.preventDefault();
    props.onSearchClick(repairTicketNumber);
  }

  const handleClearClick = (e) => {
    e.preventDefault();
    setRepairTicketNumber('');
    props.onSearchClick('');
  }

  return (
    <div className="has-background-white">
      <br />
      <form name="claimsInfoSearchForm" noValidate>
        <div className="column is-offset-3 is-6">
          <div className="field is-grouped">
            <div className="control is-expanded">
              <input
                className="input"
                type="text"
                name="repairTicketNumber"
                placeholder="Repair Ticket Number"
                value={repairTicketNumber}
                onChange={handleChange} />
            </div>
            <div className="control">
              <button type="submit" className="button is-info" onClick={handleSearchClick}>
                Search Claim
              </button>
            </div>
            <div className="control">
              <button className="button" onClick={handleClearClick}>
                Clear
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}