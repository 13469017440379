import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import userManager from '../../services/userManager';
import { ClientSelectorFormComponent as ClientSelector } from '../client/ClientSelector';
import { ServicerSelector } from '../servicer/ServicerSelector';
import { Redirect } from 'react-router-dom';
import CustomSnackbar, { useSnackbar, SEVERITY } from '../common/CustomSnackbar';

class MyWorkBench extends React.Component {

  render() {
    return (
      <div className="bgImageDiamondCover">
        <Header title='Jewelry Claim Workbench' />

        <div className="container">
          <div className="section">
            <Form />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const stateProto = {
  clientId: '',
  scNumber: 'ALL_SC',
  redirectTo: ''
}

function Form() {
  const user = userManager.getUser();
  const [state, setState] = React.useState({
    ...stateProto,
    clientId: user.clientId,
    scNumber: user.scNumber
  });

  const snackbar = useSnackbar();

  const onClientChange = (event) => {
    const clientId = event.target.value;
    if (!clientId) {
      return;
    }

    setState({
      ...state,
      clientId
    });
  }

  const handleServicerChange = (event) => {
    const scNumber = event.target.value;

    if (!scNumber) {
      return;
    }

    setState({
      ...state,
      scNumber
    });
  }

  const handleSubmit = () => {
    const { clientId, scNumber } = state;
    
    if (!clientId) {
      snackbar.handleOpen(SEVERITY.error, "Please select a client");
      return;
    }

    const scParam = scNumber === '-1' ? 'ALL_SC' : scNumber;
    console.log(`Selected clientId: ${clientId} and scNumber: ${scNumber}`);
    const redirectTo = `/clientworkbench?clientId=${clientId}&scNumber=${scParam}`;

    setState({
      ...state,
      redirectTo
    })
  }

  const { clientId, scNumber, redirectTo } = state;

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  return (
    <div className="form">
      <div className="hero" style={{ padding: "1em", paddingBottom: "4em" }}>

        <div className="columns">
          <div className="column is-4 is-offset-4">
            <div className="box">

              <ClientSelector name="clientId" onChange={onClientChange} value={clientId} required={true} />
              <ServicerSelector name="servicer" onChange={handleServicerChange} value={scNumber} clientId={clientId} />

              <div className="buttons">
                <button className="button is-info" onClick={handleSubmit}>
                  <span className="icon">
                    <i className="fas fa-paper-plane"></i>
                  </span>
                  <span>Submit</span>
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
      <CustomSnackbar {...snackbar} />
    </div>
  );
}


export default MyWorkBench;
