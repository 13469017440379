import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { moveStepDown } from './uploaderActions';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(1),
  }
}));

const BackBtnComponent = ({ onClick, disabled = false }) => {
  const classes = useStyles();
  return (
    <Button color="primary" disabled={disabled} className={classes.backButton} onClick={onClick}>
      Back
  </Button>
  );
}

const mapStateToProps = (state) => {
  const { activeStep, maxStep } = state.correction;

  // 2 cases where next button is disabled
  const isMaxStep = activeStep === maxStep;
  const isOnFirstStep = 0 === activeStep;

  return {
    activeStep: state.correction.activeStep,
    disabled: isMaxStep || isOnFirstStep
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: () => {
      dispatch(moveStepDown());
    }
  }
}

const NextBtn = connect(
  mapStateToProps,
  mapDispatchToProps)(BackBtnComponent);

export default NextBtn;