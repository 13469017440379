import httpClient from '../../services/caller';
import config from '../../services/config.json';

export const correctionService = (function correctionService() {
    return {
        submitCorrections: async (claims = []) => {
            try {
                while (claims.length > 0) {
                    const portion = claims.splice(0, 500);
                    // console.log('portion', JSON.stringify(portion));
                    // console.log('portion-length', portion.length) ;
                    await httpClient.post(config.url.submitCorrections, portion);
                }

                return true;
            }
            catch (e) {
                console.error(e);
                return Promise.reject("submitCorrections: Error occur when trying to post dal");
            }
        }
    };
})();
