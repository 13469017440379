import React from 'react';
import { getClaimById, getAmountLinesByClaimId } from './manualPaymentService';

export function useClaim(claimId) {
  const [claim, setClaim] = React.useState(undefined);

  React.useEffect(() => {
    getClaimById(claimId).then((result) => {
      setClaim(result);
    });
  }, [claimId]);

  return {
    claim,
    setClaim
  };
}

export function useAmountLines(claimId) {
  const [amountLines, setAmountLines] = React.useState([]);

  React.useEffect(() => {
    getAmountLinesByClaimId(claimId).then((result) => {
      setAmountLines(result);
    });
  }, [claimId]);

  return {
    amountLines,
    setAmountLines
  };
}

