import React from 'react';
import moment from 'moment';
import ClaimActionModal, { useFullScreenModal } from './ClaimActionModal';

export default function ClaimsInfoTable({ claimsInfo = [], claimStatus = '', repairTicketNumber = '', assignedGroup, onRefreshClick, totalCount }) {

  const modalState = useFullScreenModal();

  const handleClick = (cl) => {
    const params = {
      claimId: cl.CLAIM_ID,
      repairTicketNumber: cl.REPAIR_TICKET_NUMBER,
      claimStatus: cl.STATUS
    };
    modalState.setParams(params);
    modalState.show(cl);
  }

  return (
    <div className="section has-background-white">

      <ClaimActionModal state={modalState} />

      <div>
        {
          claimStatus ?
            <p><strong>Claim Status:</strong> {claimStatus}</p>
            : null
        }

        <p><strong>Total No. or Records:</strong> {totalCount}</p>
        <br />
        <button className="button" style={{ float: 'right', position: 'relative', bottom: "+60px" }} onClick={onRefreshClick}>Refresh</button>
      </div>

      {
        repairTicketNumber ?
          <div className="has-text-centered has-text-info">
            <p>Claim(s) with repair ticket number '{repairTicketNumber}' and group '{assignedGroup}'</p>
          </div>
          :
          ''
      }


      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-size-6">
        <thead>
          <tr>
            <td>Claim ID</td>
            <td>Repair Ticket Number</td>
            <td>Client</td>
            <td>Service Center Number</td>
            <td>Status</td>
            <td>Last Process Date</td>
          </tr>
        </thead>
        <tbody>
          {claimsInfo.length > 0 ?

            claimsInfo.map((cl) =>
              <tr key={cl.CLAIM_ID}>
                <td>{cl.CLAIM_ID}</td>
                <td>
                  <button className="button is-text is-inverted has-text-info" onClick={() => handleClick(cl)}>
                    {cl.REPAIR_TICKET_NUMBER}
                  </button>
                </td>
                <td>{cl.CLIENT_ID}</td>
                <td>{cl.SC_NUMBER}</td>
                <td>{cl.STATUS}</td>
                <td>{moment.utc(cl.CREATION_DATE).format('YYYY-MM-DD hh:mm:ss')}</td>
              </tr>
            )

            :

            <tr>
              <td colSpan="6">
                <div className="has-text-centered" style={{ padding: "15px" }}>
                  Empty Records. Please select a status
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>

    </div>
  );
}