import React from 'react';
import moment from 'moment';
import { getClaimErrorsByClaimId } from './workbenchServices';

export function useClaimErrors(claimId, onLoad) {
  const [claimErrors, setClaimErrors] = React.useState([]);

  React.useEffect(() => {
    getClaimErrorsByClaimId(claimId)
      .then(claimErrors => {
        setClaimErrors(claimErrors);

        const hasTimelySubmission = () => {
          const filtered = claimErrors
            .map(e => e.ERROR_MESSAGE || '')
            .map(msg => msg.trim().toLowerCase())
            .filter(msg => 'timely submission' === msg)

          // console.log(claimErrors);
          // console.log(filtered);
          return filtered.length > 0;
        }

        if (onLoad) {
          onLoad({
            claimErrors,
            hasTimelySubmission
          });
        }
      })
      .catch(console.error);
  }, [claimId, onLoad]);

  return claimErrors;
}

export default function ClaimErrorsTable(props) {
  const claimErrors = useClaimErrors(props.claimId, props.onLoad);

  return (
    <div className="section">
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth" style={{ fontSize: "0.80rem" }}>
        <thead>
          <tr>
            <th>Error Code</th>
            <th>Error Message</th>
            <th>Creation Date</th>
            <th>Create By</th>
          </tr>
        </thead>
        <tbody>
          {
            claimErrors.map((c, index) =>
              <tr style={{ background: "#ff000026" }} key={index}>
                <td style={{ border: "1px solid #ffaabb" }}>{c.ERROR_CODE}</td>
                <td style={{ border: "1px solid #ffaabb" }}>{c.ERROR_MESSAGE}</td>
                <td style={{ border: "1px solid #ffaabb" }}>{moment.utc(c.CREATION_DATE).format('YYYY-MM-DD hh:mm:ss')}</td>
                <td style={{ border: "1px solid #ffaabb" }}>{c.CREATED_BY}</td>
              </tr>
            )
          }
        </tbody>
      </table>

    </div>
  );
}
