import React from 'react';
import { Link } from 'react-router-dom'

function NavBar() {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="">
      <nav className="navbar is-light">
        <div className="container">

          <div className="navbar-brand">
            <Link to="/home" className="" style={{ paddingTop: "8px" }}>
              { /**
              <p className="title" style={{ color: "white" }}>JCA Portal</p>
              */}
              <img src="/images/repairnow-logo.png" alt="asurion.com" />
            </Link>
            <span
              className={open ? "navbar-burger burger is-active" : "navbar-burger burger"}
              data-target="navbarMenu" onClick={() => setOpen(!open)}>
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>

          <div id="navbarMenu" className={open ? 'navbar-menu is-active' : 'navbar-menu'}>
            <div className="navbar-end">
              <Link to="/home" className="navbar-item">Home</Link>
              <Link to="/faq" className="navbar-item">FAQ</Link>
              <Link to="/logout" className="navbar-item">Logout</Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
