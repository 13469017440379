import React from 'react';
import Button from '@material-ui/core/Button';

import { moveStepUp } from './uploaderActions';
import { connect } from 'react-redux';

const NextBtnComponent = ({ onClick, disabled = false }) => (
  <Button variant="contained" color="primary" disabled={disabled} onClick={onClick}>
    Next
  </Button>
);

const mapStateToProps = (state) => {
  const { activeStep, maxStep, fileSelected, validation } = state.entitlement;

  // 3 cases where next button is disabled
  const isMaxStep = activeStep >= maxStep;
  const fileMissing = activeStep === 0 && !fileSelected;
  const hasInvalidContents = activeStep === 2 && validation.errors.length > 0;

  return {
    activeStep: state.entitlement.activeStep,
    disabled: isMaxStep || fileMissing || hasInvalidContents
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: () => {
      dispatch(moveStepUp());
    }
  }
}

const NextBtn = connect(
  mapStateToProps, 
  mapDispatchToProps)(NextBtnComponent);

export default NextBtn;