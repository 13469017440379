import React from 'react';

export default function ModalFormButtons(props) {
  const { processed, claimId, claimStatus, claimErrors, handleAction, clientId } = props;
  return (
    <React.Fragment>
      {
        !processed ?
          <div className="buttons is-centered">
            {
              (!['NSF_PROCESSED', 'NO_SALES_FOUND', 'SALE_NOT_FOUND', 'NSF_SENT'].includes(claimStatus)) ?
                <button className="button" onClick={() => handleAction('CORRECTION')}>Submit Corrections</button> : ''
            }

            {
              ['PREADJUDICATION_FAILED', 'ADJUDICATION_FAILED', 'INVALID_DATA'].includes(claimStatus) || ['4790491', '5387604', '0056913', '0059986'].includes(String(clientId)) ?
                <button className="button" onClick={() => handleAction('INVALID_CONTRACT')}>Invalid Contract</button> : ''
            }

            {
              ['PREADJUDICATION_FAILED', 'CONTRACT_MATCH_FAILED', 'CLAIM_FAILED', 'ADJUDICATION_FAILED'].includes(claimStatus)
                || (claimErrors && claimErrors.hasTimelySubmission ? claimErrors.hasTimelySubmission() : false) ?
                <button className="button" onClick={() => handleAction('OVERRIDE')}>Override</button> : ''
            }

            {
              ['NO_SALES_FOUND', 'SALE_NOT_FOUND', 'NO_SALES_FOUND_NEW', 'NSF_SENT'].includes(claimStatus) ?
                <button className="button" onClick={() => handleAction('UPDATE')}>Update</button> : ''
            }

            <button className="button" onClick={() => handleAction('CANCEL')}>Cancel</button>

            {
              (!['NSF_PROCESSED', 'NO_SALES_FOUND'].includes(claimStatus)) ?
                <button className="button" onClick={() => handleAction('DENY')}>Deny</button> : ''
            }

            <button className="button" onClick={() => handleAction('MANUALLY_PAID')}>Manually Paid</button>
          </div>
          :
          <div className="column has-text-centered has-text-weight-bolder has-text-danger">
            <p>Claim {claimId} changes is already submitted</p>
          </div>
      }
    </React.Fragment>
  );
}