import React from 'react';


function InfoMessage({ title, message, messageComponent }) {
  return (
    <article className="message is-info">
      <div className="message-body">
        <div className="content">
          <div className="control has-icons-right">
            <label className="label">
              <span className="icon has-text-info is-large is-right">
                <i className="fas fa-info-circle fa-2x"></i>
              </span>
              {title}
            </label>
          </div>
          <div className="messageContent">
            {
              messageComponent ?
              messageComponent() : message
            }
          </div>
        </div>
      </div>
    </article>
  );
}

export default InfoMessage;
