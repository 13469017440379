import * as XLSX from 'xlsx';
import moment from 'moment';
// import { EntitlementFile } from './entitlementFile';
// import userManager from '../../services/userManager';

function isRowHeader(row) {
  const cl = row.clientNumber.toString().trim().toLowerCase() || '';
  if (cl.includes('client') && (cl.includes('number') || cl.includes('no.') || cl.includes('num') || cl.includes('no'))) {
    return true;
  }

  return false;
}

function parse(file, clientId) {
  return new Promise((resolve, reject) => {
    excelReader(file)
      .then((rows) => {
        let batchId = getBatchId();

        const rowMapper = getMapper(batchId);
        // const { userName, mail: email} = userManager.getUser()
        // let claimHeaderFileParam = {
        //   batchId: batchId,
        //   docId: batchId,
        //   createdBy: userName,
        //   email,
        //   fileName: file.name,
        //   clientId,
        //   scNumber
        // }

        // let entitlement = new EntitlementFile(claimHeaderFileParam);
        let entitlement = {
          clientId,
        };
        // const mapped = rows.filter(cols => !!cols.length).map(rowMapper).filter(row => !isRowHeader(row));
        const mapped = rows
          .filter((cols) => !!cols.length)
          .map(rowMapper)
          .filter((row) => !isRowHeader(row));

        // const claimHeaderList = [];
        // let claimHeader;
        // for (const claim of mapped) {
        //   if (claim.ClientNo && claim.ServiceCenterNo) {
        //     claimHeader = claim;
        //     claimHeaderList.push(claimHeader);
        //   } else {
        //     if (claimHeader)
        //       claimHeader.addAmounts(claim);
        //   }
        // }

        // entitlement.claimHeaderList = claimHeaderList;
        entitlement.claimHeaderList = mapped;
        resolve(entitlement);
      })
      .catch((errors) => {
        console.error(errors);
        resolve([]);
      });
  });
}

const defaultToEmpty = (field) => (field === null || field === undefined ? '' : field);

const defaultToEmptyDate = (field) => {
  if (!field) {
    return '';
  }

  const date = moment.utc(field);
  if (date.isValid()) {
    return date.format('YYYY-MM-DD');
  } else {
    return '';
  }
};

function getMapper(batchId) {
  return function rowMapper(row, index) {
    return {
      index,
      clientNumber: defaultToEmpty(row[0]),
      srNumber: defaultToEmpty(row[1]),
      repairEnvelopeNumber: defaultToEmpty(row[2]),
      storePurchaseNumber: defaultToEmpty(row[3]),
      terminalNumber: defaultToEmpty(row[4]),
      purchaseDate: defaultToEmptyDate(row[5]),
      transactionNumber: defaultToEmpty(row[6]),
      failureDate: defaultToEmptyDate(row[7]),
      scReceiveDate: defaultToEmptyDate(row[8]),
    };
  };
}

function getBatchId() {
  return moment.utc().format('YYYYMMDDHHmmss');
}

function excelReader(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary', cellDates: true });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      // console.log(dataParse);
      resolve(dataParse);
    };
    reader.readAsBinaryString(file);
  });
}

export default parse;
