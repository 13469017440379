import React from 'react';
import { Link } from 'react-router-dom';

const toBase64 = (json) => Buffer.from(JSON.stringify(json)).toString('base64');

export default function ClaimsTable({ claims = [], totalCount = 0 }) {
  return (
    <div className="" style={{ fontSize: "0.85em" }}>
      <p className="subtitle">Total No. of Records: {totalCount}</p>
      <table className="table is-striped is-hoverable is-fullwidth is-bordered">
        <thead>
          <tr>
            <th>History</th>
            <th>Client ID</th>
            <th>Service Request#</th>
            <th>Repair Envelope#</th>
            <th>Transaction#</th>
            <th>Customer Name</th>
            <th>Submit Date</th>
            <th>Batch Number</th>
            <th>Claim Status</th>
            <th>Amount Submitted</th>
            <th>Amount Approved</th>
            <th>Rejection Reason</th>
          </tr>
        </thead>
        <tbody>
          {
            claims.map((cl, index) =>
              <tr key={index}>
                <td><Link to={`/claimshistory/${toBase64(cl)}`}>History</Link></td>
                <td>{cl.CLIENT_ID}</td>
                <td>{cl.SR_NUMBER}</td>
                <td>{cl.REPAIR_TICKET_NUMBER}</td>
                <td>{cl.TRANSACTION_NUMBER}</td>
                <td>{cl.CUSTOMER}</td>
                <td>{cl.SUBMIT_DATE}</td>
                <td>{cl.BATCH_ID}</td>
                {/* <td>{cl.PORTAL_STATUS}</td> */}
                <td>{cl.STATUS}</td>
                <td>{(cl.LABOR_AMOUNT + cl.PARTS_AMOUNT + cl.SHIP_AMOUNT + cl.TAX_AMOUNT + cl.QST_AMOUNT + cl.GST_AMOUNT).toFixed(2)}</td>
                <td>{cl.APPROVED}</td>
                <td>{cl.ERROR_CODES}</td>
              </tr>)
          }
        </tbody>

      </table>
    </div>
  );
}
