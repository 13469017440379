import React from 'react';
import Divider from '@material-ui/core/Divider';
// import MainTemplate from '../MainTemplate';

import Header from '../Header';
import Footer from '../Footer';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import BatchFileSelector from './BatchFileSelector';
import FileParser from './FileParser';
import FileContentDisplay from './FileContentsDisplay';
import FileUploadComplete from './FileUploadComplete';
import FileContentValidator from './FileContentValidator';

import { connect } from 'react-redux';
import NextBtn from './NextBtn';
import BackBtn from './BackBtn';
import UploadingProcess from './UploadingProcess';
import { reset } from './uploaderActions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  topbottomspacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

function getSteps() {
  return ['Select Batch File', 'Parse File', 'Validate Claims', 'Upload Claims', 'Upload Complete'];
}

function getStepContent(activeStep, data) {
  switch (activeStep) {
    case 0:
      return <BatchFileSelector />
    case 1:
      return data.claimHeaderList && data.claimHeaderList.length ? <FileContentDisplay /> : <FileParser />
    case 2:
      return <FileContentValidator />
    case 3:
      return <UploadingProcess />
    default:
      return <FileUploadComplete />
  }
}


function UploadStepperComponent({ activeStep = 0, data = {}, onLoad }) {
  const classes = useStyles();
  const steps = getSteps();

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>
        <div>
          {getStepContent(activeStep, data)}
        </div>
        <Divider />
        <div className={classes.topbottomspacing}>
          <BackBtn />
          <NextBtn />
        </div>
      </div>

    </div >
  );
}

const mapStateToProps = (state) => ({
  activeStep: state.uploader.activeStep,
  data: state.uploader.parsed
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: () => {
    dispatch(reset());
  }
});

const UploadStepper = connect(mapStateToProps, mapDispatchToProps)(UploadStepperComponent);


export default function ClaimsUploader() {
  return (
    <div>
      <Header title='Batch Submission' subtitle='JCA Portal' />
      <div className="container">
        <div className="section">
         <UploadStepper />
        </div>
      </div>
      <Footer />
    </div>
  );
}