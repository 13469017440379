import React from 'react';
import { inputEnableChecker } from './enableFields';
import DatePicker from '../common/CustomDatePicker';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  input: {
    paddingBottom: "5px",
    paddingTop: "5px",
    height: "32px"
  }
}));


export default function ClaimDetailsEditTable(props) {

  const { claim, claimStatus, amountLines, handleAmountDetailsChange,  handleClaimDetailsChange} = props;
  const isEnable = inputEnableChecker(claimStatus);

  return (
    <div className="table-container">
      <table className="table is-bordered is-striped is-hoverable is-fullwidth claimErrorEditTable">
        <thead>
          <tr >
            <th>Client Number</th>
            <th>Service Ctr Number</th>
            <th>SR Number</th>
            <th>Repair Envelope Number</th>
            <th>Repair Store Number</th>
            <th>Purchase Store Number</th>
            <th>Register Number</th>
            <th>Date of Purchase</th>
            <th>Repair Transaction Number</th>
            <th>Transaction Number</th>
            <th>Customer Firstname</th>
            <th>Customer Lastname</th>
            <th>Street Address</th>
            <th>Address Line2</th>
            <th>Address Line3</th>
            <th>City</th>
            <th>State / Province</th>
            <th>Zip</th>
            <th>Country Code</th>
            <th>Area Code</th>
            <th>Phone Number</th>
            <th>Contract Product</th>
            <th>Product Description</th>
            <th>Product Code</th>
            <th>Brand</th>
            <th>Product Purchase Price</th>
            <th>Product Unit Price</th>
            <th>Renewal Contract</th>
            <th>Failure Date</th>
            <th>Service Ctr Receive Date</th>
            <th>Service Ctr Ship Date</th>
            <th>Work to be Done</th>
            <th>Authorization Number</th>
            <th>Failure Reason Code</th>
            <th>Valid Flag</th>
            <th>Repair Code</th>
            <th>Labor Cost</th>
            <th>Parts Cost</th>
            <th>Ship/Handle Cost</th>
            <th>Tax Cost</th>
            <th>Total</th>
            <th>Notes</th>
            <th>Claim ID</th>
          </tr>
        </thead>
        <tbody>
          <tr key={0}>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='clientId' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='scNumber' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='srNumber' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='repairTicketNumber' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='repairStoreNumber' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='purchaseStoreNumber' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='repairRegisterNumber' /></td>

            <td><CustomDatePicker isEnable={isEnable} claim={claim} field="productPurchaseDate" /></td>

            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='repairTransNumber' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='transactionNumber' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='firstName' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='lastName' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='address' /></td>

            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='addressLine2' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='addressLine3' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='city' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='state' /></td>

            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='zip' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='countryCode' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='phoneAreaCode' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='phoneNumber' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='oracleProduct' /></td>

            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='productDescription' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='productSku' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='brand' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='productPurchasePrice' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='unitPrice' /></td>

            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='renewal' /></td>

            <td><CustomDatePicker isEnable={isEnable} claim={claim} field="failureDate" /></td>
            <td><CustomDatePicker isEnable={isEnable} claim={claim} field="scReceiveDate" /></td>
            <td><CustomDatePicker isEnable={isEnable} claim={claim} field="scShipDate" /></td>

            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='workDone' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='authorizationNumber' /></td>
            <td><InputText claim={claim} isEnable={isEnable} onChange={handleClaimDetailsChange} field='failureCode' /></td>

            <td><InputTextAmtLine amountLines={amountLines} isEnable={isEnable} onChange={handleAmountDetailsChange} field='validFlag' index={0} /></td>
            <td><InputTextAmtLine amountLines={amountLines} isEnable={isEnable} onChange={handleAmountDetailsChange} field='repairCode' index={0} /></td>
            <td><InputTextAmtLine amountLines={amountLines} isEnable={isEnable} onChange={handleAmountDetailsChange} field='laborAmount' index={0} /></td>
            <td><InputTextAmtLine amountLines={amountLines} isEnable={isEnable} onChange={handleAmountDetailsChange} field='partsAmount' index={0} /></td>
            <td><InputTextAmtLine amountLines={amountLines} isEnable={isEnable} onChange={handleAmountDetailsChange} field='shipAmount' index={0} /></td>
            <td><InputTextAmtLine amountLines={amountLines} isEnable={isEnable} onChange={handleAmountDetailsChange} field='taxAmount' index={0} /></td>

            <td></td>

            {isEnable('notes') ?
              <td><input className="input" type="text" onChange={handleClaimDetailsChange} name="notes" value={claim.notes} style={{ minWidth: "300px" }} /></td>
              :
              <td><input className="input is-static readonly" type="text" value={claim.notes} style={{ minWidth: "300px" }} readOnly /></td>
            }

            <td><input className="input is-static readonly" type="text" name="claimId" value={claim.claimId} readOnly /></td>
          </tr>

          {amountLines.map((amt, index) =>
            index === 0 ? null :
              <tr key={index}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><InputTextAmtLine amountLines={amountLines} isEnable={isEnable} onChange={handleAmountDetailsChange} field='validFlag' index={index} /></td>
                <td><InputTextAmtLine amountLines={amountLines} isEnable={isEnable} onChange={handleAmountDetailsChange} field='repairCode' index={index} /></td>
                <td><InputTextAmtLine amountLines={amountLines} isEnable={isEnable} onChange={handleAmountDetailsChange} field='laborAmount' index={index} /></td>
                <td><InputTextAmtLine amountLines={amountLines} isEnable={isEnable} onChange={handleAmountDetailsChange} field='partsAmount' index={index} /></td>
                <td><InputTextAmtLine amountLines={amountLines} isEnable={isEnable} onChange={handleAmountDetailsChange} field='shipAmount' index={index} /></td>
                <td><InputTextAmtLine amountLines={amountLines} isEnable={isEnable} onChange={handleAmountDetailsChange} field='taxAmount' index={index} /></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}


function CustomDatePicker(props) {
  const { isEnable, claim, field, ...rest } = props;
  const classes = useStyles();

  if (isEnable(field)) {
    return (
      <DatePicker request={claim} field={field} {...rest} label={null} style={{ marginTop: "unset" }} size="small" height="25%" InputProps={{ className: classes.input }} />
    );
  } else {
    return <input type="text" className="input is-static readonly" readOnly value={claim[field]} />;
  }
}


function InputText({ claim, field, onChange, isEnable }) {
  return isEnable(field) ?
    (
      <input
        className='input'
        type="text"
        onChange={onChange}
        name={field}
        value={claim[field]} />
    )
    :
    <input type="text" className="input is-static readonly" readOnly value={claim[field]} />
}


function InputTextAmtLine({ amountLines, field, index, onChange, isEnable }) {
  if (!amountLines || !amountLines.length) {
    return (<span></span>)
  }

  return isEnable(field) ?
    (
      <input className="input" type="text"
        onChange={(event) => onChange(event, index)}
        name={field}
        value={amountLines[index][field]} />
    )
    :
    <input type="text" className="input is-static readonly" readOnly value={amountLines[index][field]} />
}