import dalConfig from '../config/config.json';
const API_KEY = `${dalConfig[process.env.REACT_APP_ENVIRONMENT]['XAPIKEY']}`;

export const getOptions = () => {
  return {
    'content-type': 'application/json',
    'accepts': 'application/json',
    'headers': {
      'x-api-key': API_KEY
    }
  };
};

export const optionsWithJsonData = (jsonData) => {
  return {
    ...getOptions(),
    json: true,
    body: jsonData
  };
};

const headerFns = {
  optionsWithJsonData, getOptions
};

export default headerFns;
