import { MOVE_STEP_UP, MOVE_STEP_DOWN, VALIDATE_DATA, UPLOAD_DATA, PARSE_FILE, SELECT_FILE, SET_ERRORS, RESET, REVALIDATE } from './uploaderActions';

const initialUploaderState = {
  activeStep: 0,
  maxStep: 4,
  fileSelected: false,
  uploaded: false,

  validation: {
    running: true,
    errors: []
  },

  file: undefined,
  parsed: [],
  scNumber: undefined,
  clientId: undefined
}


const uploader = (state = initialUploaderState, action) => {
  switch (action.type) {
    case RESET:
      return Object.assign({}, initialUploaderState);

    case MOVE_STEP_UP:
      return Object.assign({}, state, {
        activeStep: state.activeStep + 1
      });

    case MOVE_STEP_DOWN:
      const newStepValue = state.activeStep - 1;
      const appendState = {
        activeStep: newStepValue
      };

      if (0 === newStepValue) {
        appendState.file = undefined;
        appendState.fileSelected = false;
      }

      return Object.assign({}, state, appendState);

    case SELECT_FILE:
      const { file, scNumber, clientId } = action;
      // console.log(`file selected for client ${clientId} and scNumber ${scNumber}`, file);

      return Object.assign({}, state, {
        file: file,
        fileSelected: true,
        scNumber,
        clientId
      });
    case PARSE_FILE:
      return Object.assign({}, state, {
        parsed: action.data
      });
    case VALIDATE_DATA:
      return Object.assign({}, state, {
        validation: {
          running: true
        }
      });
    case REVALIDATE:
      return Object.assign({}, state, {
        validation: {
          running: true,
          errors: [],
        },
        activeStep: 2,
        parsed: action.data
      });
    case SET_ERRORS:
      return Object.assign({}, state, {
        validation: {
          running: false,
          errors: action.errors,
        }
      });
    case UPLOAD_DATA:
      return Object.assign({}, state, {
        uploaded: action.uploaded
      });
    default:
      return state;
  }
};

export default uploader;