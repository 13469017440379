import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routeService } from './routeService';


const PrivateRoute = (props) => {

  const { path } = props;
  //console.log('path', path);

  if(routeService.isLogin()) {
    if (routeService.hasControl(path)) {
      return <Route {...props} />
    } else {
      return <Redirect to="/home" />;
    }

  } else {
    return <Redirect to="/login" />;
  }

  // return (

  //   // Show the component only when the user is logged in
  //   // Otherwise, redirect the user to /signin page
  //   <Route {...rest} render={props => (
  //     routeService.isLogin() ?
  //       <Component {...props} />
  //       : <Redirect to="/login" />
  //   )} />
  // );
};

export default PrivateRoute;
