import React from 'react';
import { servicerService } from './servicerService';

export function useServicerDetails(clientId) {
  const [servicerList, setServicerList] = React.useState([]);

  React.useEffect(() => {
    setServicerList([]);
    servicerService.getServicerDetails(clientId).then(data => {
      // console.log(data);
      setServicerList(data);
    })
      .catch(console.error);
  }, [clientId]);

  return servicerList;
}

export function ServicerSelector(props) {
  const { clientId, required, ...rest } = props;
  const servicerDetails = useServicerDetails(clientId);

  if (!clientId) {
    return (
      <div className="field">
        <label className="label">Servicer{required ? <span className="has-text-danger"> *</span>: null }</label>
        <div className="control">
          <input className="input" type="text" value="Select Client First" readOnly="readOnly" />
        </div>
      </div>
    );
  }

  return (
    <div className="field">
      <label className="label">Servicer</label>
      {
        servicerDetails && servicerDetails.length ?
          <RenderServicers servicerDetails={servicerDetails} {...rest} />
          :
          <LoadingServicers />
      }
    </div>
  );
}

function RenderServicers({ servicerDetails, ...rest }) {
	console.log(servicerDetails)
  return (
    <div className="control">
      <div className="select is-fullwidth">
        <select {...rest} >
          <option value="">Select Servicer</option>
          {
            servicerDetails.map(sc => <option key={sc.SC_NUMBER} value={sc.SC_NUMBER}>{sc.SC_NAME}</option>)
          }
        </select>
      </div>
    </div>
  );
}

function LoadingServicers() {
  return (
    <div className="control is-loading">
      <input className="input" type="text" value="Loading Servicers..." readOnly="readOnly" />
    </div>
  );
}
