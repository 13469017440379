class ManualPaymentData {

  static from(claim) {
    const mp = new ManualPaymentData();
    mp.ClaimId = claim.claimId;
    mp.ServiceCenterNo = claim.scNumber;
    mp.ClientPartyId = claim.clientPartyId;
    mp.TransactionId = claim.transactionId;
    mp.BatchId = claim.batchId;
    mp.RepairCode = claim.repairTransNumber;
    mp.RepairStoreNumber = claim.repairStoreNumber;
    mp.RepairTransactionNumber = claim.repairTransNumber;
    mp.TransactionNumber = claim.transactionNumber;
    mp.FirstName = claim.firstName;
    mp.LastName = claim.lastName;
    mp.Address = claim.address;
    mp.AddressLine2 = claim.addressLine2 ? claim.addressLine2 : '';
    mp.AddressLine3 = claim.addressLine3 ? claim.addressLine3 : '';
    mp.City = claim.city;
    mp.State = claim.state;
    mp.CountryCode = claim.countryCode;
    mp.Zip = claim.zip;
    mp.PhoneAreaCode = claim.phoneAreaCode;
    mp.PhoneNumber = claim.phoneNumber;
    mp.EmailAddress = '';
    mp.PurchaseDate = claim.productPurchaseDate;
    
    mp.PurchasePrice = parseAmount(claim.productPurchasePrice);
    mp.UnitPrice = parseAmount(claim.productPurchasePrice);

    mp.SRNumber = claim.srNumber ? claim.srNumber : '9999';
    mp.RepairTicketNumber = claim.repairTicketNumber;
    mp.FailureReasonCode = claim.failureCode;
    mp.ProductSku = claim.productSku;
    mp.FailureDate = claim.failureDate;
    mp.ClientId = claim.clientId;
    
    mp.LaborCost = parseAmount(claim.approvedLabor);
    mp.PartsCost = parseAmount(claim.approvedParts);
    mp.ShippingHandlingCost = parseAmount(claim.approvedShip);
    mp.TaxCost = parseAmount(claim.approvedTax);
    mp.TotalCost = parseAmount(claim.totalApprovedAmount);

    mp.ProductSKU = claim.productSku;
    mp.AuthNumber = claim.authorizationNumber;
    mp.PurchaseStoreNumber = claim.purchaseStoreNumber;
    mp.RepairRegisterNumber = claim.repairRegisterNumber;
    mp.NsfFlag = claim.nsfFlag;
    mp.InvalidContractFlag = claim.invalidContractFlag;
    mp.PlanType = claim.planType;
    mp.ByotFlag = claim.byotFlag;
    mp.ReplFlag = claim.replFlag;
    mp.SCShipDate = claim.scShipDate;
    mp.CreationDate = claim.creationDate;
    mp.OverRideFlag = claim.overRideFlag;
    // mp.Underwriter = 'JCA-NSF';
    // mp.ProgramType = 'JCA-NSF';
    // mp.ClaimType = 'JCA-NSF';
    mp.Paygroup = claim.paygroup;
    mp.ServiceRequestId = '';
    // mp.NSFPayment = 'Y';

    return mp;
  }

  toJSON() {
    return Object.assign({}, this);
  }

  toUpdateJSON() {
    return {
      claimId: this.ClaimId,
      scNumber: this.ServiceCenterNo,
      clientPartyId: this.ClientPartyId,
      // transactionId: this.TransactionId,
      batchId: this.BatchId,
      repairStoreNumber: this.RepairStoreNumber,
      repairTransNumber: this.RepairTransactionNumber,
      transactionNumber: this.TransactionNumber,
      firstName: this.FirstName,
      lastName: this.LastName,
      address: this.Address,
      addressLine2: this.AddressLine2,
      addressLine3: this.AddressLine3,
      city: this.City,
      state: this.State,
      countryCode: this.CountryCode,
      zip: this.Zip,
      phoneAreaCode: this.PhoneAreaCode,
      phoneNumber: this.PhoneNumber,
      // emailAddress: this.EmailAddress,
      productPurchaseDate: this.PurchaseDate,
      productPurchasePrice: parseAmount(this.PurchasePrice),
      unitPrice: parseAmount(this.UnitPrice),
      
      srNumber: this.SRNumber,
      repairTicketNumber: this.RepairTicketNumber,
      failureCode: this.FailureReasonCode,
      productSku: this.ProductSku,
      failureDate: this.FailureDate,
      clientId: this.ClientId,
      
      approvedLabor: parseAmount(this.LaborCost),
      approvedParts: parseAmount(this.PartsCost),
      approvedShip: parseAmount(this.ShippingHandlingCost),
      approvedTax: parseAmount(this.TaxCost),
      totalApprovedAmount: parseAmount(this.TotalCost),

      authorizationNumber: this.AuthNumber,
      purchaseStoreNumber: this.PurchaseStoreNumber,
      repairRegisterNumber: this.RepairRegisterNumber,
      nsfFlag: this.NsfFlag,
      invalidContractFlag: this.InvalidContractFlag,
      planType: this.PlanType,
      byotFlag: this.ByotFlag,
      replFlag: this.ReplFlag,
      scShipDate: this.SCShipDate,
      // creationDate: this.CreationDate,
      overRideFlag: this.OverRideFlag,
      // underwriter: this.Underwriter,
      // programType: this.ProgramType,
      // claimType: this.ClaimType,
      // paygroup: this.Paygroup,
      // serviceRequestId: this.ServiceRequestId, //  srRecordId
      // nsfPayment: this.NSFPayment,			
    };
  }
}

function parseAmount(amt) {
  if (!amt) {
    return 0;
  }

  try {
    const fixed = parseFloat(amt).toFixed(2);
    return parseFloat(fixed);

  } catch(error) {
    console.error(error);
  } 
}

export default ManualPaymentData;
