import React from 'react';
import Header from './Header';
import Footer from './Footer';
import userManager from '../services/userManager';
import common from '../services/common';
import { Link } from 'react-router-dom';
import { ClientSelectorFormComponent as ClientSelector } from './client/ClientSelector';
import { routeService } from './routing/routeService';

const goldText = {
  color: "#d7b460",
  backgroundColor: "white"
}

class Home extends React.Component {

  render() {

    const user = userManager.getUser();

    return (
      <div className="hero is-fullheight">
        <div>
          <Header title='Welcome!' subtitle='Jewelry Claims Portal' />

          <div className="container">
            <div className="columns">
              <div className="column">
                {
                  user.isClient() ? <MenuSelectionClient /> :
                    user.isServicer() ? <MenuSelectionServicer /> :
                      user.isClaimsAdmin() ? <MenuSelectionClaimsAdmin /> : <MenuSelectionDefault />
                }
              </div>
              {
                user.isClient() ? <ClientInfoDisplay /> :
                  user.isServicer() ? <ServicerInfoDisplay /> : ''
              }
            </div>

            <div className="is-hidden">
              <ClientSelector />
            </div>
          </div>

        </div>
        <Footer />
      </div>
    );
  }
}

function MenuSelectionDefault() {
  return (
    <div className="section">
      <article className="message is-danger">
        <div className="message-body">
          <div className="content">
            <div className="messageContent">
              Your are not authorized to access this portal. Please contact the administrator.
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}

function MenuSelectionClient() {
  return (
    <div className="section">
      <nav className="panel">
        <div className="column is-offset-1">

          <Link to="/claimsearch" className="panel-block">
            <span className="panel-icon">
              <i className="fas fa-book" aria-hidden="true"></i>
            </span>
            Claims Search
          </Link>

          <Link to="/workbench" className="panel-block">
            <span className="panel-icon">
              <i className="fas fa-book" aria-hidden="true"></i>
            </span>
            My Work Bench
          </Link>

        </div>

      </nav>
    </div>
  );
}


function MenuSelectionServicer() {
  return (
    <div className="section">
      <div className="columns">
        {/* <div className="column">
          <nav className="panel">
            <p className="panel-heading" style={goldText}>Agreements</p>

            <Link to="/comingsoon" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Rates
                  </Link>

            <Link to="/files/ServiceAgreement2004.pdf" download className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Servicer Agreement
                  </Link>

          </nav>
        </div> */}

        <div className="column">
          <nav className="panel">
            <p className="panel-heading" style={goldText}>Validate Contract/Submit Claims</p>

            <Link to="/comingsoon" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Single Contract
            </Link>

            <Link to="/batchsubmission" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Batch Submission
            </Link>

            <Link to="/correctionfile" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Submit Correction File
            </Link>

            <Link to="/entitlement" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Entitlement Check
            </Link>

            {/* <Link to="/as400entitlement" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              AS/400 Jewelry Claims Entitlement
                  </Link> */}
          </nav>
        </div>

        <div className="column">

          <nav className="panel">
            <Link to="/claimsearch" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Claims Search
            </Link>

            <Link to="/reporting" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Reporting
            </Link>

            <Link to="/portalreport" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Portal Submission Report
            </Link>
          </nav>

          <nav className="panel">
            <Link to="/workbench" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              My Work Bench
            </Link>
          </nav>
        </div>

      </div>

    </div>
  );
}

function MenuSelectionClaimsAdmin() {
  return (
    <div className="section">
      <div className="columns">
        {/* <div className="column">
          <nav className="panel">
            <p className="panel-heading" style={goldText}>Agreements</p>

            <Link to="/comingsoon" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Rates
                  </Link>

            <Link to="/files/ServiceAgreement2004.pdf" download className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Servicer Agreement
                  </Link>

          </nav>
        </div> */}

        <div className="column">
          <nav className="panel">
            <p className="panel-heading" style={goldText}>Validate Contract/Submit Claims</p>

            <Link to="/comingsoon" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Single Contract
            </Link>

            <Link to="/batchsubmission" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Batch Submission
            </Link>

            <Link to="/correctionfile" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Submit Correction File
            </Link>

            <Link to="/entitlement" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Entitlement Check
            </Link>

            {routeService.hasControl('/manualpayment') ?
              < Link to="/manualpayment" className="panel-block">
                <span className="panel-icon">
                  <i className="fas fa-book" aria-hidden="true"></i>
                </span>
                Manual Payment
              </Link>
              : null}

            {routeService.hasControl('/nsfpaymentrelease') ?
              <Link to="/nsfpaymentrelease" className="panel-block">
                <span className="panel-icon">
                  <i className="fas fa-book" aria-hidden="true"></i>
                </span>
                NSF Payment Release
              </Link>
              : null}

            {/* <Link to="/as400entitlement" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              AS/400 Jewelry Claims Entitlement
                  </Link> */}
          </nav>
        </div>

        <div className="column">

          <nav className="panel">
            <Link to="/claimsearch" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Claims Search
            </Link>

            <Link to="/reporting" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Reporting
            </Link>

            <Link to="/portalreport" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Portal Submission Report
            </Link>

            <Link to="/downloadreports" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Download Reports
            </Link>
          </nav>

          <nav className="panel">

            <Link to="/workbench" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              My Work Bench
            </Link>

            <Link to="/clientmaintenance" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Client Maintenance
            </Link>

            <Link to="/resetpassword" className="panel-block">
              <span className="panel-icon">
                <i className="fas fa-book" aria-hidden="true"></i>
              </span>
              Reset Password
            </Link>
          </nav>
        </div>

      </div>

    </div >
  );
}

class ClientInfoDisplay extends React.Component {

  state = {
    client: undefined
  }

  componentDidMount = () => {
    const user = userManager.getUser();
    common.getClientById(user.clientId).then(client => {
      // console.log(client);
      this.setState({
        client,
        user
      });
    }).catch(console.error);
  }

  render() {
    const { user, client } = this.state;
    if (!client) {
      return <div className="column is-3"></div>
    }

    const { clientName } = client;
    return (
      <div className="column is-3">
        <br />
        <div className="notification">
          <div className="field">
            <label className="label">Client name:</label>
            <p className="content" style={{ fontSize: "0.85rem" }}>{clientName}</p>
          </div>
          <div className="field">
            <label className="label">Email:</label>
            <p className="content" style={{ fontSize: "0.85rem" }}>{user.mail}</p>
          </div>
          {/* <div className="field">
            <label className="label">Address:</label>
            <p className="content" style={{ fontSize: "0.85rem" }}>Test Address 1</p>
          </div> */}
          <br />
          <div className="field">
            <Link className="button is-fullwidth" to="/resetpassword">Change Password</Link>
          </div>
        </div>
      </div>
    );
  }
}

class ServicerInfoDisplay extends React.Component {

  state = {
    servicer: undefined,
    user: {}
  }

  componentDidMount = () => {
    const user = userManager.getUser();
    common.getClientServicerBySCNumber(user.scNumber).then(servicer => {
      // console.log(servicer);
      this.setState({
        servicer,
        user
      });
    }).catch(console.error);
  }

  render() {
    const { user, servicer } = this.state;
    if (!servicer) {
      return <div className=""></div>
    }

    const { VENDOR_NAME: name } = servicer;
    return (
      <div className="column is-3">
        <br />
        <div className="notification">
          <div className="field">
            <label className="label">Servicer name:</label>
            <p className="content" style={{ fontSize: "0.85rem" }}>{name}</p>
          </div>
          <div className="field">
            <label className="label">Email:</label>
            <p className="content" style={{ fontSize: "0.85rem" }}>{user.mail}</p>
          </div>
          {/* <div className="field">
            <label className="label">Address:</label>
            <p className="content" style={{ fontSize: "0.85rem" }}>Test Address 1</p>
          </div> */}
          <br />
          <div className="field">
            <Link className="button is-fullwidth" to="/resetpassword">Change Password</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
