import config from '../../config/config.json';
import caller from '../../services/caller';
import userManager from '../../services/userManager';

export async function getClaimsFiltered(filters) {
  const filtersBase64 = Buffer.from(JSON.stringify(filters)).toString('base64');
  let url = `${config.api.claimSearch}?filters=${filtersBase64}`

  try {
    const clients = await caller.get(url);
    return clients;

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getClaimById(claimId) {
  try {
    const url = `${config.api.claim}/getById?claimId=${claimId}`;
    const res = await caller.get(url);
    const claim = JSON.parse(res);
    return claim.length > 0 ? claim[0] : null;

  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getAmountLinesByClaimId(claimId) {
  try {
    const url = `${config.api.amountLines}?claimId=${claimId}`;
    const amountLines = await caller.get(url);
    return JSON.parse(amountLines);

  } catch (error) {
    console.error(error);
    return [];
  }
}

/**
 * 
 * @param {ManualPaymentData} manualPaymentData 
 */
export async function postManualPayment(manualPaymentData) {
  try {
    // https://rtm-cfm-sq-vip1.use1.sqa.aws.asurion.net/jcadal/claim/invoices/manualpayments
    const url = `${config.api.manualPayment}`;
    const result = await caller.post(url, manualPaymentData.toJSON());
    return result;

  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * 
 * @param {ManualPaymentData} manualPaymentData 
 */
export async function postClaimHeaderUpdate(manualPaymentData) {
  try {
    const payload = manualPaymentData.toUpdateJSON();
    const user = userManager.getUser();
    // console.log('user', user);
    if (user) {
      payload.lastUpdatedBy = user.userName;
    }

    const url = `${config.api.claim}/${manualPaymentData.ClaimId}`;
    const result = await caller.post(url, payload);
    return result;

  } catch (error) {
    console.error(error);
    return null;
  }
}
