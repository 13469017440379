import React from 'react';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// import moment from 'moment';
import moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');

function getValidDate(dateStr) {
  const date = moment(dateStr);
  // console.log('toMoment', date);
  if (date.isValid()) {
    return date.toDate();
  };

  return null;
}

function useDateInput(request, field, callback) {
  const dateStr = request[field];
  const [date, setDate] = React.useState(getValidDate(dateStr));

  const handleDateChange = (date) => {
    setDate(date);
    const dateObj = moment(date);
    
    request[field] = dateObj.isValid() ? dateObj.format('YYYY-MM-DD') : '';
    if (callback) {
      callback(request[field]);
    }
  }

  return {
    value: date,
    onChange: handleDateChange
  }
}

export default function CustomDatePicker({ request, field, label = field, callback, ...rest }) {
  const dateState = useDateInput(request, field, callback);

  return (
    <MuiPickersUtilsProvider moment={moment} utils={MomentUtils}>
      <KeyboardDatePicker
        autoOk
        disableToolbar
        variant="inline"
        inputVariant="outlined"
        format="YYYY-MM-DD"
        margin="normal"
        id={field}
        name={field}
        label={label}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        placeholder="YYYY-MM-DD"
        {...rest}
        {...dateState}
      />
    </MuiPickersUtilsProvider>
  );
}
