import caller from '../../services/caller';
import config from '../../config/config.json';

//TYPES
export const SEARCH_CLAIM_HEADER_FILE = 'SEARCH_CLAIM_HEADER_FILE';
export const CLEAR_CLAIM_HEADER_FILE = 'CLEAR_CLAIM_HEADER_FILE';

export const searchClaimHeaderFile = function(param, activePage, totalCountPerPage = 10) {
	return function(dispatch, getState) {
		let currentParam = param === null ? getState().portalSubmission.payload : param;
		let currentPayload = claimHeaderFileRequest(currentParam, activePage, totalCountPerPage)
		const urlWithParams = new URLSearchParams(currentPayload);
		caller.get(config.api.portalSubmission + "?" + urlWithParams).then(response => {
			let jsonResponse = JSON.parse(response);
			dispatch({
				type: SEARCH_CLAIM_HEADER_FILE,
				payload: currentPayload,
				data: jsonResponse,
				activePage: activePage
			});
		}).catch(() => {
			dispatch({
				type: SEARCH_CLAIM_HEADER_FILE,
				payload: currentPayload,
				data: {
					data: [],
					totalCount: 0
				},
				activePage: 0
			});
		});
	}
}

function claimHeaderFileRequest(data, activePage, totalCountPerPage) {
	let max = totalCountPerPage * activePage;
	let offset = max - totalCountPerPage;

	return {
		scNumber: data['scNumber'],
		batchId: data['batchId'],
		processType: data['processType'],
		fileName: data['fileName'],
		startDate: data['startDate'],
		endDate: data['endDate'],
		offset: offset,
		max: max
	}
}

export function clearHeaderFileSearch() {
	return { type: CLEAR_CLAIM_HEADER_FILE };
}
