import React from 'react';

import entitlementFileParser from './entitlementFileParser';
import StepLoading from './StepLoading';

import { connect } from 'react-redux';
import { parseFile } from './uploaderActions';

function FileParserComponent({ file, clientId, scNumber, callback }) {
  const handleParseResult = React.useCallback(() => {
    // console.log('fileParser useEffect');
    entitlementFileParser(file, clientId, scNumber).then((parsed) => {
      // console.log('parsed', parsed);
      callback(parsed);
    });
  }, [file, callback, clientId, scNumber]);

  React.useEffect(() => {
    handleParseResult();
  }, [handleParseResult]);

  return (
    <div>
      <StepLoading message={`Parsing ${file.name}...`} />
    </div >
  );
}

const mapStateToProps = (state) => ({
  file: state.entitlement.file,
  scNumber: state.entitlement.scNumber,
  clientId: state.entitlement.clientId
});

const mapDispatchToProps = (dispatch) => ({
  callback: (data) => {
    dispatch(parseFile(data))
  }
});

const FileParser = connect(mapStateToProps, mapDispatchToProps)(FileParserComponent);
export default FileParser;
