import httpClient from '../../services/caller';
import config from '../../config/config.json';

export async function processEntitlementCheck(data = []) {
    try {
        const result = await httpClient.post(config.api.entitlementCheck, data);
        // console.log('entitlement result: ', result);
        return JSON.parse(result);
    } catch(error) {
        console.error(error);
        throw error;
    }
}
