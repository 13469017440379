const { isEnvironmentOnLocal } = require('../util/environmentUtil');
const dalConfig = require('../config/config.json');
const BASE_URL = `${dalConfig[process.env.REACT_APP_ENVIRONMENT]['DAL_URL']}`;
const API_KEY = `${dalConfig[process.env.REACT_APP_ENVIRONMENT]['XAPIKEY']}`;
const userManager = require('./userManager');
const httpClient = require('./httpClient.ts');

export function get(uri, options) {
  let getOption = {
    method: 'GET'
  };

  return executeHttp(uri, null, Object.assign(getOption, options));
}

export function put(uri, data, options) {
  let putOption = {
    method: 'PUT'
  };

  return executeHttp(uri, data, Object.assign(putOption, options));
}

export function post(uri, data, options) {
  let postOption = {
    method: 'POST'
  };

  return executeHttp(uri, data, Object.assign(postOption, options));
}

export function executeHttp(uri, data, options) {
  if (isEnvironmentOnLocal()) {
    return _executeHttp(uri, data, options);
  } else {
    return executeHttpAws(uri, data, options);
  }
}

/** START: TEMPORARY FUNCTIONS for cors issue */
async function executeHttpAws(uri, data, options) {
  let envAwareUrl = BASE_URL;
  const payload = {
    ...options,
    data,
    resource: uri,
    token: userManager.getToken()
  }
  try {
    const result = await httpClient.post(envAwareUrl, payload);
    return JSON.stringify(result);

  } catch (error) {
    if (401 === error.statusCode) {
      userManager.reset();
      window.location.href = '/';
    } else {
      console.error(error.body);
      alert('An error occured! Please contact administrator');
    }
  }
}
/** END: TEMPORARY FUNCTIONS for cors issue */

function _executeHttp(uri, data, options) {
  let envAwareUrl = isEnvironmentOnLocal() ? BASE_URL + uri : BASE_URL;
  let defaultOptions = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      'Content-Type': 'application/json',
      'Accepts': 'application/json',
      "x-api-key": API_KEY
    },
  };

  if (data) {
    // if local
    // let body = JSON.stringify(data);
    let body = data;

    // if aws
    if (!isEnvironmentOnLocal()) {
      body = JSON.stringify({
        data: data,
        token: userManager.getToken(),
        resource: uri,
      });
    }

    defaultOptions.body = body;
  }

  // console.log(defaultOptions);

  if ('GET' === options.method) {
    return httpClient.get(envAwareUrl)
  } else if ('POST' === options.method) {
    return httpClient.postLocal(envAwareUrl, defaultOptions.body);
  } else if ('PUT' === options.method) {
    return httpClient.put(envAwareUrl, defaultOptions.body);
  }

  // return fetch(envAwareUrl, Object.assign(defaultOptions, options))
  //   .then((response) => {
  //     if (401 === response.status) {
  //       userManager.reset();
  //       window.location.href = '/';
  //     } else if ([400, 500].indexOf(response.status) > -1) {
  //       return Promise.reject(response);
  //     }
  //     else {
  //       return response.json();
  //     }
  //   })
  //   .then((data) => {
  //     return Promise.resolve(JSON.stringify(data));
  //   });
}

const callerFns = {
  get, post, put, executeHttp
};

export default callerFns;
