import React from 'react';
import Header from './Header';
import Footer from './Footer';

class ComingSoon extends React.Component {
  render() {
    return (
      <div>
        <Header title='Coming Soon!' subtitle='This portion of the site is still under construction, please stop back soon!' />
        <div className="hero is-fullheight">
      
        </div>
        <Footer />
      </div>
    );
  }
}

export default ComingSoon;
