import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Loading from '../Loading';
import common from '../../services/common';
import moment from 'moment';

class ClaimHistory extends React.Component {

  state = {
    claim: undefined,
    claimDetails: undefined,
    amounts: []
  }

  componentDidMount = async () => {

    const claimDecoded = Buffer.from(this.props.claimRecord, 'base64').toString();
    const claim = JSON.parse(claimDecoded);

    try {
      const claimId = claim.CLAIM_ID;
      const claimStr = await common.getClaimById(claimId);
      const amountLinesStr = await common.getAmountLinesByClaimId(claimId);

      const claimsArr = JSON.parse(claimStr);
      const amountsArr = JSON.parse(amountLinesStr);

      this.setState({
        claim,
        claimDetails: claimsArr.length > 0 ? claimsArr[0] : {},
        amounts: amountsArr
      });

    } catch (error) {
      console.error(error);
    }
  }

  render() {

    if (!this.state.amounts.length) {
      return <Loading />
    }

    return (
      <div>
        <Header title='Claims History' />

        <div className="hero is-fullheight">
          <div className="container">
            <div className="section">
              <ClaimsTable claims={[this.state.claim]} />
            </div>
            <div className="section">
              <ClaimHistoryDetails details={this.state.claimDetails} amounts={this.state.amounts} />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}



function ClaimsTable({ claims = [] }) {
  return (
    <div className="">
      <table className="table is-striped is-hoverable is-fullwidth is-bordered">
        <thead>
          <tr>
            <th>Client ID</th>
            <th>Service Request#</th>
            <th>Repair Envelope#</th>
            <th>Transaction#</th>
            <th>Submit Date</th>
            <th>Batch Number</th>
            <th>Claim Status</th>
            <th>Amount Submitted</th>
            <th>Amount Approved</th>
            <th>Rejection Reason</th>
          </tr>
        </thead>
        <tbody>
          {
            claims.map(cl =>
              <tr key={cl.CLAIM_ID}>
                <td>{cl.CLIENT_ID}</td>
                <td>{cl.SR_NUMBER}</td>
                <td>{cl.REPAIR_TICKET_NUMBER}</td>
                <td>{cl.TRANSACTION_NUMBER}</td>
                <td>{cl.SUBMIT_DATE}</td>
                <td>{cl.BATCH_ID}</td>
                {/* <td>{cl.PORTAL_STATUS}</td> */}
                <td>{cl.STATUS}</td>
                <td>{(cl.LABOR_AMOUNT + cl.PARTS_AMOUNT + cl.SHIP_AMOUNT + cl.TAX_AMOUNT + cl.QST_AMOUNT + cl.GST_AMOUNT).toFixed(2)}</td>
                <td>{cl.APPROVED}</td>
                <td>{cl.ERROR_CODES}</td>
              </tr>)
          }
        </tbody>

      </table>
    </div>
  );
}

class ClaimHistoryDetails extends React.Component {

  render() {
    const { details: claim, amounts } = this.props;
    console.log(amounts);
    return (
      <div className="">
        <table className="table is-striped is-hoverable is-fullwidth is-bordered">
          <thead>
            <tr>
              <th>Customer Info</th>
              <th>Dates</th>
              <th>Product Info</th>
              <th>Service Info</th>
              <th>Repair Info</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p className="content">
                  {claim.firstName} {claim.lastName} <br />
                  {claim.address} {claim.city}, <br />
                  {claim.state} {claim.zip}<br />
                  ({claim.phoneAreaCode}) {claim.phoneNumber}
                </p>
              </td>
              <td>
                <p className="content">
                  Purchase: {formatDate(claim.productPurchaseDate)}<br />
                  Failure: {formatDate(claim.failureDate)}<br />
                  SC Received: {formatDate(claim.scReceiveDate)}<br />
                  SC Shipped: {formatDate(claim.scShipDate)}<br />
                  Created: {formatDate(claim.creationDate)}
                </p>
              </td>
              <td>
                <p className="content">
                  Product Description: {claim.productDescription} <br />
                  Price: {claim.unitPrice}<br />
                  Failure Code: {claim.failureCode}
                </p>
              </td>
              <td>
                <p className="content">
                  Repair Store Number: {claim.repairStoreNumber} <br />
                  SC Number: {claim.scNumber} <br />
                  Authorization Number: {claim.authorizationNumber} <br />
                  Work Done: {claim.workDone} <br />
                </p>
              </td>
              <td>
                <p className="content">
                  Repair Code: {amounts.map(amt => amt.repairCode).join(',')} <br />
                  Total Amount: {getSumOfAllAmounts(amounts)}
                </p>
              </td>
            </tr>
          </tbody>

        </table>
      </div>
    );
  }
}

function getSumOfAllAmounts(amounts = []) {
  const sum = amounts.map(amt => {
    return amt.laborAmount + amt.gstAmount + amt.qstAmount + amt.partsAmount + amt.shipAmount + amt.taxAmount
  }).reduce((prev, current) => prev + current)

  return sum > 0 ? sum.toFixed(2) : '';
}

function formatDate(dateStr) {
  return moment(dateStr).format('YYYY-MM-DD');
}

export default ClaimHistory;
