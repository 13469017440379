import React from 'react';
import { connect } from 'react-redux';

import StepLoading from './StepLoading';
import { moveStepUp } from './uploaderActions';
import { batchService } from './batchService';


function UploadRequestComponent({ data, callback }) {

  React.useEffect(() => {
    // console.log('uploading process useeffect', JSON.stringify(data));
    batchService.createClaimHeaderFile(data)
      .then(() => {
        // console.log('uploading process done');
        callback();
      })
  });

  return <StepLoading message="Uploading Requests..." />
}

const mapStateToProps = (state) => ({
  data: state.uploader.parsed
});

const mapDispatchToProps = (dispatch) => ({
  callback: () => {
    dispatch(moveStepUp());
  }
});

const UploadingProcess = connect(mapStateToProps, mapDispatchToProps)(UploadRequestComponent);
export default UploadingProcess;
