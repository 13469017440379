import axios from 'axios';
import header from './header';
import encrypt from './encrypt';


const getREQUESTHEADERS = (options) => {
  return {
    headers: {
      'x-api-key': options.headers['x-api-key'],
      'Content-Type': 'application/json',
    },
  };
};


export async function get(uri) {
  try {
    const response = await axios.get(
      uri,
      header.getOptions(),
    );
    return response.data;
  }
  catch (error) {
    console.error('ERROR sending feedback ', error);
    return error;
  }
}

export async function post(uri, data) {
  const encrypted = await encrypt(data);
  const options = header.optionsWithJsonData(encrypted);
  try {
    const response = await axios.post(
      uri,
      options.body,
      getREQUESTHEADERS(options),
    );
    return response.data;
  }
  catch (error) {
    console.error('ERROR sending feedback ', error);
    return error;
  }
}

export async function put(uri, data) {
  const options = header.optionsWithJsonData(data);
  try {
    const response = await axios.put(
      uri,
      options.body,
      getREQUESTHEADERS(options),
    );
    return response.data;
  }
  catch (error) {
    console.error('ERROR sending feedback ', error);
    return error;
  }
}


export async function postLocal(uri, data) {
  const options = header.optionsWithJsonData(data);
  try {
    const response = await axios.post(
      uri,
      options.body,
      getREQUESTHEADERS(options),
    );
    return response.data;
  }
  catch (error) {
    console.error('ERROR sending feedback ', error);
    return error;
  }
}
