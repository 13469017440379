import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClaimEditModal from './ClaimEditModal';
import { connect } from 'react-redux';

// import moment from 'moment';
// const formatDateVal = (dateVal) => dateVal ? moment.utc(dateVal).format('YYYY-MM-DD') : '';

const useStyles = makeStyles(theme => ({
  tableRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(0.8),
      width: 180,
    },
    '& th': {
      fontWeight: "bold",
      padding: "10px",
      border: "1px solid #80808036",
      position: "sticky",
      top: 0,
      zIndex: 2,
      backgroundColor: "white",
      boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)"
    },
    '& td': {
      zIndex: 1,
      // borderLeft: "1px solid #80808036",
      // borderRight: "1px solid #80808036"
      border: "1px solid #80808036",
      padding: "3px"
    },
    '& td.rowNum': {
      zIndex: 1,
      border: "1px solid #80808036",
      textAlign: "center"
    }
  },
}));

function FileContentsDisplayComponent({ data = [] }) {
  // console.log(`File content display!!`, data);
  const claimHeaderList = data;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState({
    row: {},
    index: -1
  });

  const handleOpen = (index, row) => {
    setOpen(true);
    setSelected({
      row, index
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowUpdate = (index, row) => {
    claimHeaderList[index] = row;
  }

  return (
    <div style={{ overflow: "auto", minHeight: "200px", maxHeight: "450px" }}>
      {open ? <ClaimEditModal open={open} onClose={handleClose} data={selected} onApply={handleRowUpdate} /> : ''}
      <table className={classes.tableRoot}>
        <thead>
          <tr>
            <th>Row</th>
            <th>Client ID </th>
            <th>Claim Status</th>
            <th>Sent Date</th>
            <th>Store Number </th>
            <th>Register Number </th>
            <th>Purchase Date </th>
            <th>Transaction Number </th>
            <th>Repair Ticket Number </th>
            <th>Firstname </th>
            <th>Lastname </th>
            <th>Phonenumber </th>
            <th>Claim Amount </th>
            <th>Status </th>
            <th>Claim Id </th>
            <th>Address </th>
            <th>City </th>
            <th>State </th>
            <th>Zip </th>
            <th>Failure Date </th>
            <th>New Purchase StoreNumber </th>
            <th>New Purchase Store Register Number </th>
            <th>New Purchase Date </th>
            <th>New Purchase Transaction Number </th>
          </tr>
        </thead>
        <tbody>
          {claimHeaderList.map((row, index) =>
            <React.Fragment key={index}>
              <tr>
                <td className="rowNum"><button className="button is-text is-large has-text-info" onClick={() => handleOpen(index, row)}>{index + 1}</button></td>
                <td>{row.clientId}</td>
                <td>{row.claimStatus}</td>
                <td>{row.sentDate}</td>
                <td>{row.storeNumber}</td>
                <td>{row.registerNumber}</td>
                <td>{row.purchaseDate}</td>
                <td>{row.transactionNumber}</td>
                <td>{row.repairTicketNumber}</td>
                <td>{row.firstname}</td>
                <td>{row.lastname}</td>
                <td>{row.phonenumber}</td>
                <td>{row.claimAmount}</td>
                <td>{row.status}</td>
                <td>{row.claimId}</td>
                <td>{row.address}</td>
                <td>{row.city}</td>
                <td>{row.state}</td>
                <td>{row.zip}</td>
                <td>{row.failureDate}</td>
                <td>{row.newPurchStoreNumber}</td>
                <td>{row.newPurchStoreRegisterNumber}</td>
                <td>{row.newPurchaseDate}</td>
                <td>{row.newPurchTransNumber}</td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.correction.parsed
});

const FileContentsDisplay = connect(mapStateToProps)(FileContentsDisplayComponent);
export default FileContentsDisplay;