import React from 'react';
import { queryClaimsSummary } from './workbenchServices';

function useClaimSummary(clientId, scNumber, assignedGroup) {
  const [summary, setSummary] = React.useState([]);

  React.useEffect(() => {
    queryClaimsSummary(clientId, scNumber, assignedGroup).then((result) => {
      setSummary(JSON.parse(result))
    }).catch(console.error);

  }, [clientId, scNumber, assignedGroup]);

  return summary;
}

export default function ClaimStatusCount(props) {
  const { clientId, scNumber, assignedGroup, onStatusClick } = props;
  const claimsSummary = useClaimSummary(clientId, scNumber, assignedGroup );

  return (
    <div className="notification is-size-6">

      <div className="columns has-background-dark has-text-light has-text-centered">
        <div className="column has-text-left">
          <p className="title is-6">CLAIM STATUS</p>
        </div>
        <div className="column has-text-right">
          <p className="title is-6">COUNT</p>
        </div>
      </div>

      {claimsSummary.map(summary =>

        <div className="columns" key={summary.STATUS}>
          <div className="column is-6 has-text-left">
            {summary.STATUS}
          </div>
          <div className="column has-text-right">
            <button className="button" onClick={() => onStatusClick(summary.STATUS)} >{summary.COUNT}</button>
          </div>
        </div>
      )}

    </div>
  );

}
