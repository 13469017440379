import React from 'react';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';
import CustomSnackbar, { SEVERITY, useSnackbar } from '../common/CustomSnackbar';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButtons, { useSteps } from './StepButtons';
import FileSelector, { useFileSelector } from './FileSelector';
import FileParser from './FileParser';
import FileContentsDisplay from './FileContentsDisplay';
import FileUpload from './FileUpload';
import FileUploadComplete from './FileUploadComplete';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  }
}));

function getStepLabels() {
  return ['Select File', 'Parse File', 'Upload', 'Upload Complete'];
}

function useFileSelectedValidation(stepState, fileState, snackbar) {
  React.useEffect(() => {
    if (stepState.step === 2 && !fileState.file) {
      stepState.back();
      snackbar.handleOpen(SEVERITY.error, 'Please choose a spreadsheet file first');
    }
  }, [stepState, fileState.file, snackbar]);
}


export default function StepScreen() {
  const classes = useStyles();

  // states
  const [data, setData] = React.useState([]);
  const snackbar = useSnackbar();
  const stepLabels = getStepLabels();
  const stepState = useSteps();
  const fileState = useFileSelector();

  // effects
  useFileSelectedValidation(stepState, fileState, snackbar);

  const onDataParsed = React.useCallback((data) => {
    setData(data);
  }, []);

  const onFileUpload = React.useCallback((result) => {
    console.log('upload complete: ', result);
    stepState.next();
  }, [stepState]);


  function getStepContent(activeStep) {
    switch (activeStep) {
      case 1:
        return <FileSelector {...fileState} />
      case 2:
        return data && data.length ? 
          <FileContentsDisplay data={data} /> :
          <FileParser file={fileState.file} callback={onDataParsed} />
      case 3:
        return <FileUpload data={data} callback={onFileUpload} />
      case 4:
        return <FileUploadComplete />
      default:
        return <div></div>
    }
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={stepState.step - 1} alternativeLabel>
        {stepLabels.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>
        <div>
          {getStepContent(stepState.step, {})}
        </div>
        <Divider />
        <StepButtons stepState={stepState} />
        <CustomSnackbar {...snackbar} />
      </div>

    </div >
  );
}
