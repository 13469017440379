/** uploader actions */
export const SELECT_FILE = 'SELECT_FILE_ENTITLEMENT';
export const PARSE_FILE = 'PARSE_FILE_ENTITLEMENT';
export const VALIDATE_DATA = 'VALIDATE_DATA_ENTITLEMENT';
export const SET_ERRORS = 'SET_ERRORS_ENTITLEMENT';
export const UPLOAD_DATA = 'UPLOAD_DATA_ENTITLEMENT';
export const MOVE_STEP_UP = 'MOVE_STEP_UP_ENTITLEMENT';
export const MOVE_STEP_DOWN = 'MOVE_STEP_DOWN_ENTITLEMENT';
export const RESET = 'RESET_BATCH_ENTITLEMENT';
export const REVALIDATE = 'REVALIDATE_ENTITLEMENT';
export const SET_PROCESSED = 'SET_PROCESSED_ENTITLEMENT';

/** uploader action creators */
export const moveStepDown = () => ({ type: MOVE_STEP_DOWN })
export const moveStepUp = () => ({ type: MOVE_STEP_UP })

// const validateData = (data) => ({ type: VALIDATE_DATA, data });
export const parseFile = (data) => ({ type: PARSE_FILE, data });

export const setErrors = (errors) => ({ type: SET_ERRORS, errors });
export const reset = () => ({ type: RESET });
export const revalidate = (data) => ({ type: REVALIDATE, data });
export const setProcessed = (processed) => ({ type: SET_PROCESSED, processed });

// const uploadFile = (file) => ({ type: UPLOAD_FILE, file });