import React from 'react';
import LoadingModal from '../LoadingModal';
import Loading from '../Loading';
import MessageModal from '../MessageModal';
import InfoMessage from '../InfoMessage';
import ClaimErrorsTable from './ClaimErrorsTable';
import ModalFormButtons from './ModalFormButtons';
import ClaimDetailsEditTable from './ClaimDetailsEditTable';
import processClaimAction from './processClaimAction';
import {
  getClaimById,
  getAmountLinesByClaimId
} from './workbenchServices';


class ClaimErrors extends React.Component {

  state = {
    actionComments: '',
    loading: false,
    message: '',
    subMessage: '',
    messageModal: false,
    claim: undefined,
    amountLines: [],
    processed: false,
    claimErrors: {},
    txnNumUpdated: false
  }

  handleClose = () => {
    this.reset();
    const cb = this.props.onClose;
    if (cb) {
      cb(false);
    }
  }

  handleAction = async (actionName) => {
    const { actionComments, claim, amountLines, txnNumUpdated  } = this.state;
    const { claimId, claimStatus } = this.props;

    this.showModal(true);

    const callback = (resp) => {
      const { message, subMessage } = resp;
      this.showModal(false);
      this.showMessageModal(true, message, subMessage);
      this.setProcessFlag(true);

      const cb = this.props.reloadCallback;
      if (cb) {
        cb();
      }
    };
    
    await processClaimAction(actionName, actionComments, claimId, claimStatus, claim, amountLines, txnNumUpdated, callback);
  }

  setProcessFlag = (value) => {
    this.setState({
      processed: value
    });
  }

  setComments = (value) => {
    this.setState({
      actionComments: value
    });
  }

  showModal = (active) => {
    this.setState({
      loading: active
    });
  }

  showMessageModal = (active, message, subMessage) => {
    this.setState({
      messageModal: active,
      message,
      subMessage
    });

    if (!active) {
      this.handleClose();
    }
  }

  reset = () => {
    this.setState({
      actionComments: '',
      message: '',
      subMessage: ''
    })
  }

  componentDidMount = async () => {
    try {
      const claim = await getClaimById(this.props.claimId);
      check(claim);

      const amountLines = await getAmountLinesByClaimId(this.props.claimId);
      amountLines.forEach(check);
      // console.log('amount lines: ', amountLines);

      this.setState({
        claim,
        amountLines
      });

    } catch (error) {
      console.error(error);
    }
  }

  handleClaimDetailsChange = (event) => {
    const { name, value } = event.target;
    // console.log(`Change ${name} to ${value}`);
    const claim = this.state.claim;
    claim[name] = value;

    let txnNumUpdated = false;
    if ('transactionNumber' === name) {
      txnNumUpdated = true;
    }

    this.setState({
      claim,
      txnNumUpdated
    });
  }

  handleAmountDetailsChange = (event, index) => {
    const { name, value } = event.target;
    const amt = this.state.amountLines[index];
    amt[name] = value;

    const amountLines = this.state.amountLines;
    amountLines[index] = amt;

    this.setState({
      amountLines
    });
  }

  onClaimErrorsLoad = (claimErrors) => {
    this.setState({
      claimErrors
    })
  }

  render() {
    const { claimId, claimStatus } = this.props;
    const { loading, messageModal, message, subMessage, claim, amountLines, claimErrors } = this.state;


    if (!claim) {
      return <Loading />
    }

    const { clientId = '' } = claim;
    // console.log('claimError.clientId', clientId)

    return (
      <div className="">
        <div className="" >
          <ClaimErrorsTable claimId={claimId} onLoad={this.onClaimErrorsLoad} />

          <div className="section">
            <InfoMessage message={`Please enter Date formats as "YYYY-MM-DD" `} />
            <ClaimDetailsEditTable
              claim={claim}
              claimStatus={claimStatus}
              amountLines={amountLines}
              handleClaimDetailsChange={this.handleClaimDetailsChange}
              handleAmountDetailsChange={this.handleAmountDetailsChange}
            />
          </div>

          <ActionCommentsSection callback={this.setComments} />

          <footer className="modal-card-foot">
            <ModalFormButtons
              processed={this.state.processed}
              claimId={claimId}
              clientId={clientId}
              claimStatus={claimStatus}
              claimErrors={claimErrors}
              handleAction={this.handleAction} />
          </footer>
        </div>

        <LoadingModal message='' active={loading} />
        <MessageModal message={message} subMessage={subMessage} active={messageModal} onClose={this.showMessageModal} />
      </div>
    );
  }
}


function useActionComments(callback) {
  const [actionComments, setActionComments] = React.useState();

  return {
    value: actionComments,
    onChange: (event) => {
      const value = event.target.value;
      setActionComments(value);
      if (callback) {
        callback(value);
      }
    }
  }
}

function ActionCommentsSection(props) {
  const actionComments = useActionComments(props.callback);
  return (
    <div className="section">
      <div className="form">
        <div className="field">
          <div className="control">
            <label className="label">Action Comments:</label>
            <textarea name="actionComments" className="textarea" {...actionComments} />
          </div>
        </div>
      </div>
    </div>
  );
}



function check(obj) {
  const replaceNullWithEmpty = getNullReplacer(obj);
  Object.keys(obj).forEach(replaceNullWithEmpty);
}

function getNullReplacer(obj) {
  const replaceNullWithEmpty = (field) => obj[field] === null ? obj[field] = '' : '';
  return replaceNullWithEmpty;
}

export default ClaimErrors;
