import React from 'react';

import dsvParser from './dsvParser';
import StepLoading from './StepLoading';

import { connect } from 'react-redux';
import { parseFile } from './uploaderActions';

function FileParserComponent({ file, clientId, claimStatus, sentDate, callback }) {
  const handleParseResult = React.useCallback(() => {
    dsvParser.parse(file, clientId, claimStatus, sentDate).then((parsed) => {
      callback(parsed);
    });
  }, [file, callback, clientId, claimStatus, sentDate]);

  React.useEffect(() => {
    handleParseResult();
  }, [handleParseResult]);

  return (
    <div>
      <StepLoading message={`Parsing ${file.name}...`} />
    </div >
  );
}

const mapStateToProps = (state) => ({
  file: state.correction.file,
  clientId: state.correction.clientId,
  claimStatus: state.correction.claimStatus,
  sentDate: state.correction.sentDate,
});

const mapDispatchToProps = (dispatch) => ({
  callback: (data) => {
    dispatch(parseFile(data))
  }
});

const FileParser = connect(mapStateToProps, mapDispatchToProps)(FileParserComponent);
export default FileParser;
