export class ClaimHeaderFile {
    constructor(data) {
        this.batchId = data['batchId'];
        this.docId = data['docId'];
        this.createdBy = data['createdBy'];
        this.claimHeaderList = [];
        this.email = data['email'];
        this.fileName = data['fileName'];
        this.scNumber = data['scNumber'];
        this.clientId = data['clientId'];
        this.processType = 'PORTAL_SUBMISSION';
    }
};