import React from 'react';

// function useMessageModal(handleCloseCallback) {
//   const [active, setActive] = React.useState(false);
//   const [message, setMessage] = React.useState('');
//   const [subMessage, setSubMessage] = React.useState('');

//   const showMessageModal = (active, message = '', subMessage = '') => {
//     setActive(active);
//     setMessage(message);
//     setSubMessage(subMessage);

//     if (!active) {
//       handleCloseCallback();
//     }
//   }

//   return {
//     message,
//     subMessage,
//     active,
//     setActive,
//     showMessageModal,
//     onClose: () => {
//       showMessageModal(false)
//     }
//   }
// }

class MessageModal extends React.Component {

  handleClose = () => {
    const cb = this.props.onClose;
    if (cb) {
      cb(false);
    }
  }

  render() {
    const { active, message = "Loading...", subMessage } = this.props;
    return (
      <div className={active ? "modal is-active" : "modal"} onClick={this.handleClose}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="section">
            <h1 className="title has-text-white has-text-centered">{message}</h1>
            <h2 className="subtitle has-text-white has-text-centered">{subMessage}</h2>
          </div>
          <button className="modal-close is-large" aria-label="close"></button>
        </div>
      </div>
    );
  }
}

export default MessageModal;
