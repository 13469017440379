import React from 'react';
import { Redirect } from 'react-router-dom';
import userManager from '../../services/userManager';

const Logout = (props) => {
  userManager.reset();
  return <Redirect to="/login" />;
};

export default Logout;
