import React from 'react';
import Header from './Header';
import Footer from './Footer';
import common from '../services/common';
import moment from 'moment';
import Loading from './Loading';
import LoadingModal from './LoadingModal';


class ClientMaintenance extends React.Component {
  render() {
    return (
      <div>
        <Header title='Client Maintenance' subtitle='JCA Portal' />

        <div className="container">
          <div className="section">
            <Form />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const clientFormProto = {
  clientId: '',
  clientName: '',
  activeFlag: '',
  contractValFlag: '',
  claimsFileType: '',
  policyType: '',
  consumerEdit: '',
  maxFormatErrors: '',
  startDateActive: new Date()
}

class Form extends React.Component {

  state = {
    clientList: [],
    servicerDetails: [],
    saving: false,

    clientForm: {
      ...clientFormProto,
    },

    dropdown: {
      active: false
    }
  }

  componentDidMount() {
    this.fetchClients();
  }

  fetchClients = () => {
    common.getClients().then(result => {
      this.setState({
        clientList: result
      })
    }).catch(console.error);
  }

  getServicerDetails = () => {
    common.getClientServicerDetails(this.state.clientForm.clientId)
      .then(servicerDetails => {
        this.setState({
          servicerDetails: JSON.parse(servicerDetails)
        });
      }).catch(console.error);
  }

  onClientChange = (event) => {
    const clientId = parseInt(event.target.value);
    console.log(clientId);

    if (!clientId) {
      this.setState({
        servicerDetails: [],
        clientForm: {
          ...clientFormProto
        }
      });
      return;
    }

    const selected = this.state.clientList.filter(c => c.clientId === clientId)[0];
    const { clientName, activeFlag, claimsFileType, policyType, consumerEdit, maxFormatErrors, startDateActive, contractValFlag } = selected;

    const clientForm = {
      clientId,
      clientName,
      activeFlag,
      contractValFlag,
      claimsFileType,
      policyType,
      consumerEdit,
      maxFormatErrors,
      startDateActive: moment(startDateActive).format('YYYY-MM-DD')
    };

    this.setState({
      clientForm
    }, this.getServicerDetails)
  }

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    const clientForm = this.state.clientForm;
    clientForm[name] = value;

    this.setState({
      clientForm
    });
  }

  handleFlagChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    const checked = target.checked;

    console.log(`${name} is ${value} ${checked}`);
    const clientForm = this.state.clientForm;
    clientForm[name] = checked ? 'A' : 'N';

    this.setState({
      clientForm
    });
  }

  onUpdateClick = () => {
    this.setState({
      saving: true
    })

    common.updateClient(this.state.clientForm).then((result) => {
      console.log(result);
      this.fetchClients();
      this.setState({
        saving: false
      })

    }).catch(console.error);
  }

  toggleDropdown = () => {
    this.setState({
      dropdown: {
        active: !this.state.dropdown.active
      }
    });
  }

  handleServicerSelect = (event) => {
    const { name, checked } = event.target;
    console.log(`${name} is ${checked}`);
  }

  render() {
    if (!this.state.clientList.length) {
      return <Loading />
    }

    const { clientList, clientForm, servicerDetails, saving } = this.state;
    const { clientId, clientName, activeFlag, claimsFileType, policyType, consumerEdit, maxFormatErrors, startDateActive, contractValFlag } = clientForm;

    return (
      <div className="form">

        <LoadingModal active={saving} message="Saving..." />

        <div className="hero is-fullheight">
          <div className="box">

            <div className="columns">

              <div className="column">

                <div className="field">
                  <label className="label">Client<span className="has-text-danger">*</span></label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select name="clientId" onChange={this.onClientChange} value={clientId}>
                        <option value="">Select Client</option>
                        {clientList.map(cl => <option key={cl.clientId} value={cl.clientId} >{cl.clientName}</option>)}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Client ID</label>
                  <div className="control">
                    <input className="input" type="text" name="clientId" value={clientId} readOnly />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Client Name</label>
                  <div className="control">
                    <input className="input" type="text" name="clientName" value={clientName} onChange={this.handleChange} />
                  </div>
                </div>

                <div className="field">
                  <label className="label">File Type</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select name="claimsFileType" value={claimsFileType} onChange={this.handleChange}>
                        <option value="">Select File type</option>
                        <option value="xls">XLS</option>
                        <option value="txt">TXT</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Maximum Format Errors</label>
                  <div className="control">
                    <input className="input" type="text" placeholder="50" name="maxFormatErrors" value={maxFormatErrors} onChange={this.handleChange} />
                  </div>
                </div>

                <br />

                <div className="buttons">
                  <button className="button is-info" onClick={this.onUpdateClick} >
                    <span className="icon">
                      <i className="fas fa-paper-plane"></i>
                    </span>
                    <span>Update</span>
                  </button>
                  <button className="button">
                    <span className="icon">
                      <i className="fas fa-trash"></i>
                    </span>
                    <span>Clear</span>
                  </button>
                </div>


              </div>

              <div className="column">

                <div className="field">
                  <label className="label">Servicers</label>

                  <div className={`dropdown ${this.state.dropdown.active ? 'is-active' : ''}`} style={{ width: '100%' }}>
                    <div className="dropdown-trigger" style={{ width: '100%' }}>
                      <button className="button is-fullwidth" aria-haspopup="true" aria-controls="dropdown-menu" onClick={this.toggleDropdown}>
                        <span>Select Servicers</span>
                        <span className="icon is-small">
                          <i className="fas fa-angle-down" aria-hidden="true"></i>
                        </span>
                      </button>
                    </div>
                    <div className="dropdown-menu" id="dropdown-menu" role="menu" style={{ width: '100%' }}>
                      <div className="dropdown-content" style={{ width: '100%' }}>
                        {servicerDetails.map(sc =>
                          <span className="dropdown-item">
                            <input type="checkbox" name={sc.SC_NUMBER} onChange={this.handleServicerSelect} /> {sc.SC_NAME}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Consumer Demographics Edit Control</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select name="consumerEdit" value={consumerEdit} onChange={this.handleChange}>
                        <option value="">Select Type</option>
                        <option value="ALWAYS">Always</option>
                        <option value="NEVER">Never</option>
                        <option value="EDIT">Edit when not existing</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div className="field">
                  <label className="label">Start Active Date</label>
                  <div className="control">
                    <input className="input" type="date" name='startDateActive' value={startDateActive} onChange={this.handleChange} />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Policy Type</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select name="policyType" value={policyType} onChange={this.handleChange}>
                        <option value="">Select Policy</option>
                        <option value="APPLICATION">Application Policy</option>
                        <option value="HYBRID">Hybrid Policy</option>
                        <option value="MASTER">Master Policy</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <label className="label">Able to submit contract validations:</label>
                    <input type="checkbox" name='contractValFlag' checked={contractValFlag === 'A'} onChange={this.handleFlagChange} />
                  </div>
                  <div className="control">
                    <label className="label">Active</label>
                    <input type="checkbox" name='activeFlag' checked={activeFlag === 'A'} onChange={this.handleFlagChange} />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientMaintenance;
