import moment from 'moment';

const MAX_COLUMN_NUM = 37;
const usdFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

function jsonToXls(jsonResponse = {}) {
	let xlsArray = [];
	xlsArray.push(createHeader());
	let claimHeaderFileList = jsonResponse.claimHeaderList;

	claimHeaderFileList.forEach((claimHeaderFile) => {
		let claimsLines = claimHeaderFile.claimsLines;
		let mainArray = createMainArray(claimsLines.length);

		if(mainArray.length > 0) {
			populateClaimHeader(claimHeaderFile, mainArray[0]);

			claimsLines.forEach((val, index) => {
				populateClaimLine(val, mainArray[index]);
			});

			mainArray.forEach((array) => {
				xlsArray.push(array);
			});

			//add empty row between each claim
			// xlsArray.push(new Array(MAX_COLUMN_NUM));
		}
	});

 return xlsArray;
}

function createHeader() {
	let headerArray = [];
	mapping.forEach((column) => {
		headerArray.push(column.header);
	});

	return headerArray;
}

function createMainArray(numberOfRows = 0) {
	let mainArray = [];
	for(let i = 0; i < numberOfRows; i++) {
		let row = new Array(MAX_COLUMN_NUM);
		mainArray.push(row);
	}

	return mainArray;
}

function populateClaimLine(claimLine = {}, row = []) {
	let repairCodePos = 30;
	let laborCostPos = 31;
	let partCostPos = 32;
	let shippingPos = 33;
	let taxPos = 34;

	row[repairCodePos] = claimLine.repairCode;
	row[laborCostPos] = nullSafeNumber(claimLine.laborAmt);
	row[partCostPos] = nullSafeNumber(claimLine.partsAmt);
	row[shippingPos] = nullSafeNumber(claimLine.shipAmt);
	row[taxPos] = nullSafeNumber(claimLine.taxAmt);
}

function nullSafeNumber(value){
	return value ? usdFormatter.format(value) : ""
}

function populateClaimHeader(claimLineHeader = {}, row = []) {
	mapping.forEach((column, index) => {
		let value = claimLineHeader[column.field];

		if(value) {
			if(isDate(column.field)) {
				let date = moment(value, 'YYYY-MM-DDTHH:mm:sssZ');
				row[index] = date.format('YYYY-MM-DD');
			}
			if(isMonetary(column.field)) {
				row[index] = usdFormatter.format(value);
			}
			else {
				// used regex to replace non-ASCII charactes
				let str = value.replace(/[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g, '');
				row[index] = str;
			}
		}

	});
}

function isMonetary(field){
	return ['PurchasePrice', 'TotalCost'].indexOf(field) > -1;
}

function isDate(field){
	return ['PurchaseDate', 'ServiceCenterReceivedDate', 'FailureDate', 'ServiceCenterShipDate'].indexOf(field) > -1;
}

const mapping = [
	{	header: "Client Number", field: "ClientNo"},
	{	header: "Service Center Number", field: "ServiceCenterNo"},
	{	header: "SR Number", field: "ServiceRequestNo"},
	{	header: "Repair Env. Number", field: "RepairEnvelopeNo"},
	{	header: "Store Number(sending item)", field: "StoreNoSending"},
	{	header: "Store Number(where purchased)", field: "StoreNoPurchase"},
	{	header: "Register Number", field: "Register"},
	{	header: "Date of Purchase", field: "PurchaseDate"},
	{	header: "Transaction Number", field: "TransactionNo"},
	{	header: "Customer First Name", field: "CustFirstName"},
	{	header: "Customer Last Name", field: "CustLastName"},
	{	header: "Street Address", field: "StreetAddress"},
	{	header: "Address Line 2", field: "StreetAddress2"},
	{	header: "Address Line 3", field: "StreetAddress3"},
	{	header: "City", field: "City"},
	{	header: "State", field: "State"},
	{	header: "Zip Code", field: "ZipCode"},
	{	header: "Country Code", field: "Country"},
	{	header: "Area Code", field: "AreaCode"},
	{	header: "Phone Number", field: "PhoneNo"},
	{	header: "Product Description", field: "ProductDesc"},
	{	header: "Product code", field: "ProductSKU"},
	{	header: "Brand", field: "Brand"},
	{	header: "Value (Purch Price)", field: "PurchasePrice"},
	{	header: "Failure Date", field: "FailureDate"},
	{	header: "Service Cntr Receive Date", field: "ServiceCenterReceivedDate"},
	{	header: "Service Cntr Ship Date", field: "ServiceCenterShipDate"},
	{	header: "Work to be Done", field: "WorkToBeDone"},
	{	header: "Authorization Number", field: "AuthorizationNo"},
	{	header: "Failure Reason Code", field: "FailureReasonCode"},
	{	header: "Repair Code", field: "RepairCode"},
	{	header: "Labor Cost", field: "LaborCost"},
	{	header: "Parts Cost", field: "PartsCost"},
	{	header: "Shipping/Handling Cost", field: "ShippingHandlingCost"},
	{	header: "Tax", field: "TaxCost"},
	{	header: "Total", field: "TotalCost"},
	{	header: "Notes", field: "Notes"}
];

export default jsonToXls;

