import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import DoneAll from '@material-ui/icons/DoneAll';

export default function FileUploadComplete(props) {
  return (
    <Paper>
      <div style={{ minHeight: "200px" }}>
        <Grid container direction="column" justify="center" alignItems="center">
          <div style={{ paddingTop: "20px" }}>
            <DoneAll style={{ color: "green", fontSize: 50 }} />
          </div>
          <div>
            <h3>Done!</h3>
          </div>
          <div>
            <p>Upload Complete!</p>
          </div>
        </Grid>
      </div>
    </Paper>
  );
}
