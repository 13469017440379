import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { v1 as uuid } from 'uuid';
import EntitlementDetailsEditor from './EntitlementDetailsEditor';

import { connect } from 'react-redux';
import { revalidate } from './uploaderActions'

const useStyles = makeStyles(theme => ({
  errorDisplay: {
    '&': {
      color: 'red',
      fontFamily: 'monospace'
    }
  }
}));

function ErrorDisplayComponent({ errors, data, callback }) {

  const claimHeaderList = data.claimHeaderList;
  const handleApply = (index, row) => {
    claimHeaderList[index] = row;
    callback({
      ...data,
      claimHeaderList: [
        ...claimHeaderList
      ]
    });
  }

  return (
    <div>
      {errors.map(ex => <FormatErrorMessage key={uuid()} ex={ex} handleApply={handleApply} />)}
    </div>
  );
}

function FormatErrorMessage({ ex, handleApply }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState({
    row: {},
    index: -1
  });

  const handleOpen = (index, row) => {
    setOpen(true);
    setSelected({
      row, index
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowUpdate = (index, row) => {
    // claimHeaderList[index] = row;
    handleApply(index, row)
  }

  const { index, row, errors = [] } = ex;
  const header = `Row ${index + 1}: Error(s) found in request`;

  const errorMessageItem = (err) => {
    const field = err.dataPath ? err.dataPath.replace('/', '') : '';
    const key = uuid();
    if (err.keyword === 'enum' && err.message === 'should be equal to one of the allowed values') {
      return (
        <li key={key}>Invalid value for '{field}' </li>
      );

    } else if (err.keyword === 'type') {
      return (
        <li key={key}>{field} {err.message}</li>
      );
    } else {
      // console.log(err);
      return (
        <li key={key}>{err.message}</li>
      );
    }
  };

  return (
    <Container>
      {open ? <EntitlementDetailsEditor open={open} onClose={handleClose} data={selected} onApply={handleRowUpdate} /> : null}
      <div className={classes.errorDisplay}>
        <button className="button has-text-weight-semibold has-text-danger is-link is-text is-inverted" onClick={() => handleOpen(index, row)}>{header}</button>
        <ul>
          {errors.map(errorMessageItem)}
        </ul>
        <br/>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  errors: state.entitlement.validation.errors,
  data: state.entitlement.parsed
});

const mapDispatchToProps = (dispatch) => ({
  callback: (data) => {
    dispatch(revalidate(data));
  }
});

const ErrorDisplay = connect(mapStateToProps, mapDispatchToProps)(ErrorDisplayComponent);
export default ErrorDisplay;
