import React from 'react';
import Header from './Header';
import Footer from './Footer';

class FAQ extends React.Component {
  render() {
    return (
      <div>
        <Header title='Frequently Asked Questions' subtitle='JCA Portal' />
        <FAQDetails />
        <Footer />
      </div>
    );
  }
}

function FAQDetails() {

  return (
    <div className="form">
      <div className="hero is-fullheight" style={{ padding: "1em" }}>
        <div className="container">
          <div className="section">
            <div className="content">
              <p>
                Q.   How do I change my service center information?
                For example :
                <ul className="content">
                  <li>User ID and Password</li>
                  <li>Address</li>
                  <li>Telephone</li>
                  <li>E-mail Address Notification</li>
                </ul>
              </p>
              <p>
                A.   In order to update your service center information, you must contact NEW's Jewelry Service Manager.
              </p>

              <p>
                Q.   Some of the links do not work properly.

                A.   This site is under construction. As we continue to build and improve, more tools will become available for
                       use.

              If a link that normally works is not working, contact NEW's Jewelry Service Manager.
              </p>

              <p>
                Q.   What's the difference between Batch Submission and Batch Resubmission?
              </p>

              <p>
                <span className="label">Batch Submission</span>
                The first time you file a claim, you should use the Batch Submission process. Also, if a claim submitted on
                that process is identified as having an invalid format, you should correct the format and submit through the
                batch submission process.
                </p>
              <p>
                <span className="label">Batch Resubmission</span>
                If a claim is rejected, it can be corrected and resubmitted through the Batch Resubmission process. Claims
                can be resubmitted 2 times. If they are rejected on the second resubmission, please contact NEW's
                Jewelry Service Manager.
              </p>

              <p>
                Q.   Where do I get the additional columns needed for the Batch Resubmission process?
              </p>

              <p>
                A.   NEW has created a special report to assist our service centers in correcting errors and resubmitting claims
                       for payment. The report will be emailed to the email address listed in your profile within 48 hours. The
                       report emailed to you will be in the correct format for correcting and resubmitting claims directly to the
                       Jewelry Claims Portal.
              </p>

              <p>
                Q.   Where can I find the format that the first time (initial) claims submission spreadsheet should be
                       completed as?
              </p>

              <p>
                A.   There is a sample file format on the Batch Submission Page or you may click here to download
                       a template.
              </p>

              <p>
                Q.   What Error codes is a Service Center authorized to correct and resubmit?
              </p>

              <p>
                A.   Click on the link for a complete list of errors Service Centers can correct. Click here to download
                       the list.
              </p>

              <p>
                Q.   Where can I find the format that the claims re-submission spreadsheet should be completed as?
              </p>

              <p>
                A.   There is a sample file format on the Batch Re-Submission Page.
              </p>

              <p>
                Q.   I made a request for a new password to login and have not received it yet, what do I do?
              </p>
              <p>
                A.   You should initially allow 1 business day to receive an e-mail with your new password. If you have not
                       received it within that time frame, please contact NEW's Jewelry Service Manager.
              </p>
              <p>
                Q.   What kind of files can I upload?
              </p>
              <p>
                A.   Only Excel files with an .XLS extension are allowed.
              </p>
              <p>
                Q.   How will I know my claims were received?
              </p>
              <p>
                <p>
                  A.   Upon submitting your spreadsheet, a message will display on the Batch Claims Confirmation page with
                         the following message:
                       </p>

                <p>
                  "Your batch was submitted successfully. You will receive an email notification with the details of your
                  submission."
                       </p>

                <p>
                  Submission Format Validation Summary: <br />
                  Total number of records processed: X <br />
                  Total number of correctly formatted records: X <br />
                  Total number of incorrectly formatted records: X <br />
                  <br />
                  Rejection Summary: <br />
                  Total number of Errors: X
                </p>

              </p>
              <p>
                Q.   How long does it take to receive a detailed status on the batch of claims submitted or re-submitted?
              </p>

              <p>
                A.   You should receive an e-mail notification within 48 hours containing a summary of the batch file submitted.
                      You will also receive an attachment containing a detailed status for each claim submitted.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
