import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClaimEditModal from './ClaimEditModal';
import { connect } from 'react-redux';

// import moment from 'moment';
// const formatDateVal = (dateVal) => dateVal ? moment.utc(dateVal).format('YYYY-MM-DD') : '';

const useStyles = makeStyles(theme => ({
  tableRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(0.8),
      width: 180,
    },
    '& th': {
      fontWeight: "bold",
      padding: "10px",
      border: "1px solid #80808036",
      position: "sticky",
      top: 0,
      zIndex: 2,
      backgroundColor: "white",
      boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)"
    },
    '& td': {
      zIndex: 1,
      // borderLeft: "1px solid #80808036",
      // borderRight: "1px solid #80808036"
      border: "1px solid #80808036",
      padding: "3px"
    },
    '& td.rowNum': {
      zIndex: 1,
      border: "1px solid #80808036",
      textAlign: "center"
    },
    'amtNum': {
      textAlign: "right !important"
    } 
  },
}));

function FileContentsDisplayComponent({ data = {} }) {
  // console.log(`File content display!!`);
  const claimHeaderList = data.claimHeaderList;
  // console.log('to display: ', claimHeaderList);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState({
    row: {},
    index: -1
  });

  const handleOpen = (index, row) => {
    setOpen(true);
    setSelected({
      row, index
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowUpdate = (index, row) => {
    claimHeaderList[index] = row;
  }

  return (
    <div style={{ overflow: "auto", minHeight: "200px", maxHeight: "450px" }}>
      {open ? <ClaimEditModal open={open} onClose={handleClose} data={selected} onApply={handleRowUpdate} /> : ''}
      <table className={classes.tableRoot}>
        <thead className="is-size-6">
          <tr>
            <th>Row</th>
            <th>BatchId</th>
            <th>DocId</th>
            <th>Client No </th>
            <th>Service Center No</th>
            <th>Service Request No. </th>
            <th>Repair Envelope No. </th>
            <th>Store No Sending </th>
            <th>Store No Purchase</th>
            <th>Register Number</th>
            <th>Purchase Date </th>
            <th>Transaction No</th>
            <th>FirstName</th>
            <th>LastName </th>
            <th>StreetAddress</th>
            <th>StreetAddress2 </th>
            <th>StreetAddress3 </th>
            <th>City </th>
            <th>State</th>
            <th>Zip Code</th>
            <th>Country</th>
            <th>Area Code </th>
            <th>Phone No</th>
            <th>Product Desc</th>
            <th>Product SKU </th>
            <th>Brand</th>
            <th>Purchase Price</th>
            <th>Failure Date</th>
            <th>SC Received Date</th>
            <th>SC Ship Date</th>
            <th>WorkToBeDone   </th>
            <th>Authorization No</th>
            <th>Failure Reason Code</th>
            <th>Repair Code </th>
            <th>Labor Cost</th>
            <th>Parts Cost</th>
            <th>Shipping Handling Cost </th>
            <th>Tax Cost</th>
            <th>Total Cost</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {claimHeaderList.map((row, index) =>
            <React.Fragment key={index}>
              <tr className="is-size-6">
                <td className="rowNum"><button className="button is-link is-text is-inverted is-medium" onClick={() => handleOpen(index, row)}>{index + 1}</button></td>
                <td> {row.BatchId}</td>
                <td> {row.DocId}</td>
                <td> {row.ClientNo}</td>
                <td> {row.ServiceCenterNo}</td>
                <td> {row.ServiceRequestNo}</td>
                <td> {row.RepairEnvelopeNo}</td>
                <td> {row.StoreNoSending}</td>
                <td> {row.StoreNoPurchase}</td>
                <td> {row.Register}</td>
                <td> {row.PurchaseDate}</td>
                <td> {row.TransactionNo}</td>
                <td> {row.CustFirstName}</td>
                <td> {row.CustLastName}</td>
                <td> {row.StreetAddress}</td>
                <td> {row.StreetAddress2}</td>
                <td> {row.StreetAddress3}</td>
                <td> {row.City}</td>
                <td> {row.State}</td>
                <td> {row.ZipCode}</td>
                <td> {row.Country}</td>
                <td> {row.AreaCode}</td>
                <td> {row.PhoneNo}</td>
                <td> {row.ProductDesc}</td>
                <td> {row.ProductSKU}</td>
                <td> {row.Brand}</td>
                <td style={{ textAlign: 'right' }}> {row.PurchasePrice}</td>
                <td> {row.FailureDate}</td>
                <td> {row.ServiceCenterReceivedDate}</td>
                <td> {row.ServiceCenterShipDate}</td>
                <td> {row.WorkToBeDone}</td>
                <td> {row.AuthorizationNo}</td>
                <td> {row.FailureReasonCode}</td>

                {row.claimsLines.map((amt, amtIndex) =>
                  amtIndex !== 0 ? null :
                    <React.Fragment key={`amtLine_${index}_${amtIndex}`}>
                      <td>{amt.repairCode}</td>
                      <td style={{ textAlign: 'right' }}>{amt.laborAmt}</td>
                      <td style={{ textAlign: 'right' }}>{amt.partsAmt}</td>
                      <td style={{ textAlign: 'right' }}>{amt.shipAmt}</td>
                      <td style={{ textAlign: 'right' }}>{amt.taxAmt}</td>
                    </React.Fragment>
                )}

                <td style={{ textAlign: 'right' }}> {row.TotalCost}</td>
                <td style={{ textAlign: 'right' }}> {row.Notes}</td>

                {row.meta ? row.meta.map((mtVal, mtIndex) =>
                  <td key={mtIndex}>{mtVal}</td>
                ) : null}

              </tr>
              {row.claimsLines.map((amt, amtIndex) =>
                amtIndex === 0 ? null :
                  <tr className="amtLine is-size-6" key={`amtLine_${index}_${amtIndex}`}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{amt.repairCode}</td>
                    <td style={{ textAlign: 'right' }}>{amt.laborAmt}</td>
                    <td style={{ textAlign: 'right' }}>{amt.partsAmt}</td>
                    <td style={{ textAlign: 'right' }}>{amt.shipAmt}</td>
                    <td style={{ textAlign: 'right' }}>{amt.taxAmt}</td>
                    <td></td>
                    <td></td>

                    {amt.meta ? amt.meta.map((mtVal, mtIndex) =>
                      <td key={mtIndex}>{mtVal}</td>
                    ) : null}
                  </tr>
              )}
            </React.Fragment>
          )}
        </tbody>
      </table>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.uploader.parsed
});

const FileContentsDisplay = connect(mapStateToProps)(FileContentsDisplayComponent);
export default FileContentsDisplay;