import React from 'react';
import StepLoading from './StepLoading';
import ErrorDisplay from './ValidationErrorDisplay';
import ValidDisplay from './ValidationValidDisplay';

import { connect } from 'react-redux';
import { moveStepUp, setErrors } from './uploaderActions'

// import ajv from './ajv/ajv-ext';
// const validator = ajv();

function FileContentValidatorComponent({ data = [], validation, callback }) {
  const requestArr = data;

  React.useEffect(() => {
    console.log('validator use effect');
    validate(requestArr, data).then((result) => {
      callback(result);
    });
  }, [data, requestArr, callback]);

  if (validation.running) {
    return <StepLoading message={`Validating requests...`} />;
  }

  if (validation.errors && validation.errors.length) {
    return <ErrorDisplay />
  } else {
    return <ValidDisplay />
  }
}

function validate(requestArr = [], data = {}) {
  return new Promise((resolve, reject) => {
    const result = [];
    requestArr.forEach((request, index) => {
      //validator(request);
      const validator = {};

      if (!validator.errors) {
        validator.errors = [];
      }

      if (!request.newPurchStoreNumber) {
        validator.errors.push({
          message: "New Purchase Store number is missing",
          column: "newPurchStoreNumber"
        })
      }

      if (!request.newPurchStoreRegisterNumber) {
        validator.errors.push({
          message: "New Purchase Store Register number is missing",
          column: "newPurchStoreRegisterNumber"
        })
      }

      if (!request.newPurchaseDate) {
        validator.errors.push({
          message: "New Purchase Date is missing",
          column: "newPurchaseDate"
        })
      }

      if (!request.newPurchTransNumber) {
        validator.errors.push({
          message: "New Purchase Transaction Number is Missing",
          column: "newPurchTransNumber"
        })
      }

      if (!request.clientId) {
        validator.errors.push({
          message: "Client ID is missing",
          column: "clientId"
        })
      }

      if (!request.claimStatus) {
        validator.errors.push({
          message: "Claim Status is missing",
          column: "claimStatus"
        })
      }

      if (validator.errors && validator.errors.length) {
        result.push({
          index: index,
          errors: validator.errors,
          row: request
        });
      }
      resolve(result);
    });
  });
}

const mapStateToProps = (state) => ({
  data: state.correction.parsed,
  validation: state.correction.validation
});

const mapDispatchToProps = (dispatch) => ({
  callback: (errors) => {
    if (errors && errors.length) {
      dispatch(setErrors(errors));
    } else {
      dispatch(moveStepUp());
    }
  }
});

const FileContentValidator = connect(mapStateToProps, mapDispatchToProps)(FileContentValidatorComponent);
export default FileContentValidator;
