import httpClient from '../../services/caller';
import config from '../../config/config.json';

function makeBatches(claimHeaderList, batchSize = 1000) {
  const batch = claimHeaderList.splice(0, batchSize);
  let result = [batch];

  if (claimHeaderList.length > 0) {
    const remaining = makeBatches(claimHeaderList);
    result = [batch, ...remaining];
  }

  return result;
}

function isPartial(claims) {
  const claimHeaderList = claims.claimHeaderList;
  // console.log(`Total claimHeaderList: `, claimHeaderList.length)
  if (claimHeaderList.length > 1000) {
    return true;
  } else {
    return false;
  }
}

export const batchService = (function batchService() {
  return {
    createClaimHeaderFile: async (claims) => {
      try {
        // await httpClient.post(config.api.claimHeaderFile, JSON.stringify(claims));
        claims.partial = isPartial(claims);
        if (claims.partial) {
          const batches = makeBatches(claims.claimHeaderList);
          for (let batch of batches) {
            claims.claimHeaderList = batch;
            // console.log(JSON.stringify(claims));
            await httpClient.post(config.api.claimHeaderFile, claims);
            // console.log('uploaded!');
          }
        } else {
          await httpClient.post(config.api.claimHeaderFile, claims);
        }
      } catch (e) {
        // console.error(e);
        return Promise.reject('createClaimHeaderFile: Error occur when trying to post dal');
      }

      return Promise.resolve();
    },
  };
})();
