import config from '../../config/config.json';
import caller from '../../services/caller';

export const reportService = (function () {
  const service = {
    submit: async (request) => {
      try {
        const result = await caller.post(config.api.reportRequest, request);
        return result;
      } catch (error) {
        throw error;
      }
    }
  }
  return service;
})();