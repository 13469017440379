import userManager from '../../services/userManager';
import moment from 'moment';
import {
  processResubmit,
  processOverride,
  processResubmitNsfAccept,
  processResubmitNsfUpdate,
  markAsInvalidContract,
  processCancelDeny
} from './workbenchServices';

export default async function processClaimAction(actionName, actionComments, claimId, claimStatus, claim, amountLines, isTxnNumUpdated, callback) {
  const { userName } = userManager.getUser();

  let result = {};
  try {
    // this.showModal(true);

    if ('CORRECTION' === actionName) {
      claim.productPurchaseDate = formatDate(claim.productPurchaseDate);
      claim.failureDate = formatDate(claim.failureDate);
      claim.scReceiveDate = formatDate(claim.scReceiveDate);
      claim.scShipDate = formatDate(claim.scShipDate);

      // console.log(claim);
      result = await processResubmit(claimId, userName, claim, amountLines);

    } else if ('OVERRIDE' === actionName) {
      await processOverride(claimId, userName, claimStatus, actionComments)
      result.STATUS = 'VALID_DATA';

    } else if ('ACCEPT' === actionName) {
      result = await processResubmitNsfAccept(claimId, userName, claimStatus, actionComments)

    } else if ('UPDATE' === actionName) {
      const claimHeaders = {
        clientId: claim.clientId,
        productPurchaseDate: formatDate(claim.productPurchaseDate),
        repairTransNumber: claim.repairTransNumber,
        purchaseStoreNumber: claim.purchaseStoreNumber,
        repairRegisterNumber: claim.repairRegisterNumber,
        productPurchasePrice: claim.productPurchasePrice
      };

      // console.log(`For updating: ${JSON.stringify(claimHeaders)}`)

      // optionally set transactionNumber if it is manually entered
      if (isTxnNumUpdated) {
        claimHeaders.transactionNumber = claim.transactionNumber;
      }

      result = await processResubmitNsfUpdate(claimId, userName, claimHeaders);

    } else if ('INVALID_CONTRACT' === actionName) {
      await markAsInvalidContract(claimId, userName, actionComments);
      result.STATUS = 'INVALID_CONTRACT';

    } else {
      result = await processCancelDeny(claimId, userName, actionName, actionComments);

    }

    try {
      result = JSON.parse(result);
    } catch (error) {
    }

    // console.log('processing result', result);

    // const oldStatus = claimStatus;
    // const newStatus = result.STATUS;
    // const subMessage = `Claim Status has been updated from ${oldStatus} to ${newStatus}`;
    let message = `Claim: ${claimId}`;
    let subMessage = `Claim updates successfully submitted!`;

    if (result && result.statusCode === 400) {
      subMessage = result.message || 'An error occurred while processing claim';
    }

    const resp = {
      message, subMessage
    }

    // this.showModal(false);
    // this.showMessageModal(true, message, subMessage);
    // this.setProcessFlag(true);

    if (callback) {
      callback(resp);
    }

  } catch (error) {
    console.log(error);
  }
}

function formatDate(dateStr) {
  if (!dateStr || dateStr === 'null' || dateStr === 'NULL') {
    return '';
  }
  return moment(dateStr).format('YYYY-MM-DD');
}
