import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Loading from '../LoadingModal';
import CustomSnackbar from '../common/CustomSnackbar';
import ManualPaymentTable from './ManualPaymentTable';
import ManualPaymentTablePagination, { usePagination } from './ManualPaymentTablePagination';
import Filters from './ManualPaymentFilters';
import useClaims from './useClaims';
import useFilters from './useFilters';

function ManualPayment() {

  const pagination = usePagination();
  const filters = useFilters();
  const claims = useClaims(filters.filters, pagination.activePage);

  const onClear = () => {
    claims.onClear();
    // pagination.setActivePage(1);
  }

  const onFilterChange = (params) => {
    pagination.setActivePage(1);
    filters.onFilterChange(params);
  }

  React.useEffect(() => {
    pagination.setTotalItemsCount(claims.totalCount)
  }, [pagination, claims.totalCount]);

  return (
    <div>
      <Header title='Manual Payment' />

      <div className="container">
        <div>
          <div style={{ padding: "1.5em 1em" }}>
            <Filters onFilterChange={onFilterChange} onClear={onClear} />
          </div>
          <div className="section">
            <ManualPaymentTable claims={claims.claims} totalCount={claims.totalCount} />
            <ManualPaymentTablePagination {...pagination} />
          </div>
        </div>
        <CustomSnackbar {...filters.snackbar} />
      </div>

      <Loading active={claims.loading} isDark={false} />
      <Footer />
    </div>
  );
}

export default ManualPayment;

