import React from 'react';
import config from '../config/config.json';

function Footer(props) {
  const { build, version, year } = config.meta;
  return (
    <>
    <span>
        <footer className="footer" style={{'backgroundColor':'black'}}>
            <div className="footer__container container">
                <div className="row text-center">
                    <div className="col-xs-12 col-sm-6 col-md-7">
                        <nav className="footer-nav">
                            <div className="menu-wrap">
                                <div className="asurion-logo">
                                    <a href="https://www.asurion.com/" target="_blank" rel="noreferrer">
                                        <img className="truste_img_size" src="/assets/img/asurion-logo-white.svg" alt="asurion logo"/>
                                    </a>
                                </div>
                                <ul className="menu footer-menu">
                                    {/* <li class="menu-item">
                                        <span class="info-btn menu-link">
                                            <span>
                                                <span class="info-btn__trigger-text" tabindex="0">
                                                    <span class="font-loud">Trademarks</span>
                                                </span>
                                            </span>
                                        </span>
                                    </li> */}
                                    <li className="menu-item">
                                        <a href="https://www.asurion.com/privacy-policy/" target="_blank" id="global-footer-privacyPolicy"
                                            title="Opens in a new window"
                                            className="btn-as-link link-button menu-link" rel="noreferrer">
                                                <span className="font-loud">Privacy Policy</span>
                                        </a>
                                    </li>
                                    <li className="menu-item">
                                        <a href="https://www.asurion.com/terms-conditions/" target="_blank" id="global-footer-termsOfUse"
                                            title="Opens in a new window"
                                            className="btn-as-link link-button menu-link" rel="noreferrer">
                                                <span className="font-loud">Terms of Use</span>
                                        </a>
                                    </li>
                                    {/* <li class="menu-item">
                                        <button id="onetrust-modal-trigger" class="ot-sdk-show-settings">Cookie Settings</button>
                                    </li> */}
                                </ul>
                                <div className="copyright-wrap font-loud"><span>©&nbsp;Asurion 1992-</span>
                                    <span>
                                        {new Date().getFullYear()}
                                    </span>. 
                                    All rights reserved.  Visit  
                                    <span>
                                        <b>
                                            <a className="link" href="https://www.asurion.com" target="_blank" rel="noopener noreferrer" title="Opens in a new window"> Asurion.com</a>
                                        </b>
                                    </span> to learn more.
                                </div>
                            </div>
                        </nav>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-5">
                        <div className="col-xs-12 col-sm-4 col-md-4 text-center">
                            <a href="https://privacy.truste.com/privacy-seal/validation?rid=5b01517c-cf40-4cdd-806e-aaedc2e528b2" rel="noopener noreferrer" target="_blank">
                                <img className="img-fluid mw-100 truste_img_size truste_img" src="/assets/img/seal.svg" alt="TRUSTe APEC Privacy"/>
                            </a>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-3 text-center">
                            <a href="https://privacy.truste.com/privacy-seal/validation?rid=e82169b7-5776-408b-9b86-56d74d3f3774"rel="noopener noreferrer" target="_blank">
                                <img className="img-fluid mw-100 truste_img_size truste_img" src="/assets/img/seal1.svg" alt="TRUSTe APEC Processor"/>
                            </a>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-5 text-center">
                            <a id="bbblink" rel="noopener noreferrer" target="_blank" 
                                href="//www.bbb.org/nashville/business-reviews/insurance-companies/asurion-insurance-services-in-nashville-tn-2131781#bbblogo" 
                                className="rbhzbus footer__logos__bbb" title="Asurion Insurance Services, Inc. is a BBB Accredited Insurance Company in Nashville, TN">
                                <img id="bbblinkimg" className="bbb shrink shrink-bbb logo-img" src="/assets/img/asurion-insurance-services-2131781.png" 
                                    alt="Asurion Insurance Services, Inc. is a BBB Accredited Insurance Company in Nashville, TN"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div>
                    Ver. {version} Build {build}
                </div>
            </div>
        </footer>
    </span>
    </>
    
  );
}

export default Footer;