import React from 'react';
import NavBar from './NavBar'

const goldText = {
  color: "#d7b460"
}

const goldBorder = {
  borderBottom: "1px solid rgba(215,180,96,0.5)",
  borderTop: "1px solid rgba(215,180,96,0.7)"
}

function Header({ title, subtitle }) {
  return (
    <div className="header">
      <NavBar />

      <div className="hero has-shadow" style={goldBorder}>
        <div className="has-text-centered" style={{ padding: "20px" }}>
          <div className="container">
            <h1 className="title is-1 has-text-weight-light" style={goldText}>{title}</h1>
            <h2 className="subtitle" style={goldText}>{subtitle}</h2>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Header;
