import React from 'react';
import userManager from '../../services/userManager';
import { ClientSelectorFormComponent as ClientSelector } from '../client/ClientSelector';
import { SELECT_FILE, reset } from './uploaderActions';

import { connect } from 'react-redux';

const XLSX_MIMETYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const XLS_MIMETYPE = 'application/vnd.ms-excel';
const VALID_FILETYPES = [XLSX_MIMETYPE, XLS_MIMETYPE];

function BatchFileSelectorComponent({ dispatch }) {

  const onFileSelect = (file, clientId, scNumber) => {
    console.log('params onchange');
    dispatch(reset());
    // if (file && (clientId && clientId !== '-1') && (scNumber && scNumber !== '-1')) {
    if (file && (clientId && clientId !== '-1')) {
      dispatch({ type: SELECT_FILE, file, clientId, scNumber });
    }
  }

  return (
    <div className="section">
      <Form onFileSelect={onFileSelect} />
    </div>
  );
}

class Form extends React.Component {

  state = {
    clientId: '',
    scNumber: '',
    fileName: '',
    file: undefined,
    hasInvalidFile: false
  }

  onClientChange = (event) => {
    const clientId = event.target.value;

    if (!clientId) {
      return;
    }

    this.setState({
      clientId,
      scNumber: '-1'
    }, () => {
      this.props.onFileSelect(this.state.file, this.state.clientId, this.state.scNumber);
    });
  }

  handleChange = (event) => {
    const target = event.target;
    const { name, value } = target;
    const state = this.state;
    state[name] = value;

    this.setState(state, () => {
      this.props.onFileSelect(this.state.file, this.state.clientId, this.state.scNumber);
    });
  }

  componentDidMount() {
    const user = userManager.getUser();
    const { clientId, scNumber } = user;
    this.setState({
      clientId, scNumber
    })
  }

  onFileUpload = (event) => {
    const files = event.target.files;
    const file = Array.from(files)[0];

    if (!file) {
      return;
    }

    // console.log('file type', file.type);
    if (!VALID_FILETYPES.includes(file.type)) {
      this.setState({
        file: undefined,
        fileName: undefined,
        hasInvalidFile: true
      })
      return;

    } else {
      if (!file) {
        return;
      }

      this.setState({
        file,
        fileName: file.name,
        hasInvalidFile: false
      }, () => {
        this.scrollToBottom();
        this.props.onFileSelect(file, this.state.clientId, this.state.scNumber);
      });
    }
  }

  scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  }

  render() {
    const { clientId, fileName, hasInvalidFile } = this.state;

    return (
      <div className="form">
        <div className="hero" >

          <div className="columns">
            <div className="column is-4 is-offset-4">

              <div className="box">

                <ClientSelector name="clientId" value={clientId} onChange={this.onClientChange} required={true} />

                {/* <ServicerSelector name="scNumber" clientId={clientId} value={scNumber} onChange={this.handleChange} required={true} /> */}

                <div className="field">
                  <label className="label">Batch File</label>
                  <div className="file has-name is-fullwidth" style={{ fontSize: "smaller" }}>
                    <label className="file-label">
                      <input className="file-input" type="file" name="batchFile" onChange={this.onFileUpload} />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">
                          Choose a file…
                        </span>
                      </span>
                      <span className="file-name">
                        {fileName}
                      </span>
                    </label>
                  </div>
                  {hasInvalidFile ? <p className="help is-danger" style={{ fontSize: "0.80rem" }}>You have selected an invalid File. Please select XLSX or XLS files only</p> : null}
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const BatchFileSelector = connect()(BatchFileSelectorComponent);
export default BatchFileSelector;