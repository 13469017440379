import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ManualPaymentEdit from './ManualPaymentEdit';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
    '&': {
      padding: 20
    }
  },
  heading: {
    paddingBottom: 5,
    paddingTop: 10,
    fontWeight: "bold"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function useFullScreenModal() {
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState({});

  const hide = () => {
    setOpen(false);
  }

  const show = () => {
    setOpen(true);
  }

  return {
    open,
    show,
    hide,
    params,
    setParams
  }
}

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const { state } = props;
  const { claimId } = state.params;

  const onSubmit = () => {
    state.hide();
  };

  return (
    <div>
      <Dialog fullScreen open={state.open} onClose={state.hide} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={state.hide} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Edit Claim Details
            </Typography>
            <Button autoFocus color="inherit" onClick={state.hide}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <div>
            <ManualPaymentEdit claimId={claimId} onSubmit={onSubmit}/>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
