const Ajv = require('ajv');
const moment = require('moment');
// const metaSchema = require('ajv/lib/refs/json-schema-draft-04.json');
// const { toFixedNumber } = require('./core/numberUtil');

function toFixedNumber(num, decimal = 2) {
    if (!num) {
        return 0;
    }

    return parseFloat((num).toFixed(decimal));
};

const ajvSchema = require('./claimformat-flat.json');
// const ajvSchema = require('./validate.json');
// const cfmSchema1 = require('./cfm-new-path.json');
// const cfmSchema2 = require('./cfm-update-path.json');

/**
 * 
 * @returns {Ajv.ValidateFunction}
 */
module.exports = function () {
    let ajv = Ajv({ allErrors: true, jsonPointers: true });


    ajv.addFormat('date', function(data) {
        const date = moment.utc(data);
        // console.log(data)
        return date.isValid();
    });

    // ajv.addMetaSchema(metaSchema);
    registerCustomKeywords(ajv);

    // ajv.addSchema(cfmSchema1);
    // ajv.addSchema(cfmSchema2);

    const validator = ajv.compile(ajvSchema);
    return validator;
}

const registerCustomKeywords = (ajv) => {

    ajv.addKeyword("sumCheck", {
        type: 'object',
        errors: true,
        validate: validationFn_sumCheck
    });

    ajv.addKeyword("altRequirements", {
        type: 'object',
        errors: true,
        validate: validationFn_altReq
    });

    ajv.addKeyword("valueCheck", {
        type: 'object',
        errors: true,
        validate: validationFn_valueCheck
    });

    ajv.addKeyword("enumerables", {
        type: 'object',
        errors: true,
        validate: enumerablesFn
    });

    return ajv;
}

/**
 * 
 * @param {JSON} sch
 * @param {JSON} data
 */
const validationFn_sumCheck = function (sch, data) {
    validationFn_sumCheck.errors = [];
    // let allSumsAreValid = true;
    const accessData = dataAccessor(data);

    const genError = (f, items, errorMessage) => {
        return {
            keyword: "sumCheck",
            message: errorMessage ? errorMessage : `${getFieldName(f)} is not equal to the sum of its details`,
            params: { items },
            dataPath: f
        };
    };

    // const genErrorRequired = (f, items) => {
    //     return {
    //         keyword: "sumCheck",
    //         message: `${getFieldName(f)} is required`,
    //         params: {},
    //         dataPath: f
    //     };
    // };

    const checkSum = ({ expectedSum, items, errorMessage }) => {
        const field = accessData(expectedSum);
        const sum = items.map(accessData).filter(o => o).reduce((_sum, _current) => _sum + _current, 0);

        const isEqual = toFixedNumber(field) === toFixedNumber(sum);
        if (!isEqual) {
            validationFn_sumCheck.errors.push(genError(expectedSum, items, errorMessage));
        }
        return isEqual;
    };

    // const allSumsAreEqual = !Object.entries(sch).map(checkSum).includes(false);
    // console.log(allSumsAreEqual);
    const allSumsAreEqual = sch.map(checkSum).every(val => val);

    return allSumsAreEqual;
};

/**
 * 
 * @param {JSON} sch
 * @param {JSON} data
 */
const validationFn_altReq = function (sch, data) {
    validationFn_altReq.errors = [];

    const accessData = dataAccessor(data);

    const genError = (message) => {
        return {
            keyword: "altRequirements",
            message: message,
            params: { keyword: "altRequirements" }
        };
    };

    function checkField(f) {
        const fval = accessData(f);
        const found = !!fval; // field is defined
        //console.log(`field ${f} is ${fval} = ${found}`);
        return found;
    }

    function areAllRequiredFieldsFound(requiredFields, errorMessage) {
        const hasErrors = requiredFields.map(checkField).includes(false);
        if (hasErrors) {
            validationFn_altReq.errors = validationFn_altReq.errors.concat(genError(errorMessage));
            return false;
        }
        return true;
    };

    const checkAlt = ({ optionalField, requiredFields, errorMessage = `Required fields are not met when ${optionalField} is null or empty` }) => {
        const optionalIsFound = accessData(optionalField);
        return optionalIsFound ? true : areAllRequiredFieldsFound(requiredFields, errorMessage);
    };

    const hasErrors = sch.map(checkAlt).includes(false);
    const allRequirementsMet = hasErrors ? false : true;
    return allRequirementsMet;
};

const validationFn_valueCheck = function (sch, data) {
    validationFn_valueCheck.errors = [];
    const accessData = dataAccessor(data);

    const genErrorValCheck = (msg, dataPath = '') => {
        return {
            keyword: "valueCheck",
            message: msg,
            params: {},
            dataPath
        };
    };

    const checkValues = ({ when, then, errorMessage }) => {

        const hasExpectedValue = ({ field, hasValue, isEmptyOrNull }) => {
            const value = accessData(field);

            if (hasValue) {
                if (Array.isArray(hasValue)) {
                    return hasValue.includes(value);
                } else {
                    return value === hasValue;
                }
            }

            if (isEqualIgnoreCase('true', isEmptyOrNull)) {
                return !value || isEqualIgnoreCase('null', value);
            }

            return false;
        };

        const hasExpectedValue_then = (item) => {
            const value = accessData(item.field);
            let result = false;
            let expected = null;
            let condition = null;

            if (item.shouldBeEqual !== undefined) {
                expected = item.shouldBeEqual;
                condition = 'Should be';
                result = value === expected;

            } else if (item.shouldNotBeEqual !== undefined) {
                expected = item.shouldNotBeEqual;
                condition = 'Should not be';
                result = value !== expected;

            } else if (item.shouldBeDefined !== undefined) {
                expected = item.shouldBeDefined;
                condition = 'Should be defined';
                result = value !== undefined && value !== "" && value !== null;

            } else if (item.shouldBeGreater !== undefined) {
                expected = item.shouldBeGreater;
                condition = 'Should be greater than';
                result = value > expected;

            } else if (item.shouldBeLesser !== undefined) {
                expected = item.shouldBeLesser;
                condition = 'Should be less than';
                result = value < expected;

            } else if (item.shouldBeGreaterOrEqual !== undefined) {
                expected = item.shouldBeGreaterOrEqual;
                condition = 'Should be greater than or equal to ';
                result = value >= expected;

            } else if (item.shouldBeLesserOrEqual !== undefined) {
                expected = item.shouldBeLesserOrEqual;
                condition = 'Should be less than or equal to ';
                result = value <= expected;
            }

            if (!result && !errorMessage) {
                const msg = errorMessage ? errorMessage : `Field ${getFieldName(item.field)} is ${value}. ${condition} ${expected}`;
                validationFn_valueCheck.errors.push(genErrorValCheck(msg, item.field));
            }

            return result;
        };

        const allConditionsMet = when.map(hasExpectedValue).every(val => val);
        if (!allConditionsMet) {
            return true; // dont have to check expectations when conditions were not met
        }

        const allValuesMet = then.map(hasExpectedValue_then).every(val => val);
        if (!allValuesMet && errorMessage) {
            validationFn_valueCheck.errors.push(genErrorValCheck(errorMessage, ""));
        }

        return allValuesMet;
    };

    return sch.map(checkValues).every(val => val);
};

function isEqualIgnoreCase(str1 = "", str2 = "") {
    return str1.toLowerCase() === str2.toLowerCase();
}

const getFieldName = (f) => f.split('.').slice(-1);

function enumerablesFn(sch, data) {
    enumerablesFn.errors = [];
    const accessData = dataAccessor(data);

    const genError = (msg, dataPath = '', allowedValues = []) => {
        return {
            keyword: "enumerables",
            message: `${getFieldName(dataPath)} must be one of the enumerables defined`,
            params: { allowedValues },
            dataPath
        };
    };

    function readEnums({ field, allowedValues = [] }) {
        const value = accessData(field);
        if (undefined === value) {
            return true;
        }

        const found = caseInsensitiveSearch(allowedValues, value);

        if (!found) {
            const err = genError('', field, allowedValues);
            enumerablesFn.errors.push(err);
        }

        return found;
    }

    const allValuesAreValid = sch.map(readEnums).every(t => !!t);
    return allValuesAreValid;
}

function caseInsensitiveSearch(arr = [], item = "") {
    return arr.map(s => s.toLowerCase()).includes(item.toLowerCase());
}

function dataAccessor(data) {
    const accessData = (keystr) => {
        let tempData = null;
        keystr.split('.').forEach(k => tempData = tempData ? tempData[k] : data[k]);
        // console.log(`${keystr} -- ${tempData}`);
        return tempData;
    };

    return accessData;
}