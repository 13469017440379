import React from 'react';
import Header from './Header';
import Footer from './Footer';

class AS400Entitlement extends React.Component {
  render() {
    return (
      <div>
        <Header title='AS/400 Jewelry Claims Entitlement Portal' subtitle='JCA Portal' />

        <div className="container">
          <div className="section">
            <Form />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

function Form() {

  return (
    <div className="form">
      <div className="hero is-fullheight">

        <div className="box">

          <div className="columns">
            <div className="column">

              <div className="field">
                <label className="label">Client</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select>
                      <option value="cl1">Sterling Jeweler</option>
                      <option value="cl2">Jared</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Service Center</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select>
                      <option value="StollCo">Stoll & Co</option>
                      <option value="AWR">AWR</option>
                      <option value="SJR">SJR</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <label className="label">Transaction Number</label>
                <div className="control">
                  <input className="input" type="text" placeholder="" />
                </div>
              </div>

            </div>

            <div className="column is-offset-1">

              <div className="field">
                <label className="label">Failure Date</label>
                <div className="control">
                  <input className="input" type="date" placeholder="YYYY-MM-DD" />
                </div>
              </div>

              <div className="field">
                <label className="label">From Date</label>
                <div className="control">
                  <input className="input" type="date" placeholder="YYYY-MM-DD" />
                </div>
              </div>

              <div className="field">
                <label className="label">To Date</label>
                <div className="control">
                  <input className="input" type="date" placeholder="YYYY-MM-DD" />
                </div>
              </div>

            </div>

          </div>

          <div className="buttons">
            <button className="button is-info">
              <span className="icon">
                <i className="fas fa-search"></i>
              </span>
              <span>Search</span>
            </button>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default AS400Entitlement;
