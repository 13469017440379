import * as XLSX from 'xlsx';
import moment from 'moment';
import { ClaimHeaderFile } from './claimHeaderFile';
import userManager from '../../services/userManager';

function isRowHeader(row) {
  const cl = row.ClientNo.toString().trim().toLowerCase() || '';
  if (cl.includes('client') && (cl.includes('number') || cl.includes('no.') || cl.includes('num') || cl.includes('no'))) {
    return true;
  }

  return false;
}

function parse(file, clientId, scNumber) {
  return new Promise((resolve, reject) => {
    excelReader(file)
      .then((rows) => {
        // console.log('parsed-excel: ', JSON.stringify(rows));
        let batchId = getBatchId();

        const rowMapper = getMapper(batchId);
        const { userName, mail: email } = userManager.getUser();

        let claimHeaderFileParam = {
          batchId: batchId,
          docId: batchId,
          createdBy: userName,
          email,
          fileName: file.name,
          clientId,
          scNumber,
        };

        let claimHeaderFile = new ClaimHeaderFile(claimHeaderFileParam);
        const mapped = rows.map(rowMapper).filter((row) => !isRowHeader(row));

        let [claimHeader, ...rest] = mapped;
        const claimHeaderList = [claimHeader];

        for (const claim of rest) {
          //if (claim.ClientNo && claim.ServiceCenterNo) {
          if (claim.RepairEnvelopeNo && claimHeader.RepairEnvelopeNo !== claim.RepairEnvelopeNo) {
            claimHeader = claim;
            claimHeaderList.push(claimHeader);
          } else {
            if (claimHeader) claimHeader.addAmounts(claim);
          }
        }

        claimHeaderFile.claimHeaderList = claimHeaderList;
        // console.log(claimHeaderList);
        resolve(claimHeaderFile);
      })
      .catch((errors) => {
        console.error(errors);
        resolve([]);
      });
  });
}

const defaultToEmpty = (field) => {
  return field === null || field === undefined ? '' : isNaN(field) || field instanceof Date ? String(field).trim() : field;
};

const hasDollarSign = (field = '') => {
  return field.trim().startsWith('$');
};

const unsigned = (field = '') => {
  if (isNaN(field)) {
    return hasDollarSign(field) ? field.replace('$', '') : field;
  }

  return field;
};

const parseNumField = (field = '') => {
  if (!field) {
    return 0;
  }

  const f = unsigned(field);
  return isNaN(f) ? 0 : Number.parseFloat(f);
};
// const defaultToEmptyDate = (field) => (field === null || field === undefined) ? '' : new Date(field);

function getMapper(batchId) {
  return function rowMapper(row, index) {
    const txnIndex = `${index + 1}`.padStart(4, 0);
    const transactionId = `${batchId}${txnIndex}`;

    const mapped = {
      TransactionId: transactionId,
      BatchId: batchId,
      DocId: batchId,
      ClientNo: defaultToEmpty(row[0]),
      ServiceCenterNo: defaultToEmpty(row[1]),
      ServiceRequestNo: defaultToEmpty(row[2]),
      RepairEnvelopeNo: defaultToEmpty(row[3]),
      StoreNoSending: defaultToEmpty(row[4]),
      StoreNoPurchase: defaultToEmpty(row[5]),
      Register: defaultToEmpty(row[6]),
      PurchaseDate: defaultToEmptyDate(row[7]),
      TransactionNo: defaultToEmpty(row[8]),
      CustFirstName: normalizeStr(defaultToEmpty(row[9])),
      CustLastName: normalizeStr(defaultToEmpty(row[10])),
      StreetAddress: normalizeStr(defaultToEmpty(row[11])),
      StreetAddress2: normalizeStr(defaultToEmpty(row[12])),
      StreetAddress3: normalizeStr(defaultToEmpty(row[13])),
      City: defaultToEmpty(row[14]),
      State: defaultToEmpty(row[15]),
      ZipCode: defaultToEmpty(row[16]),
      Country: defaultToEmpty(row[17]),
      AreaCode: defaultToEmpty(row[18]),
      PhoneNo: defaultToEmpty(row[19]),
      ProductDesc: defaultToEmpty(row[20]),
      ProductSKU: defaultToEmpty(row[21]),
      Brand: defaultToEmpty(row[22]),
      PurchasePrice: parseNumField(row[23]),
      FailureDate: defaultToEmptyDate(row[24]),
      ServiceCenterReceivedDate: defaultToEmptyDate(row[25]),
      ServiceCenterShipDate: defaultToEmptyDate(row[26]),
      WorkToBeDone: normalizeStr(defaultToEmpty(row[27])),
      AuthorizationNo: defaultToEmpty(row[28]),
      FailureReasonCode: defaultToEmpty(row[29]),
      RepairCode: defaultToEmpty(row[30]),
      LaborCost: parseNumField(row[31]),
      PartsCost: parseNumField(row[32]),
      ShippingHandlingCost: parseNumField(row[33]),
      TaxCost: parseNumField(row[34]),
      TotalCost: parseNumField(row[35]),
      Notes: normalizeStr(defaultToEmpty(row[36])),
      addAmounts: function (header) {
        if (!header.RepairCode) {
          return;
        }
        this.LaborCost += header.LaborCost;
        this.PartsCost += header.PartsCost;
        this.ShippingHandlingCost += header.ShippingHandlingCost;
        this.TaxCost += header.TaxCost;
        this.TotalCost += header.TotalCost;

        header.claimsLines[0].amountLine = this.claimsLines.length + 1;
        if (header.meta && header.meta.length) {
          header.claimsLines[0].meta = header.meta;
        }
        this.claimsLines = this.claimsLines.concat(header.claimsLines);
      },
      claimsLines: [
        {
          batchId: batchId,
          repairCode: defaultToEmpty(row[30]),
          laborAmt: parseNumField(row[31]),
          partsAmt: parseNumField(row[32]),
          shipAmt: parseNumField(row[33]),
          taxAmt: parseNumField(row[34]),
          amountLine: 1,
        },
      ],
    };

    if (row.length > 37) {
      const extraCols = row.splice(36);
      mapped.meta = [];
      extraCols.forEach((value) => {
        mapped.meta.push(value);
      });
    }

    mapped.TotalCost = mapped.LaborCost + mapped.PartsCost + mapped.ShippingHandlingCost + mapped.TaxCost;
    return mapped;
  };
}

const defaultToEmptyDate = (field) => {
  if (!field) {
    return '';
  }

  const date = moment.utc(field);
  if (date.isValid()) {
    return date.format('YYYY-MM-DD');
  } else {
    return '';
  }
};

function getBatchId() {
  return moment.utc().format('YYYYMMDDHHmmss');
}

function excelReader(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: 'binary', cellDates: true });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, range: 11 });
      // console.log(dataParse);
      resolve(dataParse);
    };
    reader.readAsBinaryString(file);
  });
}

function removeLineBreaks(str = '') {
  console.log('replacing: ', str);
  return str.replace(/\r?\n|\r/g, ' ');
}

function removeSpecialChars(str = '') {
  return str.replace(/[^\w\s]/gi, '');
}

function normalizeStr(str) {
  str = String(str);
  return removeSpecialChars(removeLineBreaks(str));
}

export default parse;
