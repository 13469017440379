import React from 'react';
import { loginService } from './loginService';
import { Redirect } from 'react-router-dom';
import Footer from '../Footer';

const goldText = {
  color: "#d7b460"
}

const bgLogin = {
  "backgroundImage": `url('${process.env.PUBLIC_URL}/images/login-background.png')`,
  "backgroundSize": "contain",
  "backgroundAttachment": "fixed",
  "backgroundPosition": "left",
  "backgroundRepeat": "no-repeat",
}


class Login extends React.Component {

  state = {
    username: '',
    password: '',
    loading: false,
    invalidUsername: false,
    invalidPassword: false,
    redirectTo: '',
    status: 0
  }

  handleUsernameChange = (event) => {
    const { value } = event.target;
    this.setState({
      username: value
    })
  }

  handlePasswordChange = (event) => {
    const { value } = event.target;
    this.setState({
      password: value
    })
  }

  handleLoginClick = (event) => {
    event.preventDefault();
    const { username, password } = this.state;

    if (!username) {
      this.setState({ invalidUsername: true, loading: false })
    }

    if (!password) {
      this.setState({ invalidPassword: true, loading: false })
    }

    if (username && password) {

      this.setState({
        loading: true,
        status: 0
      });

      loginService.authenticate(username, password).then((result) => {
        if (result) {
          this.setState({
            redirectTo: '/home'
          });
        } else {
          this.setState({
            status: 1,
            loading: false
          })
        }

      }).catch(console.error);
    }
  }

  render() {
    const { loading, invalidUsername, invalidPassword, redirectTo } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />
    }

    return (
      <div className="hero is-light is-fullheight" style={bgLogin}>

        <div className="hero-body" >
          <div className="container" >
            <div className="column is-6 is-offset-6">
              <div className="section">

                <div className="has-text-centered">
                  <img src="/images/repairnow-logo.png" alt="repairnow.com" />
                  <h2 className="subtitle is-5" style={goldText}>Your go-to site for filing and searching jewelry claims</h2>
                </div>

                <br />

                <div className="section" style={{ paddingTop: "3px" }}>
                  <div className="box" style={{ backgroundColor: "hsla(0, 0%, 100%, 1)" }}>
                    <label className="label has-text-centered" style={{ padding: "8px", ...goldText }}>Partner Login</label>

                    <form onSubmit={this.handleLoginClick} >
                      <div className="field">
                        <div className="control has-icons-left">
                          <input className="input" type="text" name="username" onChange={this.handleUsernameChange} placeholder="Username" />
                          <span className="icon is-small is-left">
                            <i className="fas fa-user"></i>
                          </span>
                          {invalidUsername ? <p className="help is-danger" style={{ fontSize: "0.80rem" }}>Username is required</p> : ''}
                        </div>
                      </div>
                      <div className="field">
                        <div className="control has-icons-left">
                          <input className="input" type="password" name="password" onChange={this.handlePasswordChange} placeholder="Password" />
                          <span className="icon is-small is-left">
                            <i className="fas fa-lock"></i>
                          </span>
                          {invalidPassword ? <p className="help is-danger" style={{ fontSize: "0.80rem" }}>Password is required</p> : ''}
                        </div>
                      </div>

                      {
                        this.state.status && parseInt(this.state.status) === 1 ?
                          < LoginError message="Invalid Username or Password!" /> : ''
                      }

                      <div className="columns">

                        <div className="column is-3">
                          <div className="field">
                            <div className="control">
                              <button type="submit" className={`button is-info is-fullwidth ${loading ? 'is-loading' : ''}`}>Login</button>
                            </div>
                          </div>
                        </div>

                        <div className="column is-offset-5">
                          <div className="field is-grouped">
                            <div className="control">
                              <input className="checkbox" id="rememberFlag" type="checkbox" />
                            </div>
                            <div className="control">
                              <label htmlFor="rememberFlag">Remember me</label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </form>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div className="hero-foot">
          <Footer style={{ backgroundColor: "hsla(0, 0%, 100%, .40)" }} />
        </div>
      </div>
    );
  }
}


function LoginError({ message }) {
  return (
    <article className="message is-danger" style={{ fontSize: ".80rem" }}>
      <div className="message-body">
        <div className="content">
          <div className="messageContent">
            {message}
          </div>
        </div>
      </div>
    </article>
  );
}

export default Login;
