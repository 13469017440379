import config from '../../config/config.json';
import caller from '../../services/caller';

export async function postNsfPaymentRelease(data) {
  try {
    const url = `${config.api.nsfPaymentRelease}`;
    const result = await caller.post(url, { ClaimIds: data });
    return result;
  } catch (error) {
    console.error(error);
    return null;
  }
}
