import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import userManager from '../../services/userManager';
import { ClientSelectorFormComponent as ClientSelector } from '../client/ClientSelector'
// import Loading from '../Loading';
import { listReports, downloadReports } from './reportService';

function DownloadReports({ batchId }) {
  return (
    <div className="hero is-fullheight">
      <Header title='Download Reports' subtitle='JCA Portal' />

      <div className="container">
        <ClaimHeaderFileInfo />
      </div>

      <Footer />
    </div>
  );
}

function useReportList(clientId) {
  const [reports, setReports] = React.useState({
    data: [],
    token: ''
  });

  React.useEffect(() => {
    if (clientId) {
      listReports(clientId).then(data => {
        setReports(data);
      }).catch(console.error);
    }
  }, [clientId]);

  return reports;
}

function useClientId() {
  const [clientId, setClientId] = React.useState();
  React.useEffect(() => {
    // console.log('Loading ClientId...');
    const user = userManager.getUser();
    const { clientId } = user;
    setClientId(clientId);
  }, []);

  const onClientChange = (event) => {
    const clientId = event.target.value;
    setClientId(clientId);
  }

  return {
    clientId,
    onClientChange
  }
}


function ClaimHeaderFileInfo() {
  const { clientId, onClientChange } = useClientId();
  const reports = useReportList(clientId);

  return (
    <div className="columns">

      <div className="column is-4">
        <div className="section" style={{ minWidth: "400px" }}>
          <div className="box">
            <ClientSelector name="clientId" value={clientId} onChange={onClientChange} required={true} />
          </div>
        </div>
      </div>

      <div className="column is-8">
        <div className="section" style={{ minWidth: "800px" }}>
          <DisplayReportTable reports={reports.data} />
        </div>
      </div>

    </div>
  );
}

function useDownload() {
  const [downloading, setDownloading] = React.useState(false);

  const onDownloadClick = async (report) => {
    setDownloading(true);
    const { clientId, scNumber, fileName } = report;
    await downloadReports(clientId, scNumber, fileName);
    setTimeout(() => {
      setDownloading(false);
    }, 2000);
  };

  return {
    downloading,
    onDownloadClick
  }
}

function DownloadButton({report}) {
  const { onDownloadClick, downloading } = useDownload();
  return (
    <button className={downloading ? "button is-text is-loading" : "button is-text"} onClick={() => onDownloadClick(report)}>Download</button>
  );
}

function DisplayReportTable({ reports }) {

  return (
    <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-size-6" >
      <thead>
        <tr className="is-size-6">
          <th>Client ID</th>
          <th>ScNumber</th>
          <th>Filename</th>
          <th>Creation Date</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {
          (reports.length) ?
            reports.map((r, index) => <tr key={index} >
              <td>{r.clientId}</td>
              <td>{r.scNumber}</td>
              <td>{r.fileName}</td>
              <td>{r.date}</td>
              <td><DownloadButton report={r} /></td>
            </tr>)
            :
            <tr>
              <td colSpan={5}><div className="has-text-centered">No Data</div></td>
            </tr>
        }
      </tbody>
    </table>
  );
}


export default DownloadReports;
