import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tableRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(0.8),
      width: 180,
    },
    '& th': {
      fontWeight: "bold",
      padding: "10px",
      border: "1px solid #80808036",
      position: "sticky",
      top: 0,
      zIndex: 2,
      backgroundColor: "white",
      boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)"
    },
    '& td': {
      zIndex: 1,
      // borderLeft: "1px solid #80808036",
      // borderRight: "1px solid #80808036"
      border: "1px solid #80808036",
      padding: "3px"
    },
    '& td.rowNum': {
      zIndex: 1,
      border: "1px solid #80808036",
      textAlign: "center"
    }
  },
}));

export default function FileContentDisplay({data = []}) {
  const classes = useStyles();

  if(!data) {
    return;
  }

  return (
    <div style={{ overflow: "auto", minHeight: "200px", maxHeight: "450px" }}>
      <table className={classes.tableRoot}>
        <thead>
          <tr>
            <th>StoreNumber</th>
            <th>RegisterNumber</th>
            <th>DateofPurchase</th>
            <th>TransactionNumber</th>
            <th>RepairTicketNumber</th>
            <th>FirstName</th>
            <th>LastName</th>
            <th>Phone</th>
            <th>UnitCost</th>
            <th>Status</th>
            <th>ClaimId</th>
            <th>Address</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
            <th>FailureDate</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) =>
            <tr key={index}>
              <td>{row.storeNumber}</td>
              <td>{row.registerNumber}</td>
              <td>{row.dateOfPurchase}</td>
              <td>{row.transactionNumber}</td>
              <td>{row.repairTicketNumber}</td>
              <td>{row.firstName}</td>
              <td>{row.lastName}</td>
              <td>{row.phone}</td>
              <td>{row.unitCost}</td>
              <td>{row.status}</td>
              <td>{row.claimId}</td>
              <td>{row.address}</td>
              <td>{row.city}</td>
              <td>{row.state}</td>
              <td>{row.zip}</td>
              <td>{row.failureDate}</td>
            </tr>)}
        </tbody>
      </table>
    </div>
  );
}