import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const XLSX_MIMETYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const XLS_MIMETYPE = 'application/vnd.ms-excel';
const VALID_FILETYPES = [XLSX_MIMETYPE, XLS_MIMETYPE];

export function useFileSelector() {
  const [file, setFile] = React.useState();
  const [fileName, setFileName] = React.useState();

  const onFileSelect = (event) => {
    const files = event.target.files;
    const file = Array.from(files)[0];

    if (!file) {
      return;
    }

    if (!VALID_FILETYPES.includes(file.type)) {
      return;
    }

    setFile(file);
    setFileName(file.name);
  }

  return {
    file,
    fileName,
    onFileSelect
  }
}

function FileSelector({file, onFileSelect}) {
  return (
    <div>
      <Paper style={{ backgroundColor: "gainsboro" }}>
        <div style={{ minHeight: "200px" }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Typography>
              <input type="file" name="requestFile" style={{ marginTop: "80px" }} onChange={onFileSelect} />
            </Typography>
          </Grid>
        </div>
      </Paper>
    </div>
  );
}

export default FileSelector;
