import React from 'react';
import nsfExcelParser from './nsfExcelParser';
import StepLoading from './StepLoading';

function useParsedData(file, callback) {
  React.useEffect(() => {
    if (file) {
      nsfExcelParser(file).then((data) => {
        // console.log('parsed data', JSON.stringify(data));
        callback(data);
      });
    }
  }, [file, callback]);
}

export default function FileParser({ file, callback }) {
  useParsedData(file, callback);

  if (!file) {
    return;
  }

  return (
    <div>
      <StepLoading message={`Parsing ${file.name}...`} />
    </div >
  );
}