import React from 'react';
import DatePicker from "react-datepicker";
import { ClientSelectorFormComponent as ClientSelector } from '../client/ClientSelector';
import { ServicerSelector } from '../servicer/ServicerSelector';
import moment from 'moment';
import userManager from '../../services/userManager';

const filtersProto = {
  fromDate: '',
  toDate: '',
  status: '',
  srNumber: '',
  clientNumber: '',
  transactionNumber: '',
  phoneNumber: '',
  authNumber: '',
  batchId: '',
  repairTicketNumber: '',
  scNumber: ''
};

function formatDate(dateStr) {
  if (!dateStr || dateStr === 'null' || dateStr === 'NULL') {
    return '';
  }
  return moment(dateStr).format('YYYY-MM-DD');
}


export default class Filters extends React.Component {

  state = {
    filters: { ...filtersProto },
    invalidClient: false,
    invalidServicer: false
  }

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    const filters = this.state.filters;
    filters[name] = value;

    this.setState({
      filters
    })

    if ('scNumber' === name) {
      this.setState({ invalidServicer: false });
    }

    if ('clientNumber' === name) {
      this.setState({ invalidClient: false });
    }
  }

  onFilterChange = () => {
    if (this.props.onFilterChange) {
      this.props.onFilterChange(this.state.filters);
    }
  }

  onClearClick = () => {
    this.setState({
      filters: { ...filtersProto },
      invalidClient: false,
      invalidServicer: false
    });

    if (this.props.onClear) {
      this.props.onClear();
    }
  }

  handleDateChange = (date, field) => {
    const filters = this.state.filters;
    filters[field] = formatDate(date);
    this.setState({ filters });
  }

  render() {
    const user = userManager.getUser();
    // const { clientList = [], servicerDetails = [] } = this.props;
    const { fromDate, toDate, status, srNumber, clientNumber, transactionNumber, phoneNumber, authNumber, batchId, repairTicketNumber, scNumber } = this.state.filters;

    return (
      <div className="claimSearchFilters">
        <div className="">
          <div className="box">
            <div className="columns">
              <div className="column">

                <div className="field">
                  <label className="label">From Date</label>
                  <div className="control">
                    <DatePicker className="input" onChange={(date) => this.handleDateChange(date, 'fromDate')} value={fromDate} placeholderText="YYYY-MM-DD" />
                  </div>
                </div>

                <div className="field">
                  <label className="label">To Date</label>
                  <div className="control">
                    <DatePicker className="input" onChange={(date) => this.handleDateChange(date, 'toDate')} value={toDate} placeholderText="YYYY-MM-DD" />
                  </div>
                </div>

              </div>

              <div className="column">

                <div className="field">
                  <label className="label">Status</label>
                  <div className="control">
                    <div className="select">
                      <select name="status" onChange={this.handleChange} value={status} >
                        <option value="">Select Status</option>
                        <option value="NO SALES FOUND">NO SALES FOUND</option>
                        <option value="REJECTED">REJECTED</option>
                        <option value="PROCESSING">PROCESSING</option>
                        <option value="PAID">PAID</option>
                        <option value="INVALID_CONTRACT">INVALID CONTRACT</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Service Request Number</label>
                  <p className="control">
                    <input className="input" name="srNumber" type="text"
                      value={srNumber}
                      onChange={this.handleChange} />
                  </p>
                </div>

              </div>

              <div className="column">

                <div className="field">
                  <label className="label">Customer's Phone</label>
                  <p className="control">
                    <input className="input" name="phoneNumber" type="text" placeholder="555-555-5555"
                      value={phoneNumber}
                      onChange={this.handleChange} />
                  </p>
                </div>

                <div className="field">
                  <label className="label">Batch Number</label>
                  <p className="control">
                    <input className="input" name="batchId" type="text"
                      value={batchId}
                      onChange={this.handleChange} />
                  </p>
                </div>

              </div>


              <div className="column">

                {
                  !user.isClient() ?
                    <ClientSelector name="clientNumber" onChange={this.handleChange} value={clientNumber} required={true} />
                    :
                    <ServicerSelector name="scNumber" onChange={this.handleChange} value={scNumber} />
                }

                <div className="field">
                  <label className="label">Repair Ticket Number</label>
                  <p className="control">
                    <input className="input" name="repairTicketNumber" type="text"
                      value={repairTicketNumber}
                      onChange={this.handleChange} />
                  </p>
                </div>

              </div>

              <div className="column">
                <div className="field">
                  <label className="label">Authorization Number</label>
                  <p className="control">
                    <input className="input" name="authNumber" type="text"
                      value={authNumber}
                      onChange={this.handleChange} />
                  </p>
                </div>

                <div className="field">
                  <label className="label">Transaction Number</label>
                  <p className="control">
                    <input className="input" name="transactionNumber" type="text"
                      value={transactionNumber}
                      onChange={this.handleChange} />
                  </p>
                </div>
              </div>

            </div>

            <div className="buttons">
              <button className="button is-info" onClick={this.onFilterChange}>
                <span className="icon">
                  <i className="fas fa-search"></i>
                </span>
                <span>Search</span>
              </button>

              <button className="button" onClick={this.onClearClick}>
                <span className="icon">
                  <i className="fas fa-backspace"></i>
                </span>
                <span>Clear</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}