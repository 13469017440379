import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function StepLoading({ message }) {
  return (
    <Paper>
      <div style={{ minHeight: "200px" }}>
        <Grid container direction="column" justify="center" alignItems="center">
          <div style={{ paddingTop: "20px" }}>
            <CircularProgress />
          </div>
          <div>
            <h3>{message}</h3>
          </div>
        </Grid>
      </div>
    </Paper>
  );
}
