const endline = '\n';
const moment = require('moment');
const yyyymmdd = (datestr) => datestr ? moment.utc(datestr).format('YYYY-MM-DD') : '';

function dsvReader(file, delimiter = ",") {
    return new Promise((resolve) => {
        const fileReader = new FileReader();
        fileReader.readAsText(file);
        fileReader.onloadend = function () {
            const data = fileReader.result;
            const rows = data.replace(/\r\n/g, endline).split(endline).filter(row => row.trim().length > 0);
            const parsedData = rows.map(row => row.split(delimiter));
            const [ header, ...claims ] = parsedData;
            console.log(header);
            resolve(claims);
        }
    });
}

function parse(file, clientId, claimStatus, sentDate) {
    return new Promise((resolve, reject) => {

        dsvReader(file).then((rows) => {
            const mapper = getMapper(clientId, claimStatus, sentDate);
            const mapped = rows.map(mapper);
            resolve(mapped);

        }).catch((errors) => {
            console.error(errors);
            resolve([]);
        })
    });
}

function getMapper(clientId, claimStatus, sentDate) {
    return function rowMapper(data) {
        return {
            storeNumber: data[0],
            registerNumber: data[1],
            purchaseDate: yyyymmdd(data[2]),
            transactionNumber: data[3],
            repairTicketNumber: data[4],
            firstname: data[5],
            lastname: data[6],
            phonenumber: data[7],
            claimAmount: data[8],
            status: data[9],
            claimId: data[10],
            address: data[11],
            city: data[12],
            state: data[13],
            zip: data[14],
            failureDate: yyyymmdd(data[15]),
            newPurchStoreNumber: data[16],
            newPurchStoreRegisterNumber: data[17],
            newPurchaseDate: yyyymmdd(data[18]),
            productPurchaseDate: yyyymmdd(data[18]),
            newPurchTransNumber: data[19],
            clientId,
            claimStatus,
            sentDate: yyyymmdd(sentDate)
        }
    }
}

const parserFn = {
    parse
};

export default parserFn;
