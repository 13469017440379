import Pagination from "react-js-pagination";
import React, { useState } from "react";
import { searchClaimHeaderFile } from './portalSubmissionAction';
import { connect } from "react-redux";

function ClaimHeaderFileTablePagination({ onSearchClaimHeaderFile, totalCount, activePage }) {
	const initialPaginationProps = {
		itemsCountPerPage: 10,
		pageRangeDisplayed: 10
	};

	const [paginationProps] = useState(initialPaginationProps);

	function handlePageChange(activePage) {
		onSearchClaimHeaderFile(null, activePage);
	}

	return (
		<nav id="portalSubmissionReportTablePagination"
			className="pagination is-centered"
			role="navigation"
			aria-label="pagination">
			<Pagination
				onChange={handlePageChange}
				activePage={activePage}
				totalItemsCount={totalCount}
				itemsCountPerPage={paginationProps.itemsCountPerPage}
				pageRangeDisplayed={paginationProps.pageRangeDisplayed}
				activeLinkClass={"is-current"}
				linkClass={"pagination-link"}
				innerClass={"pagination-list"}
			/>
		</nav>
	)
}

const mapDispatchToProps = dispatch => {
	return {
		onSearchClaimHeaderFile: (param, activePage) => {
			dispatch(searchClaimHeaderFile(param, activePage));
		}
	};
};

const mapStateToProps = state => {
	return {
		totalCount: state.portalSubmission.totalCount,
		activePage: state.portalSubmission.activePage
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(ClaimHeaderFileTablePagination);
