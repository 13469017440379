import React from 'react';
import { clientService } from './clientService';

export function useClientList() {
  const [clientList, setClientList] = React.useState([]);

  React.useEffect(() => {
    clientService.getClientList().then(data => {
      // console.log(data);
      setClientList(data);
    })
      .catch(console.error);
  }, []);

  return clientList;
}

export function ClientSelectorFormComponent(props) {
  const clientList = useClientList();
  const { required = false, ...rest } = props;

	return (
    <div className="field">
      <label className="label">Client{required ? <span className="has-text-danger"> *</span>: null }</label>
      {clientList && clientList.length ?
        <div className="control">
          <div className="select is-fullwidth">
            <select { ...rest }>
              <option value="">Select Client</option>
              {clientList.map(cl => <option key={cl.clientId} value={cl.clientId} >{cl.clientName} ({cl.clientId})</option>)}
            </select>
          </div>
        </div>
        :
        <div className="control is-loading">
          <input className="input" type="text" value="Loading Clients..." readOnly="readOnly" />
        </div>
      }
    </div>
  );
}
