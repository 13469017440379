const PREADJUDICATION_FAILED = ['repairCode', 'laborAmount', 'partsAmount', 'shipAmount', 'taxAmount', 'productSku', 'productPurchasePrice'];
const ADJUDICATION_FAILED = ['laborAmount', 'partsAmount', 'shipAmount', 'taxAmount', 'authorizationNumber', 'productPurchasePrice', 'productSku']; // all buttons
const NO_SALES_FOUND = ['purchaseStoreNumber', 'repairRegisterNumber', 'productPurchaseDate', 'repairTransNumber', 'transactionNumber', 'productPurchasePrice', 'productSku']; // cancel, manually_paid
const CONTRACT_MATCH_FAILED = ['srNumber', 'unitPrice', 'failureDate', 'scReceiveDate', 'scShipDate', 'productPurchasePrice', 'productSku']; // no override
const CLAIM_VALIDATE_FAILED = ['srNumber'];
const CLAIM_FAILED = ['productSku']; // no override
const NSF_PROCESSED = ['purchaseStoreNumber', 'repairRegisterNumber', 'productPurchaseDate', 'repairTransNumber', 'transactionNumber', 'productPurchasePrice', 'productSku']; // cancel, manually_paid
const SALES_PENDING = ['productSku']; // no buttons
const INVALID_SR = ['srNumber', 'authorizationNumber', 'productPurchasePrice', 'productSku'];


export function inputEnableChecker(status) {
  if ('INVALID_DATA' === status || 'INVALID' === status) {
    return function (name) {
      return true;
    }
  }

  const enabledFields =
    'PREADJUDICATION_FAILED' === status ? PREADJUDICATION_FAILED :
      'ADJUDICATION_FAILED' === status ? ADJUDICATION_FAILED :
        'NO_SALES_FOUND' === status ? NO_SALES_FOUND :
          'CONTRACT_MATCH_FAILED' === status ? CONTRACT_MATCH_FAILED :
            'CLAIM_FAILED' === status ? CLAIM_FAILED :
              'NSF_PROCESSED' === status ? NSF_PROCESSED :
                'SALE_NOT_FOUND' === status ? NO_SALES_FOUND :
                  'NSF_SENT' === status ? NO_SALES_FOUND :
                    'NO_SALES_FOUND_NEW' === status ? NO_SALES_FOUND :
                      'SALES_PENDING' === status ? SALES_PENDING :
                        'INVALID_SR' === status ? INVALID_SR :
                          'CLAIM_VALIDATE_FAILED' === status ? CLAIM_VALIDATE_FAILED : [];

  return function (name) {
    return enabledFields.includes(name)
  }
}