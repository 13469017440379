import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Loading from '../LoadingModal';
import InfoMessage from '../InfoMessage';
import userManager from '../../services/userManager';
import CustomSnackbar, { SEVERITY, useSnackbar } from '../common/CustomSnackbar';
import ClaimSearchTable from './ClaimSearchTable';
import ClaimSearchTablePagination, { usePagination } from './ClaimSearchTablePagination';
import Filters from './ClaimSearchFilters';

import { getClaimsFiltered } from './claimSearchService';

function useClaims(filters, activePage = 0, totalCountPerPage = 10) {
  const [claims, setClaims] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);

  React.useEffect(() => {
    if (!filters) {
      return;
    }

    setLoading(true);

    let max = totalCountPerPage * activePage;
    let offset = max - totalCountPerPage;

    const params = {
      ...filters,
      offset,
      max
    }

    console.log('filters: ', params)

    getClaimsFiltered(params)
      .then(result => {
        setLoading(false);
        const newClaims = JSON.parse(result);
        if (Array.isArray(newClaims) && newClaims.length > 0) {
          setTotalCount(newClaims[0].RESULT_COUNT);
        } else {
          setTotalCount(0);
        }

        setClaims(newClaims);
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  }, [filters, activePage, totalCountPerPage]);

  const onClear = () => {
    setClaims([]);
  }

  return {
    claims,
    setClaims,
    loading,
    onClear,
    totalCount
  }
}

function useFilters() {
  const [filters, setFilters] = React.useState(undefined);
  const snackbar = useSnackbar();
  const user = userManager.getUser();

  const onFilterChange = (filters) => {
    const { clientNumber, scNumber } = filters;

    if (user.isClient() && !scNumber) {
      snackbar.handleOpen(SEVERITY.error, 'Servicer is required');

    } else if (!user.isClient() && !clientNumber) {
      snackbar.handleOpen(SEVERITY.error, 'Client is required');

    } else {
      setFilters({
        ...filters
      });
    }
  }

  return {
    filters,
    onFilterChange,
    snackbar
  }
}

function ClaimSearch() {

  const pagination = usePagination();
  const filters = useFilters();
  const claims = useClaims(filters.filters, pagination.activePage);

  const onFilterChange = (params) => {
    pagination.setActivePage(1);
    filters.onFilterChange(params);
  }

  React.useEffect(() => {
    pagination.setTotalItemsCount(claims.totalCount)
  }, [pagination, claims.totalCount]);

  return (
    <div>
      <Header title='Claims Search' />

      <div className="container">
        <div>
          <div style={{ padding: "1.5em 1em" }}>
            <Filters onFilterChange={onFilterChange} onClear={claims.onClear} />
          </div>
          <div className="section">
            <ClaimSearchTable claims={claims.claims} totalCount={claims.totalCount} />
            <ClaimSearchTablePagination {...pagination} />
          </div>
          <div className="section">
            <InfoMessage title="Search Tips" messageComponent={SearchTips} />
          </div>
        </div>
        <CustomSnackbar {...filters.snackbar} />
      </div>

      <Footer />
      <Loading active={claims.loading} isDark={false} />
    </div>
  );
}



function SearchTips() {
  return (
    <ul>
      <li>Search using more than one criteria will reduce the amount of data returned.              </li>
      <li>Try to use the batch number or transaction number in your search.                         </li>
      <li>When using the date range keep the range as small as possible for faster/slower results.  </li>
      <li>Larger results from date range searches will be limited to a thousand records.            </li>
      <li>When using the Consumer Phone Number please use numbers and dashes only.                  </li>
      <li>Please use the correct format for searches using the Transaction Number.                  </li>
    </ul>
  );
}




export default ClaimSearch;

