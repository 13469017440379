import userManager from '../../services/userManager';

// fix webpack 5 error: Should not import the named export 'accessControl' (imported as 'accessControl') from default-exporting module (only default export is available soon)
import config from '../../config/config.json';
const { accessControl } = config

const findUserName = (userName) => userName === userManager.getUser().userName.toUpperCase();
const findUid = (uid) => uid === userManager.getUser().uid.toUpperCase();
const findEmail = (email) => email === userManager.getUser().mail.toUpperCase();

export const routeService = (function () {
  const service = {
    isLogin: () => {
      const user = userManager.getUser();
      const userIsSet = !!user;
      return userIsSet;
    },
    hasControl: (path) => {

      const pathControl = accessControl[path];

      if (!pathControl) {
        return true;
      }

      const { users = [], email = [] } = pathControl;
      if (users.length || email.length) {
        const validUser = users.map(u => u.toUpperCase()).filter(u => findUserName(u) || findUid(u)).length > 0;
        const validEmail = email.map(e => e.toUpperCase()).filter(findEmail).length > 0

        // console.log('valid user', validUser);
        // console.log('valid email', validEmail);

        return validUser || validEmail;
      }

      return true;
    }
  };

  return service;
})();
