import React from 'react';
import './App.sass';
import './App.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Login from './components/login/Login';
import Logout from './components/login/Logout';
import ClaimSearch from './components/claimsearch/ClaimSearch';
import Home from './components/Home';
import ResetPassword from './components/password/ResetPassword';
import NewPassword from './components/password/NewPassword';
// import BatchSubmission from './components/batch/BatchSubmission';
import FAQ from './components/FAQ';
import AS400 from './components/AS400';
import ClaimsReporting from './components/report/ClaimsReporting';
import ClientMaintenance from './components/ClientMaintenance';
import MyWorkBench from './components/workbench/MyWorkBench';
import ManualPayment from './components/manualpayment/ManualPayment';
import ComingSoon from './components/ComingSoon';
import ClaimsHistory from './components/claimhistory/ClaimsHistory';
import ClientWorkBench from './components/workbench/ClientWorkBench';
import ClaimErrors from './components/workbench/ClaimErrors';
import UnauthorizedAccess from './components/UnauthorizedAccess';
import ClaimsUploaderMain from './components/uploader/ClaimsUploaderMain';
import CorrectionFileUploaderMain from './components/correction/CorrectionFileUploaderMain';
import PortalSubmission from "./components/portalsubmission/PortalSubmission";
import PageNotFound from './components/PageNotFound';
import EntitlementCheckMain from './components/entitlement/EntitlementCheckMain';
import NsfPaymentRelease from './components/nsfpaymentrelease/NsfPaymentRelease';
import DownloadReports from './components/downloadreports/DownloadReports';

import PrivateRoute from './components/routing/PrivateRoute';
import PublicRoute from './components/routing/PublicRoute';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="appContent">
          <Switch>

            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/resetpassword" component={ResetPassword} />
            <PublicRoute exact path="/setpassword" component={NewPassword} />
            <PrivateRoute exact path="/claimsearch" component={ClaimSearch} />
            <PrivateRoute exact path="/batchsubmission" component={ClaimsUploaderMain} />
            <PrivateRoute exact path="/faq" component={FAQ} />
            <PrivateRoute exact path="/reporting" component={ClaimsReporting} />
            <PrivateRoute exact path="/portalreport" component={PortalSubmission} />
            <PrivateRoute exact path="/as400entitlement" component={AS400} />
            <PrivateRoute exact path="/comingsoon" component={ComingSoon} />
            <PrivateRoute exact path="/clientmaintenance" component={ClientMaintenance} />
            <PrivateRoute exact path="/workbench" component={MyWorkBench} />
            <PrivateRoute exact path="/correctionfile" component={CorrectionFileUploaderMain} />
            <PrivateRoute exact path="/entitlement" component={EntitlementCheckMain} />
            <PrivateRoute exact path="/manualpayment" component={ManualPayment} />
            <PrivateRoute exact path="/nsfpaymentrelease" component={NsfPaymentRelease} />

            <PrivateRoute exact path="/clientworkbench" render={(props) => {
              //props.location.search;
              const params = new URLSearchParams(props.location.search);
              const clientId = params.get('clientId');
              const scNumber = params.get('scNumber');

              return <ClientWorkBench clientId={clientId} scNumber={scNumber} />
            }} />

            <PrivateRoute exact path="/claimshistory/:claimRecord" render={(props) => {
              return <ClaimsHistory claimRecord={props.match.params.claimRecord} />
            }} />

            <PrivateRoute exact path="/claimerrors" render={(props) => {
              const params = new URLSearchParams(props.location.search);
              const claimId = params.get('claimId');
              const repairTicketNumber = params.get('repairTicketNumber');
              const claimStatus = params.get('claimStatus');

              console.log(`Claim errors page: for ${claimId} claimId and ${repairTicketNumber} repairTicketNumber`);

              return <ClaimErrors claimId={claimId} repairTicketNumber={repairTicketNumber} claimStatus={claimStatus} />
            }} />

            <Route path="/login" render={(props) => {
              const params = new URLSearchParams(props.location.search);
              const status = params.get('status');
              return <Login status={status} />
            }} />

            <PrivateRoute exact path="/downloadreports" component={DownloadReports} />

            <PublicRoute exact path="/unauthorizedaccess" component={UnauthorizedAccess} />

            <Route exact path="/logout" component={Logout} />
            <PrivateRoute exact path="/" component={Home} />

            { /**
            <Route exact 
                path="/verify/:userId/:groupId"
                render={(props) => {
                  const { groupId, userId } = props.match.params;
                  return <Verify groupId={groupId} userId={userId} />
                }} />
            */}

            <PrivateRoute path='*' exact={true} component={PageNotFound} />

          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;

