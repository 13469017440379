import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import InfoMessage from '../InfoMessage';
import userManager from '../../services/userManager';
import { passwordService } from './passwordService';
import CustomSnackbar, { SEVERITY, useSnackbar } from '../common/CustomSnackbar';

class ResetPassword extends React.Component {
  render() {
    return (
      <div>
        <Header title='Reset Password' subtitle='JCA Portal' />

        <div className="container">
          <div className="section">
            <ResetForm />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}



function ResetForm() {
  const user = userManager.getUser();
  const oldPassword = useFormInput('', false);
  const confirmPassword = useFormInput('');
  const newPassword = useFormInput('');
  const [loading, setLoading] = React.useState(false);

  const snackbar = useSnackbar();
  const allValid = oldPassword.isValid() && newPassword.isValid() && confirmPassword.isValid();

  const onSubmit = () => {
    setLoading(true);
    if (confirmPassword.value !== newPassword.value) {
      snackbar.handleOpen(SEVERITY.error, 'New and Confirm Passwords do not match!');
      setLoading(false);

    } else {
      passwordService.resetPassword(user.userName, oldPassword.value, newPassword.value).then((result => {
         if (401 === result.statusCode) {
          snackbar.handleOpen(SEVERITY.error, 'Invalid old password!');
        } else if (400 === result.statusCode) {
          console.error(JSON.stringify(result));
          snackbar.handleOpen(SEVERITY.error, 'Reset password failed. Please contact administrator.');
        } else {
          snackbar.handleOpen(SEVERITY.success, 'Password successfully updated!');
          oldPassword.clear();
          newPassword.clear();
          confirmPassword.clear();
        }

        setLoading(false);
      })).catch(console.error);
    }
  }

  return (
    <div className="resetPasswordForm">
      <div className="hero is-fullheight" style={{ padding: "1em" }}>

        <div className="columns">
          <div className="column is-4 is-offset-1">

            <div className="box">

              <div className="field">
                <label className="label">Username</label>
                <p className="control">
                  <input className="input" type="text" placeholder="Username" value={user.userName} readOnly="readOnly" />
                </p>
              </div>

              <PasswordField {...oldPassword} label="Old Password" />
              <PasswordField {...newPassword} label="New Password" />
              <PasswordField {...confirmPassword} label="Confirm Password" />

              <div className="buttons">
                {allValid ?
                  < button className={!loading ? "button is-info" : "button is-info is-loading"} onClick={onSubmit}>
                    <span>Update Password</span>
                  </button>
                  :
                  <button className="button is-info" onClick={onSubmit} disabled>
                    <span>Update Password</span>
                  </button>
                }
              </div>

            </div>
          </div>

          <div>
            <CustomSnackbar {...snackbar} />
          </div>

          <div className="column is-offset-1" >
            <InfoMessage title="Password Reset Tips" messageComponent={PasswordResetTips} />
          </div>
        </div>
      </div>
    </div >
  );
}

function PasswordResetTips() {
  return (
    <ul>
      <li>Password must be a length of eight or more</li>
      <li>Password must contain a minimum of two upper case characters</li>
      <li>Password must contain a minimum of two digits</li>
      <li>Password must contain at least one special character</li>
      <li>Cannot use the same password used for the five previous passwords</li>
    </ul>
  );
}

function PasswordField(props) {
  const { label, errors, isValid, isEdited, ...rest } = props;
  return (
    <div className="field">
      <label className="label">{label}</label>
      <p className="control">
        <input className={!isEdited() ? 'input' : isValid() ? 'input' : 'input is-danger'} type="password" {...rest} />
      </p>
      <p className="help is-danger" style={{ fontSize: "0.80rem" }}>{errors}</p>
    </div>
  );
}

function useFormInput(initialValue, withValidations = true) {
  const [value, setValue] = React.useState(initialValue);
  const [errors, setErrors] = React.useState('');

  function handleValueChange(event) {
    const value = event.target.value;
    setValue(value);


    if (withValidations) {
      const newErrors = validatePassword(value);
      if (newErrors.length) {
        setErrors(newErrors.join('\n'));
      } else {
        setErrors('');
      }
    }
  }

  return {
    value,
    onChange: handleValueChange,
    errors,
    isValid: () => {
      return !!value && !errors;
    },
    isEdited: () => {
      return value.length > 0;
    },
    clear: () => {
      setErrors('');
      setValue('');
    }
  }
}

function validatePassword(password) {
  let newErrors = [];
  if (password.trim().length < 8) {
    newErrors.push("Password must be 8 characters minimum.");
  }

  var hasUpperCase = /[A-Z]/.test(password);
  if (!hasUpperCase) {
    newErrors.push("Password must contain Upper case characters.");
  }

  var hasLowerCase = /[a-z]/.test(password);
  if (!hasLowerCase) {
    newErrors.push("Password must contain Lower case characters.");
  }

  var hasNonalphas = /\W/.test(password);
  if (!hasNonalphas) {
    newErrors.push("Password must contain a non-alpha character.");
  }

  return newErrors;
}

export default ResetPassword;
