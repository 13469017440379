import React from 'react';
import { postNsfPaymentRelease } from './nsfPaymentReleaseService';
import StepLoading from './StepLoading';

function useDataUpload(data, callback) {
  React.useEffect(() => {
    console.log('data upload...');
    if (data && data.length) {
      const allClaimIds = data.map(d => d.claimId);
      console.log('allClaimIds', allClaimIds)
      postNsfPaymentRelease(allClaimIds).then((result) => {
        callback(result);
      });
    }
  }, [data, callback]);
}

export default function FileParser({ data, callback }) {
  useDataUpload(data, callback);

  if (!data) {
    return;
  }

  return (
    <div>
      <StepLoading message={`Uploading ${data.length} claims for payment release...`} />
    </div >
  );
}