import config from '../../config/config.json';
import caller from '../../services/caller';

export async function getClaimsFiltered(filters) {
  
  // const { fromDate, toDate, status, srNumber, clientNumber, transactionNumber, phoneNumber, authNumber, batchId, repairTicketNumber } = filters;
  const filtersBase64 = Buffer.from(JSON.stringify(filters)).toString('base64');
  let url = `${config.api.claimSearch}?filters=${filtersBase64}`

  try {
    const clients = await caller.get(url);
    return clients;

  } catch (error) {
    console.error(error);
    return [];
  }
}