import React from 'react';
import { useClaim } from './selectedClaim';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import CustomDatePicker from '../common/CustomDatePicker';
import ManualPaymentData from './ManualPaymentData';
import CustomSnackbar from '../common/CustomSnackbar';
import { SEVERITY, useSnackbar } from '../common/CustomSnackbar';
import { postManualPayment, postClaimHeaderUpdate } from './manualPaymentService';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
    '&': {
      padding: 20
    }
  },
  heading: {
    paddingBottom: 5,
    paddingTop: 10,
    fontWeight: "bold"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  dateControl: {
    minWidth: 260,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

export default function ManualPaymentEdit(props) {
  const classes = useStyles();
  const { claimId } = props;
  const { claim, setClaim } = useClaim(claimId);
  const snackbar = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const handleChange = (event) => {
    let { name, value } = event.target;
    value = value ? value : undefined;
    const updated = Object.assign({}, claim);
    updated[name] = value;
    setClaim(updated);
  };

  // const { amountLines, setAmountLines } = useAmountLines(claimId);
  // const handleAmtChange = (event, amt, index) => {
  //   let { name, value } = event.target;
  //   value = value ? value : undefined;
  //   const updated = { ...amt };
  //   updated[name] = parseFloat(value);
  //   amountLines[index] = updated;
  //   setAmountLines(amountLines);
  // }

  const onSubmit = () => {
    setLoading(true);
    const manualPaymentData = ManualPaymentData.from(claim);
    // console.log('Submitting', JSON.stringify(manualPaymentData.toJSON()));
    // console.log('Saving', JSON.stringify(manualPaymentData.toUpdateJSON()));

    postManualPayment(manualPaymentData).then(() => {
      
      return postClaimHeaderUpdate(manualPaymentData);

    }).then(() => {
      snackbar.handleOpen(SEVERITY.info, 'Manual Payment successfully submitted!');
      setLoading(false);
      setSubmitted(true);

    }).catch((error) => {
      console.error(error);
      snackbar.handleOpen(SEVERITY.error, 'Error occured while submitting manual payment! Please contact administrator');
      setLoading(false);
    });
  };

  if (claim === undefined) {
    return (
      <p>Loading...</p>
    );
  }

  return (
    <div className={classes.root}>
      <CustomSnackbar {...snackbar} />
      <div>
        <p className={classes.heading}>Claim Header</p>
        <Divider />
        <br />

        <TextField variant="outlined" size="small" label="Claim Id" name="claimId" value={claim.claimId} InputProps={{ readOnly: true }} />
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Batch Id" name="batchId" value={claim.batchId|| ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="Doc Id" name="docId" value={claim.docId|| ''} /> */}
        <TextField variant="outlined" size="small" label="Transaction Id" name="transactionId" value={claim.transactionId} InputProps={{ readOnly: true }} />
        
        <TextField variant="outlined" onChange={handleChange} size="small" label="Client Id" name="clientId" value={claim.clientId || ''} />
        <TextField variant="outlined" onChange={handleChange} size="small" label="Client Party Id" name="clientPartyId" value={claim.clientPartyId || ''} />
        <TextField variant="outlined" onChange={handleChange} size="small" label="SC Number" name="scNumber" value={claim.scNumber || ''} />
        <TextField variant="outlined" onChange={handleChange} size="small" label="Repair Ticket Number" name="repairTicketNumber" value={claim.repairTicketNumber || ''} />
        <TextField variant="outlined" onChange={handleChange} size="small" label="Repair Store Number" name="repairStoreNumber" value={claim.repairStoreNumber || ''} />
        <TextField variant="outlined" onChange={handleChange} size="small" label="Purchase Store Number" name="purchaseStoreNumber" value={claim.purchaseStoreNumber || ''} />
        <TextField variant="outlined" onChange={handleChange} size="small" label="Repair Register Number" name="repairRegisterNumber" value={claim.repairRegisterNumber || ''} />
        <TextField variant="outlined" onChange={handleChange} size="small" label="Repair Transaction Number" name="repairTransNumber" value={claim.repairTransNumber || ''} />
        <TextField variant="outlined" onChange={handleChange} size="small" label="Transaction Number" name="transactionNumber" value={claim.transactionNumber || ''} />

        <TextField variant="outlined" onChange={handleChange} size="small" label="Status" name="status" value={claim.status || ''} />

        <TextField variant="outlined" onChange={handleChange} size="small" label="Failure Code" name="failureCode" value={claim.failureCode || ''} />

        <TextField variant="outlined" onChange={handleChange} size="small" label="Authorization Number" name="authorizationNumber" value={claim.authorizationNumber || ''} />
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Work Done" name="workDone" value={claim.workDone || ''} /> */}
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Notes" name="notes" value={claim.notes || ''} /> */}
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Duplicate Claim" name="duplicateClaim" value={claim.duplicateClaim || ''} /> */}
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Contract Number" name="contractNumber" value={claim.contractNumber || ''} /> */}
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Customer Party Id" name="customerPartyId" value={claim.customerPartyId || ''} /> */}
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Installed Base Id" name="installedbaseId" value={claim.installedbaseId || ''} /> */}

        <TextField variant="outlined" onChange={handleChange} size="small" label="SR Number" name="srNumber" value={claim.srNumber || ''} />
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Repl SR Number" name="replSrNumber" value={claim.replSrNumber || ''} /> */}

        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Claim Source" name="claimSource" value={claim.claimSource || ''} /> */}
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="MP Sales Id" name="mpSalesId" value={claim.mpSalesId || ''} /> */}

        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Currency Code" name="currencyCode" value={claim.currencyCode || ''} /> */}


        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="BPM Id" name="bpmId" value={claim.bpmId|| ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="BPM Server" name="bpmServer" value={claim.bpmServer|| ''} /> */}

        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Payment Status" name="paymentStatus" value={claim.paymentStatus || ''} /> */}
        <TextField variant="outlined" onChange={handleChange} size="small" label="Plan Type" name="planType" value={claim.planType || ''} />
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Warranty Transaction Id" name="warrTransactionId" value={claim.warrTransactionId || ''} /> */}
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Repair Product" name="repairProduct" value={claim.repairProduct || ''} /> */}
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Warranty Price Low" name="warrantyPriceLow" value={claim.warrantyPriceLow || ''} /> */}
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Warranty Price High" name="warrantyPriceHigh" value={claim.warrantyPriceHigh || ''} /> */}
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Sr RecordId" name="srRecordId" value={claim.srRecordId || ''} /> */}
        {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Repl Sr Record Id" name="replSrRecordId" value={claim.replSrRecordId || ''} /> */}

        <TextField variant="outlined" onChange={handleChange} size="small" label="Paygroup" name="paygroup" value={claim.paygroup || ''} />

        <div>
          <br />
          <p className={classes.heading}>Customer Details</p>
          <Divider />
          <br />

          <TextField variant="outlined" onChange={handleChange} size="small" label="First Name" name="firstName" value={claim.firstName || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Last Name" name="lastName" value={claim.lastName || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Address" name="address" value={claim.address || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Address Line 2" name="addressLine2" value={claim.addressLine2 || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Address Line 3" name="addressLine3" value={claim.addressLine3 || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="City" name="city" value={claim.city || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="State" name="state" value={claim.state || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Province" name="province" value={claim.province || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Zip" name="zip" value={claim.zip || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Country Code" name="countryCode" value={claim.countryCode || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Phone Area Code" name="phoneAreaCode" value={claim.phoneAreaCode || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Phone Number" name="phoneNumber" value={claim.phoneNumber || ''} />
        </div>

        <div>
          <br />
          <p className={classes.heading}>Product Details</p>
          <Divider />
          <br />

          <TextField variant="outlined" onChange={handleChange} size="small" label="Unit Price" name="unitPrice" value={claim.unitPrice || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Product Purchase Price" name="productPurchasePrice" value={claim.productPurchasePrice || ''} />
          {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Product" name="product" value={claim.product || ''} /> */}
          <TextField variant="outlined" onChange={handleChange} size="small" label="Product Sku" name="productSku" value={claim.productSku || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Product Description" name="productDescription" InputProps={{ readOnly: true }} value={claim.productDescription || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Brand" name="brand" value={claim.brand || ''} InputProps={{ readOnly: true }} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Model Number" name="modelNumber" value={claim.modelNumber || ''} InputProps={{ readOnly: true }} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Serial Number" name="serialNumber" value={claim.serialNumber || ''} InputProps={{ readOnly: true }} />
          {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Oracle Product" name="oracleProduct" value={claim.oracleProduct || ''} /> */}
          {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Oracle Warranty Sku" name="oracleWarrantySku" value={claim.oracleWarrantySku || ''} /> */}
          {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Client Warranty Sku" name="clientWarrantySku" value={claim.clientWarrantySku || ''} /> */}
        </div>

        <div>
          <br />
          <p className={classes.heading}>Dates</p>
          <Divider />
          <br />
          <CustomDatePicker className={classes.dateControl} request={claim} onChange={handleChange} size="small" label="Product Purchase Date" field="productPurchaseDate" value={claim.productPurchaseDate || ''} />
          <CustomDatePicker className={classes.dateControl} request={claim} onChange={handleChange} size="small" label="Failure Date" field="failureDate" value={claim.failureDate || ''} />
          {/* <CustomDatePicker className={classes.dateControl} request={claim} onChange={handleChange} size="small" label="SC Receive Date" field="scReceiveDate" value={claim.scReceiveDate || ''} /> */}
          <CustomDatePicker className={classes.dateControl} request={claim} onChange={handleChange} size="small" label="SC Ship Date" field="scShipDate" value={claim.scShipDate || ''} />
          {/* <CustomDatePicker className={classes.dateControl} request={claim} onChange={handleChange} size="small" label="Adjudicate Date" field="adjudicateDate" value={claim.adjudicateDate || ''} /> */}
          {/* <CustomDatePicker className={classes.dateControl} request={claim} onChange={handleChange} size="small" label="Report Sent Date" field="rptSentDate" value={claim.rptSentDate || ''} /> */}
          {/* <CustomDatePicker className={classes.dateControl} request={claim} onChange={handleChange} size="small" label="Report Update Date" field="rptUpdateDate" value={claim.rptUpdateDate || ''} /> */}
          {/* <CustomDatePicker className={classes.dateControl} request={claim} onChange={handleChange} size="small" label="NS Sent Date" field="nsSentDate" value={claim.nsSentDate || ''} /> */}
          {/* <CustomDatePicker className={classes.dateControl} request={claim} onChange={handleChange} size="small" label="NS Update Date" field="nsUpdateDate" value={claim.nsUpdateDate || ''} /> */}
          {/* <CustomDatePicker className={classes.dateControl} request={claim} onChange={handleChange} size="small" label="Store Return Sent Date" field="storeReturnSentDate" value={claim.storeReturnSentDate || ''} /> */}
          {/* <CustomDatePicker className={classes.dateControl} request={claim} onChange={handleChange} size="small" label="Store Return Update Date" field="storeReturnUpdateDate" value={claim.storeReturnUpdateDate || ''} /> */}
        </div>

        <div>
          <br />
          <p className={classes.heading}>Flags</p>
          <Divider />
          <br />

          {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Renewal" name="renewal" value={claim.renewal || ''} /> */}
          <TextField variant="outlined" onChange={handleChange} size="small" label="NSF Flag" name="nsfFlag" value={claim.nsfFlag || ''} />
          {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Store Return Flag" name="storeReturnFlag" value={claim.storeReturnFlag || ''} /> */}
          <TextField variant="outlined" onChange={handleChange} size="small" label="Invalid Contract Flag" name="invalidContractFlag" value={claim.invalidContractFlag || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Byot Flag" name="byotFlag" value={claim.byotFlag || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="Repl Flag" name="replFlag" value={claim.replFlag || ''} />
          <TextField variant="outlined" onChange={handleChange} size="small" label="OverRide Flag" name="overRideFlag" value={claim.overRideFlag || ''} />
          {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Lock Flag" name="lockFlag" value={claim.lockFlag || ''} /> */}
          {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Transfer Flag" name="transferFlag" value={claim.transferFlag || ''} /> */}
          {/* <TextField variant="outlined" onChange={handleChange} size="small" label="Handling Fee Flag" name="handlingFeeFlag" value={claim.handlingFeeFlag || ''} /> */}
        </div>

        <div>
          <br />
          <p className={classes.heading}>Approved Amounts</p>
          <Divider />
          <br />

          <TextField type="number" variant="outlined" onChange={handleChange} size="small" label="Approved Labor" name="approvedLabor" value={claim.approvedLabor || ''} />
          <TextField type="number" variant="outlined" onChange={handleChange} size="small" label="Approved Parts" name="approvedParts" value={claim.approvedParts || ''} />
          <TextField type="number" variant="outlined" onChange={handleChange} size="small" label="Approved Ship" name="approvedShip" value={claim.approvedShip || ''} />
          <TextField type="number" variant="outlined" onChange={handleChange} size="small" label="Approved Tax" name="approvedTax" value={claim.approvedTax || ''} />
          {/* <TextField type="number" variant="outlined" onChange={handleChange} size="small" label="Approved Qst" name="approvedQst" value={claim.approvedQst || ''} /> */}
          {/* <TextField type="number" variant="outlined" onChange={handleChange} size="small" label="Approved Gst" name="approvedGst" value={claim.approvedGst || ''} /> */}
          {/* <TextField type="number" variant="outlined" onChange={handleChange} size="small" label="Auth Amount" name="authAmount" value={claim.authAmount || ''} /> */}
          <TextField type="number" variant="outlined" onChange={handleChange} size="small" label="Total Approved Amount" name="totalApprovedAmount" value={claim.totalApprovedAmount || ''} />
        </div>
      </div>

      {/* <br />
      {amountLines.length > 0 ?
        <div>
          <br />
          <p className={classes.heading}>Amount Lines</p>
          {amountLines.map((amt, amtIndex) =>
            <React.Fragment key={`amt_${amtIndex}`}>
              <Divider />
              <div>
                <TextField variant="outlined" size="small" label="Amount Line" name="amountLine" value={amt.amountLine} InputProps={{ readOnly: true } || ''} />
                <TextField variant="outlined" onChange={(event) => handleAmtChange(event, amt, amtIndex)} size="small" label="Repair Code" name="repairCode" value={amt.repairCode || ''} />
                <TextField variant="outlined" type="number" onChange={(event) => handleAmtChange(event, amt, amtIndex)} size="small" label="Labor Amount" name="laborAmount" value={amt.laborAmount || ''} />
                <TextField variant="outlined" type="number" onChange={(event) => handleAmtChange(event, amt, amtIndex)} size="small" label="Parts Amount" name="partsAmount" value={amt.partsAmount || ''} />
                <TextField variant="outlined" type="number" onChange={(event) => handleAmtChange(event, amt, amtIndex)} size="small" label="Shipping Amount" name="shipAmount" value={amt.shipAmount || ''} />
                <TextField variant="outlined" type="number" onChange={(event) => handleAmtChange(event, amt, amtIndex)} size="small" label="Tax Amount" name="taxAmount" value={amt.taxAmount || ''} />
                <TextField variant="outlined" type="number" onChange={(event) => handleAmtChange(event, amt, amtIndex)} size="small" label="QST Amount" name="qstAmount" value={amt.qstAmount || ''} />
                <TextField variant="outlined" type="number" onChange={(event) => handleAmtChange(event, amt, amtIndex)} size="small" label="GST Amount" name="gstAmount" value={amt.gstAmount || ''} />
              </div>
            </React.Fragment>
          )}
        </div>
        : null} */}

      <br />
      <Divider />
      <br />

      {
        submitted ?
          <div className="field is-grouped">
            <div className="control">
              <button className={`button is-info`} disabled>Submitted</button>
            </div>
            <div className="control">
              <button className={`button is-danger`} onClick={() => { props.onSubmit() }}>Close</button>
            </div>
          </div> :

          <div className="field is-grouped">
            <div className="control">
              <button className={`button is-info ${loading ? 'is-loading' : ''}`} onClick={onSubmit} >Submit</button>
            </div>
            <div className="control">
              {loading ?
                <button className={`button is-danger`} disabled>Cancel</button> :
                <button className={`button is-danger`} onClick={() => { props.onSubmit() }}>Cancel</button>
              }
            </div>
          </div>
      }

    </div>
  );
};
