import React from 'react';
import StepLoading from './StepLoading';
import { processEntitlementCheck } from './entitlementService';
import ProcessedEntitlementDisplay from './ProcessedEntitlementDisplay';
import { connect } from 'react-redux';

function EntitlementCheckProcessComponent({ data = [], checkState, callback }) {
  const requestArr = data.claimHeaderList;
  const [processed, setProcessed] = React.useState(undefined);

  React.useEffect(() => {
    processEntitlementCheck(requestArr).then(result => {
      setProcessed(result);
    }).catch(console.error);
  }, [data, requestArr, callback]);

  if (processed) {
    return <ProcessedEntitlementDisplay data={processed} />
  }

  if (checkState.running) {
    return <StepLoading message={`Running entitlement checks...`} />;
  }
}

const mapStateToProps = (state) => ({
  data: state.entitlement.parsed,
  checkState: state.entitlement.checkState
});


const EntitlementCheckProcess = connect(mapStateToProps)(EntitlementCheckProcessComponent);
export default EntitlementCheckProcess;
