import React from 'react';
import InfoMessage from '../InfoMessage';
import userManager from '../../services/userManager';
import { ClientSelectorFormComponent as ClientSelector } from '../client/ClientSelector';
import { ServicerSelector } from '../servicer/ServicerSelector'
import { SELECT_FILE, reset } from './uploaderActions';
import { routeService } from '../routing/routeService';

import { connect } from 'react-redux';

const XLSX_MIMETYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const XLS_MIMETYPE = 'application/vnd.ms-excel';
const VALID_FILETYPES = [XLSX_MIMETYPE, XLS_MIMETYPE];

function BatchFileSelectorComponent({ dispatch }) {

  const onFileSelect = (file, clientId, scNumber) => {
    // console.log('params onchange');
    dispatch(reset());
    
    const checkFileAndClientScNumber = () => file && (clientId && clientId !== '-1') && (scNumber && scNumber !== '-1');
    const checkFileAndClient = () => file && (clientId && clientId !== '-1');

    const checkCondition = routeService.hasControl('sameClientUpload') ? checkFileAndClient : checkFileAndClientScNumber;
    // console.log('checkCondition: ', checkCondition)

    // if (file && (clientId && clientId !== '-1') && (scNumber && scNumber !== '-1')) {
    // if (file && (clientId && clientId !== '-1')) {
    if (checkCondition()) {
      dispatch({ type: SELECT_FILE, file, clientId, scNumber });
    }
  }

  return (
    <div className="section">
      <Form onFileSelect={onFileSelect} />
    </div>
  );
}

class Form extends React.Component {

  state = {
    clientId: '',
    scNumber: '',
    fileName: '',
    file: undefined,
    hasInvalidFile: false
  }

  onClientChange = (event) => {
    const clientId = event.target.value;

    if (!clientId) {
      return;
    }

    this.setState({
      clientId,
      scNumber: '-1'
    }, () => {
      this.props.onFileSelect(this.state.file, this.state.clientId, this.state.scNumber);
    });
  }

  handleChange = (event) => {
    const target = event.target;
    const { name, value } = target;
    const state = this.state;
    state[name] = value;

    this.setState(state, () => {
      this.props.onFileSelect(this.state.file, this.state.clientId, this.state.scNumber);
    });
  }

  componentDidMount() {
    const user = userManager.getUser();
    const { clientId, scNumber } = user;
    this.setState({
      clientId, scNumber
    })
  }

  onFileUpload = (event) => {
    const files = event.target.files;
    const file = Array.from(files)[0];

    if (!file) {
      return;
    }

    // console.log('file type', file.type);
    if (!VALID_FILETYPES.includes(file.type)) {
      this.setState({
        file: undefined,
        fileName: undefined,
        hasInvalidFile: true
      })
      return;

    } else {
      if (!file) {
        return;
      }

      this.setState({
        file,
        fileName: file.name,
        hasInvalidFile: false
      }, () => {
        this.scrollToBottom();
        this.props.onFileSelect(file, this.state.clientId, this.state.scNumber);
      });
    }
  }

  scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  }

  render() {
    const { clientId, scNumber, fileName, hasInvalidFile } = this.state;

    return (
      <div className="form">
        <div className="hero" >

          <div className="columns">
            <div className="column is-4 is-offset-1">

              <div className="box">

                <ClientSelector name="clientId" value={clientId} onChange={this.onClientChange} required={true} />

                {
                  !routeService.hasControl('sameClientUpload') ?
                    <ServicerSelector name="scNumber" clientId={clientId} value={scNumber} onChange={this.handleChange} required={true} />
                    : null
                }

                <div className="field">
                  <label className="label">Batch File</label>
                  <div className="file has-name is-fullwidth" style={{ fontSize: "smaller" }}>
                    <label className="file-label">
                      <input className="file-input" type="file" name="batchFile" onChange={this.onFileUpload} />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">
                          Choose a file…
                        </span>
                      </span>
                      <span className="file-name">
                        {fileName}
                      </span>
                    </label>
                  </div>
                  {hasInvalidFile ? <p className="help is-danger" style={{ fontSize: "0.80rem" }}>You have selected an invalid File. Please select XLSX or XLS files only</p> : null}
                </div>

              </div>
            </div>

            <div className="column is-offset-1" >
              <InfoMessage title="Upload File Format" messageComponent={UploadFileFormatInfo} />

            </div>
          </div>
        </div>
      </div>
    );
  }
}

function UploadFileFormatInfo() {
  return (
    <ul>
      <li>This page is only for first time submissions of batched claims</li>
      <li>The file will contain 37 pre-defined columns</li>
      <li>Please click the submit button only once</li>
      <li>Depending on the size of the file, your submission may take a few moments</li>
      <li>Claims that do not meet the format validation will need to be corrected and submitted again through this page</li>
      <li>Once your file is submitted, you will receive a confirmation page listing
      <ul>
          <li>Total number of records processed</li>
          <li>Total number of records processed</li>
          <li>Total number of incorrectly formatted records</li>
        </ul>
      </li>
      <li>
        You will also receive a Rejection Summary with
        <ul>
          <li>Row Number</li>
          <li>Column Number</li>
          <li>Error Message</li>
        </ul>
      </li>
      <li>
        <p>
          You can use the Rejection Summary to identify the data that needs to be corrected.
          Upon correcting the format errors, use this page to submit the corrected records.
          Records that do not have any format errors will be processed further.
        </p>
      </li>
      <li>
        <p>The first 10 rows of the file are reserved for user notes.</p>
      </li>
      <li>
        <p>The header is at row 11 and data starts at row 12</p>
      </li>
      <li>
        <a className="link" href="/files/sample_batchfile.xlsx" download>Sample File Format</a>
      </li>
    </ul>
  );
}

const BatchFileSelector = connect()(BatchFileSelectorComponent);
export default BatchFileSelector;