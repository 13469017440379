import React from 'react';
import { getClaimsFiltered } from './manualPaymentService';

export default function useClaims(filters, activePage = 0, totalCountPerPage = 10) {
  const [claims, setClaims] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(0);

  React.useEffect(() => {
    if (!filters) {
      return;
    }

    setLoading(true);

    let max = totalCountPerPage * activePage;
    let offset = max - totalCountPerPage;

    const params = {
      ...filters,
      offset,
      max
    }

    // console.log('filters: ', params)

    getClaimsFiltered(params)
      .then(result => {
        setLoading(false);
        const newClaims = JSON.parse(result);
        if (Array.isArray(newClaims) && newClaims.length > 0) {
          setTotalCount(newClaims[0].RESULT_COUNT);
        } else {
          setTotalCount(0);
        }

        setClaims(newClaims);
      })
      .catch(error => {
        setLoading(false);
        console.error(error);
      });
  }, [filters, activePage, totalCountPerPage]);

  const onClear = () => {
    setClaims([]);
    setTotalCount(0);
  }

  return {
    claims,
    setClaims,
    loading,
    onClear,
    totalCount
  }
};
