import React from "react";

function ClaimHeaderFileTableHeader() {
	return (
		<thead>
			<tr className="is-size-6">
				<th>Filename</th>
				<th>Client ID</th>
				<th>SC Number</th>
				<th>Batch ID</th>
				<th>Process Type</th>
				<th>Total Claims</th>
				<th>Rejected</th>
				<th>Status</th>
				<th>Creation Date</th>
				{/* <th>Action</th> */}
			</tr>
		</thead>
	)
}

export default ClaimHeaderFileTableHeader;