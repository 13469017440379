import React from 'react';
import Pagination from "react-js-pagination";

export function usePagination(totalCount = 0) {
  const [activePage, setActivePage] = React.useState(1); 
  const [totalItemsCount, setTotalItemsCount] = React.useState(totalCount);

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber);
    setActivePage(pageNumber);
  }

  return {
    activePage,
    setActivePage,
    onChange: handlePageChange,
    totalItemsCount,
    setTotalItemsCount
  }
}

export default function ClaimSearchTablePagination(props) {
  const { itemsCountPerPage = 10, pageRangeDisplayed = 5, state, ...rest } = props;

  return (
    <nav id="pagination-nav"
		     className="pagination is-centered"
		     role="navigation"
		     aria-label="pagination"
         style={{ paddingTop: "0.75rem" }}>
			<Pagination
        {...rest}
				itemsCountPerPage={itemsCountPerPage}
				pageRangeDisplayed={pageRangeDisplayed}
				activeLinkClass={"is-current"}
				linkClass={"pagination-link"}
				innerClass={"pagination-list"}
			/>
		</nav>
  );
}