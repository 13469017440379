import React from 'react';
import { ClientSelectorFormComponent as ClientSelector } from '../client/ClientSelector';
import CustomDatePicker from '../common/CustomDatePicker';
import userManager from '../../services/userManager';
import { SELECT_FILE, reset } from './uploaderActions';

import { connect } from 'react-redux';

const CSV_MIMETYPE = 'text/csv';
const TXT_MIMETYPE = 'text/plain';
const VALID_FILETYPES = [CSV_MIMETYPE, TXT_MIMETYPE];

function CorrectionFileSelectorComponent({ dispatch }) {

  const onFileSelect = (file, clientId, claimStatus, sentDate) => {
    dispatch(reset());
    if (file && (clientId && clientId !== '-1') && claimStatus && sentDate) {
      dispatch({ type: SELECT_FILE, file, clientId, claimStatus, sentDate });
    }
  }

  return (
    <div className="section">
      <Form onFileSelect={onFileSelect} />
    </div>
  );
}

class Form extends React.Component {

  state = {
    clientId: '',
    fileName: '',
    file: undefined,
    claimStatus: '',
    sentDate: '',
    hasInvalidFile: false
  }

  onClientChange = (event) => {
    const clientId = event.target.value;

    if (!clientId) {
      return;
    }

    this.setState({
      clientId
    }, () => {
      this.props.onFileSelect(this.state.file, this.state.clientId, this.state.claimStatus, this.state.sentDate);
    });
  }

  handleChange = (event) => {
    const target = event.target;
    const { name, value } = target;
    const state = { ...this.state };
    state[name] = value;

    this.setState(state, () => {
      this.props.onFileSelect(this.state.file, this.state.clientId, this.state.claimStatus, this.state.sentDate);
    });
  }

  componentDidMount() {
    const user = userManager.getUser();
    const { clientId } = user;
    this.setState({
      clientId
    })
  }

  onFileUpload = (event) => {
    const files = event.target.files;
    const file = Array.from(files)[0];

    if (!file) {
      return;
    }

    console.log('file types', file.type);

    if (!VALID_FILETYPES.includes(file.type)) {
      this.setState({
        file: undefined,
        fileName: undefined,
        hasInvalidFile: true
      })
      return;

    } else {
      
      this.setState({
        file,
        fileName: file.name,
        hasInvalidFile: false
      }, () => {
        this.props.onFileSelect(file, this.state.clientId, this.state.claimStatus, this.state.sentDate);
        this.scrollToBottom();
      });
    }
  }

  scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  }

  onSentDateChange = () => {
    this.props.onFileSelect(this.state.file, this.state.clientId, this.state.claimStatus, this.state.sentDate);
  }

  render() {
    const { clientId, fileName, claimStatus } = this.state;

    return (
      <div className="form">
        <div className="hero" style={{ padding: "1em" }}>

          <div className="columns">
            <div className="column is-4 is-offset-4">

              <div className="box">

                <ClientSelector value={clientId} name="clientId" onChange={this.onClientChange} required={true} />

                <div className="field">
                  <label className="label">Claim Status</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select name="claimStatus" value={claimStatus} onChange={this.handleChange} >
                        <option value="">Select Claim Status</option>
                        <option value="INVALID_CONTRACT">INVALID_CONTRACT</option>
                        <option value="SALE_NOT_FOUND">SALE_NOT_FOUND</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Sent Date</label>
                  <div className="control">
                    <CustomDatePicker request={this.state} field="sentDate" label={null} style={{ marginTop: "unset" }} size="small" callback={this.onSentDateChange} />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Correction File</label>
                  <div className="file has-name is-fullwidth" style={{ fontSize: "smaller" }}>
                    <label className="file-label">
                      <input className="file-input" type="file" name="batchFile" onChange={this.onFileUpload} />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">
                          Choose a file…
                        </span>
                      </span>
                      <span className="file-name">
                        {fileName}
                      </span>
                    </label>
                  </div>
                </div>

                { this.state.hasInvalidFile ?
                <div className="field">
                  <p className="has-text-danger is-size-6">Please select .txt or .csv files only</p>
                </div>
                : null }

              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const BatchFileSelector = connect()(CorrectionFileSelectorComponent);
export default BatchFileSelector;