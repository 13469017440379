import {
	SEARCH_CLAIM_HEADER_FILE,
	CLEAR_CLAIM_HEADER_FILE
} from './portalSubmissionAction';

const initialState = {
	claimHeaderList: [],
	payload: {},
	totalCount: 0,
	activePage: 1,
	claimHeaderFile: {}
}

function reducerFn(prevState = initialState, action) {
	switch (action.type) {
		case SEARCH_CLAIM_HEADER_FILE:
			return {
				...prevState,
				claimHeaderList: action.data.data,
				totalCount: action.data.totalCount,
				payload: action.payload,
				activePage: action.activePage
			}
		case CLEAR_CLAIM_HEADER_FILE:
			return {
				...prevState,
				claimHeaderList: [],
				payload: {},
				totalCount: 0,
				activePage: 1,
			}
		default:
			return prevState;
	}
}

export default reducerFn;
