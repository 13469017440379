import React from 'react';

const Loading = ({ fullHeight = true, active = true }) => {
  if (!active) {
    return null;
  }

  return (
    <div className={`hero ${fullHeight ? 'is-fullheight' : ''}`}>
      <div className="hero-body">
        <div className="container has-text-centered">
          <span className="icon">
            <i className="fa fa-spinner fa-spin fa-3x"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Loading;
