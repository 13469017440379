import caller from '../../services/caller';
import config from '../../config/config.json';

const DEFAULT_MAX = 10;

export async function queryClaimsSummary(clientId, scNumber, assignedGroup) {
  try {
    const url = `${config.api.queryClaimsSummary}/?clientID=${clientId}&assignedGroup=${assignedGroup}&sc_number=${scNumber}`;
    const summary = await caller.get(url);
    return summary;

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function queryClaimInfoByStatusAndGroup(status, clientId, scNumber, assignedGroup, offset = 0, max = DEFAULT_MAX) {
  try {
    const url = `${config.api.queryClaimInfoByStatusAndGroup}/?status=${status}&clientID=${clientId}&assignedGroup=${assignedGroup}&sc_number=${scNumber}&offset=${offset}&max=${max}`;
    const claimInfo = await caller.get(url);
    return claimInfo;

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function queryClaimSearch(repairTicketNumber, clientId, scNumber, assignedGroup, offset = 0, max = DEFAULT_MAX) {
  try {
    const url = `${config.api.queryClaimSearch}/?repairTicketNumber=${repairTicketNumber}&clientID=${clientId}&assignedGroup=${assignedGroup}&sc_number=${scNumber}&offset=${offset}&max=${max}`;
    const claimInfo = await caller.get(url);
    return claimInfo;

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getClaimErrorsByClaimId(claimId) {
  try {
    const url = `${config.api.claimErrors}?claimId=${claimId}`
    const claimErrors = await caller.get(url);
    return JSON.parse(claimErrors);

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getClaimById(claimId) {
  try {
    const url = `${config.api.claim}/getById?claimId=${claimId}`;
    const resultsStr = await caller.get(url);
    const results = JSON.parse(resultsStr);
    return results[0];

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getAmountLinesByClaimId(claimId) {
  try {
    const url = `${config.api.amountLines}?claimId=${claimId}`;
    const amountLines = await caller.get(url);
    return JSON.parse(amountLines);

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function processResubmit(claimId, userName, header, amountLines) {
  try {
    const result = await caller.post(config.api.processResubmit, { claimId, userName, header, amountLines });
    return result;

  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function processResubmitNsfUpdate(claimId, userName, claimHeaders) {
  try {
    const result = await caller.post(config.api.processResubmitNsfUpdate, { claimId, userName, claimHeaders });
    return result;

  } catch (error) {
    console.error(error);
    return null;
  }
}


export async function processResubmitNsfAccept(claimId, userName) {
  try {
    const result = await caller.post(config.api.processResubmitNsfAccept, { claimId, userName });
    return result;

  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function processCancelDeny(claimID, userName, process, actionComments) {

  try {
    const params = {
      claimID,
      userName,
      process,
      actionComments
    };
    const result = await caller.post(config.api.processCancelDeny, params);
    return result;

  } catch (error) {
    console.error(error);
    return false;
  }

}

export async function processOverride(claimId, userName, status, actionComments) {

  try {
    const params = {
      claimID: claimId,
      userName,
      status,
      actionComments
    };
    await caller.post(config.api.processOverride, params);
  } catch (error) {
    console.error(error);
    return [];
  }

  return "Ok";
}

export async function markAsInvalidContract(claimId, userName, comments) {
  try {
    const params = {
      claimId, userName, comments
    }
    const url = config.api.invalidContract;
    const result = await caller.post(url, params);
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
}