import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  topbottomspacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backButton: {
    marginRight: theme.spacing(1),
  }
}));

export function useSteps(maxStep = 4) {
  const [step, setStep] = React.useState(1);
  const enableBack = useButtonEnabler(() => (step > 1 && step !== maxStep));
  const enableNext = useButtonEnabler(() => step < maxStep, false);

  const next = () => {
    if (step <= maxStep)
      setStep(step + 1);
  };

  const back = () => {
    if (step > 1)
      setStep(step - 1);
  };

  return {
    step,
    maxStep,
    next,
    back,
    enableBack,
    enableNext
  };
}

function useButtonEnabler(condition, init = true) {
  const [enable, setEnable] = React.useState(init);

  React.useEffect(() => {
    setEnable(condition());
  }, [condition]);

  return enable;
}

export default function StepButtons({ stepState }) {
  const classes = useStyles();
  const { next, back, enableBack, enableNext } = stepState;

  return (
    <div className={classes.topbottomspacing}>
      <Button variant="contained" disabled={!enableNext} color="primary" onClick={next}>Next</Button>
      <Button color="primary" disabled={!enableBack} className={classes.backButton} onClick={back}>Back</Button>
    </div>
  );
}