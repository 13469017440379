import { get } from '../../services/caller';
import config from '../../config/config.json';

export async function listReports(clientId) {
  try {
    const url = `${config.api.listReports}/?clientId=${clientId}`;
    const result = await get(url);
    return JSON.parse(result);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function downloadReports(clientId, scNumber, fileName) {
  try {
    const url = `${config.api.signedReport}/?clientId=${clientId}&scNumber=${scNumber}&fileName=${encodeURIComponent(fileName)}`;
    const signedUrl = await get(url);
    window.location.href = JSON.parse(signedUrl);
    return signedUrl;

  } catch (error) {
    console.error(error);
    throw error;
  }
}