import caller from './caller';
import config from './config.json';
import userManager from './userManager';

export async function getClients() {
  try {
    const clients = await caller.get(config.url.clientProfile);
    return JSON.parse(clients);

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getClientById(clientId) {
  try {
    const url = `${config.url.clientProfile}/${clientId}`
    const clients = await caller.get(url);
    const parsed = JSON.parse(clients);
    return parsed[0];

  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getClaimsFiltered(filters) {

  // const { fromDate, toDate, status, srNumber, clientNumber, transactionNumber, phoneNumber, authNumber, batchId, repairTicketNumber } = filters;
  const filtersBase64 = Buffer.from(JSON.stringify(filters)).toString('base64');
  let url = `${config.url.claimSearch}?filters=${filtersBase64}`

  try {
    const clients = await caller.get(url);
    return clients;

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getClaimById(claimId) {
  try {
    const url = `${config.url.claim}/getById?claimId=${claimId}`;
    const claim = await caller.get(url);
    return claim;

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getAmountLinesByClaimId(claimId) {
  try {
    const url = `${config.url.amountLines}?claimId=${claimId}`;
    const amountLines = await caller.get(url);
    return amountLines;

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getClientServicerDetails(clientId) {
  try {
    const url = `${config.url.servicerDetails}?clientId=${clientId}`;
    const servicerDetails = await caller.get(url);
    return JSON.parse(servicerDetails);

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function updateClient(client) {
  try {
    const result = await caller.put(config.url.clientProfile, client);
    return result;

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function uploadClaims(claims) {

  for (const claim of claims) {
    try {
      console.log(`Uploading: ${JSON.stringify(claim)}`);
      await caller.post(config.url.claimRegister, claim);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  return "Ok";
}

export async function queryClaimsSummary(clientId, scNumber, assignedGroup) {
  try {
    const url = `${config.url.queryClaimsSummary}/?clientID=${clientId}&assignedGroup=${assignedGroup}&sc_number=${scNumber}`;
    const summary = await caller.get(url);
    return summary;

  } catch (error) {
    console.error(error);
    return [];
  }
}


export async function queryClaimInfoByStatusAndGroup(status, clientId, scNumber, assignedGroup) {
  try {
    const url = `${config.url.queryClaimInfoByStatusAndGroup}/?status=${status}&clientID=${clientId}&assignedGroup=${assignedGroup}&sc_number=${scNumber}`;
    const claimInfo = await caller.get(url);
    return claimInfo;

  } catch (error) {
    console.error(error);
    return [];
  }
}


export async function queryClaimSearch(repairTicketNumber, clientId, scNumber, assignedGroup) {
  try {
    const url = `${config.url.queryClaimSearch}/?repairTicketNumber=${repairTicketNumber}&clientID=${clientId}&assignedGroup=${assignedGroup}&sc_number=${scNumber}`;
    const claimInfo = await caller.get(url);
    return claimInfo;

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function processCancelDeny(claimID, userName, process, actionComments) {

  try {
    const params = {
      claimID,
      userName,
      process,
      actionComments
    };
    const result = await caller.post(config.url.processCancelDeny, params);
    return result;

  } catch (error) {
    console.error(error);
    return false;
  }

}

export async function processOverride(claimId, userName, status, actionComments) {

  try {
    const params = {
      claimID: claimId,
      userName,
      status,
      actionComments
    };
    await caller.post(config.url.processOverride, params);
  } catch (error) {
    console.error(error);
    return [];
  }

  return "Ok";
}

export async function getClaimErrorsByClaimId(claimId) {
  try {
    const url = `${config.url.claimErrors}?claimId=${claimId}`
    const claimErrors = await caller.get(url);
    return JSON.parse(claimErrors);

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function updateClaimHeader(claimId, claimHeader) {
  try {
    const url = `${config.url.claim}/${claimId}`;
    const result = await caller.post(url, claimHeader);
    return result;

  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function processResubmit(claimId, userName, header, amountLines) {
  try {
    const result = await caller.post(config.url.processResubmit, { claimId, userName, header, amountLines });
    return result;

  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function processResubmitNsfUpdate(claimId, userName, claimHeaders) {
  try {
    const result = await caller.post(config.url.processResubmitNsfUpdate, { claimId, userName, claimHeaders });
    return result;

  } catch (error) {
    console.error(error);
    return null;
  }
}


export async function processResubmitNsfAccept(claimId, userName) {
  try {
    const result = await caller.post(config.url.processResubmitNsfAccept, { claimId, userName });
    return result;

  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function login(username, password) {
  try {
    console.log(`Logging in using ${username} ${password}`);
    const user = await caller.post(`${window.location.origin}/auth`, { username, password });
    console.log(`returned user: ${JSON.stringify(user)}`);
    if (user) {
      userManager.setUser(user);
    }
    return user;

  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getClientsByScNumber(scNumber) {
  try {
    const url = `${config.url.servicerDetails}/clients/?scNumber=${scNumber}`
    const clients = await caller.get(url);
    return JSON.parse(clients);

  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getClientServicerBySCNumber(scNumber) {
  try {
    const url = `${config.url.servicerDetails}?scNumber=${scNumber}`;
    const servicerDetails = await caller.get(url);
    const parsed = JSON.parse(servicerDetails);
    return parsed[0];

  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function markAsInvalidContract(claimId, userName, comments) {
  try {
    const params = {
      claimId, userName, comments
    }
    const url = config.url.invalidContract;
    const result = await caller.post(url, params);
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
}

const commonFns = {
  getClients,
  getClaimsFiltered,
  getClaimById,
  getAmountLinesByClaimId,
  getClientServicerDetails,
  updateClient,
  uploadClaims,
  queryClaimsSummary,
  queryClaimInfoByStatusAndGroup,
  queryClaimSearch,
  processCancelDeny,
  processOverride,
  getClaimErrorsByClaimId,
  updateClaimHeader,
  processResubmit,
  processResubmitNsfAccept,
  processResubmitNsfUpdate,
  login,
  getClientsByScNumber,
  getClientById,
  getClientServicerBySCNumber,
  markAsInvalidContract
};

export default commonFns;
