import React from 'react';
import userManager from '../../services/userManager';
import { SEVERITY, useSnackbar } from '../common/CustomSnackbar';

export default function useFilters() {
  const [filters, setFilters] = React.useState(undefined);
  const snackbar = useSnackbar();
  const user = userManager.getUser();

  const onFilterChange = (filters) => {
    const { clientNumber, scNumber } = filters;

    if (user.isClient() && !scNumber) {
      snackbar.handleOpen(SEVERITY.error, 'Servicer is required');

    } else if (!user.isClient() && !clientNumber) {
      snackbar.handleOpen(SEVERITY.error, 'Client is required');

    } else {
      setFilters({
        ...filters
      });
    }
  }

  return {
    filters,
    onFilterChange,
    snackbar
  }
};
