import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CustomDatePicker from '../common/CustomDatePicker';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
    '&': {
      padding: 20
    }
  },
  heading: {
    paddingBottom: 5,
    paddingTop: 10,
    fontWeight: "bold"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();
  // console.log('Edit Modal');
  const [row, setRow] = React.useState({ ...props.data.row });

  const handleApply = () => {
    props.onApply(props.data.index, row);
    props.onClose();
  }

  const handleChange = (event) => {
    let { name, value } = event.target;
    value = value ? value : undefined;
    const updated = { ...row };
    updated[name] = value;
    setRow(updated);
  }

  // const computeTotal = (claimsLines = []) => {
  //   let laborSum = 0;
  //   let taxSum = 0;
  //   let shipSum = 0;
  //   let partsSum = 0;

  //   claimsLines.forEach(amt => {
  //     laborSum += amt.laborAmt || 0;
  //     taxSum += amt.taxAmt || 0;
  //     shipSum += amt.shipAmt || 0;
  //     partsSum += amt.partsAmt || 0;
  //   })

  //   return {
  //     laborSum,
  //     taxSum,
  //     shipSum,
  //     partsSum,
  //     total: laborSum + taxSum + shipSum + partsSum
  //   }
  // }

  // const handleAmtChange = (event, amt, index) => {
  //   let { name, value } = event.target;
  //   value = value ? value : undefined;
  //   const updated = { ...amt };
  //   updated[name] = parseFloat(value);

  //   const updatedRow = { ...row };
  //   updatedRow.claimsLines[index] = updated;

  //   const total = computeTotal(updatedRow.claimsLines);
  //   updatedRow.TotalCost = total.total;
  //   updatedRow.LaborCost = total.laborSum;
  //   updatedRow.PartsCost = total.partsSum;
  //   updatedRow.ShippingHandlingCost = total.shipSum;
  //   updatedRow.TaxCost = total.taxSum;

  //   setRow(updatedRow);
  // }

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Edit Entitlement Details
            </Typography>
            <Button autoFocus color="inherit" onClick={handleApply}>
              Apply
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <p className={classes.heading}>Claim Header</p>
          <Divider />
          <div>
            <TextField variant="outlined" onChange={handleChange} size="small" label="Client Number" name="clientNumber" value={row.clientNumber || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="Sr Number" name="srNumber" value={row.srNumber || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="Repair Envelope Number" name="repairEnvelopeNumber" value={row.repairEnvelopeNumber || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="Store Purchase Number" name="storePurchaseNumber" value={row.storePurchaseNumber || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="Terminal Number" name="terminalNumber" value={row.terminalNumber || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="Transaction Number" name="transactionNumber" value={row.transactionNumber || ''} />
            
            <CustomDatePicker onChange={handleChange} size="small" label="Purchase Date" name="purchaseDate" field="purchaseDate" request={row} value={row.purchaseDate || ''} />
            <CustomDatePicker onChange={handleChange} size="small" label="Failure Date" name="failureDate" field="failureDate" request={row} value={row.failureDate || ''} />
            <CustomDatePicker onChange={handleChange} size="small" label="SC Receive Date" name="scReceiveDate" field="scReceiveDate" request={row} value={row.scReceiveDate || ''} />
          </div>

        </div>
      </Dialog>
    </div>
  );
}
