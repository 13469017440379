import React from 'react';
// import { Link } from 'react-router-dom';
import ManualPaymentActionModal, { useFullScreenModal } from './ManualPaymentActionModal';

export default function ManualPaymentTable({ claims = [], totalCount = 0 }) {

  const modalState = useFullScreenModal();

  const onClaimIdClick = (claimId) => {
    modalState.setParams({
      claimId
    });
    modalState.show();
  };

  return (
    <div className="" style={{ fontSize: "0.85em" }}>
      <ManualPaymentActionModal state={modalState}/>
      <p className="subtitle">Total No. of Records: {totalCount}</p>
      <table className="table is-striped is-hoverable is-fullwidth is-bordered">
        <thead>
          <tr>
            <th>Claim ID</th>
            <th>Client ID</th>
            <th>Service Request#</th>
            <th>Repair Envelope#</th>
            <th>Transaction#</th>
            <th>Customer Name</th>
            <th>Submit Date</th>
            <th>Batch Number</th>
            <th>Claim Status</th>
            <th>Amount Approved</th>
          </tr>
        </thead>
        <tbody>
          {
            claims.map((cl, index) =>
              <tr key={index}>
                <td><button className="button is-text is-inverted has-text-info" onClick={() => onClaimIdClick(cl.CLAIM_ID)}>{cl.CLAIM_ID}</button></td>
                <td>{cl.CLIENT_ID}</td>
                <td>{cl.SR_NUMBER}</td>
                <td>{cl.REPAIR_TICKET_NUMBER}</td>
                <td>{cl.TRANSACTION_NUMBER}</td>
                <td>{cl.CUSTOMER}</td>
                <td>{cl.SUBMIT_DATE}</td>
                <td>{cl.BATCH_ID}</td>
                <td>{cl.STATUS}</td>
                <td>{cl.APPROVED}</td>
              </tr>)
          }
        </tbody>

      </table>
    </div>
  );
}
