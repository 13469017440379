import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import CustomDatePicker from '../common/CustomDatePicker';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
    '&': {
      padding: 20
    }
  },
  heading: {
    paddingBottom: 5,
    paddingTop: 10,
    fontWeight: "bold"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();
  // console.log('Edit Modal');
  const [row, setRow] = React.useState({ ...props.data.row });

  const handleApply = () => {
    props.onApply(props.data.index, row);
    props.onClose();
  }

  const handleChange = (event) => {
    let { name, value } = event.target;
    value = value ? value : undefined;
    const updated = { ...row };
    updated[name] = value;
    setRow(updated);
  }

  const computeTotal = (claimsLines = []) => {
    let laborSum = 0;
    let taxSum = 0;
    let shipSum = 0;
    let partsSum = 0;

    claimsLines.forEach(amt => {
      laborSum += amt.laborAmt || 0;
      taxSum += amt.taxAmt || 0;
      shipSum += amt.shipAmt || 0;
      partsSum += amt.partsAmt || 0;
    })

    return {
      laborSum,
      taxSum,
      shipSum,
      partsSum,
      total: laborSum + taxSum + shipSum + partsSum
    }
  }

  const handleAmtChange = (event, amt, index) => {
    let { name, value } = event.target;
    value = value ? value : undefined;
    const updated = { ...amt };
    updated[name] = parseFloat(value);

    const updatedRow = { ...row };
    updatedRow.claimsLines[index] = updated;

    const total = computeTotal(updatedRow.claimsLines);
    updatedRow.TotalCost = total.total;
    updatedRow.LaborCost = total.laborSum;
    updatedRow.PartsCost = total.partsSum;
    updatedRow.ShippingHandlingCost = total.shipSum;
    updatedRow.TaxCost = total.taxSum;

    setRow(updatedRow);
  }

  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Edit Claim Details
            </Typography>
            <Button autoFocus color="inherit" onClick={handleApply}>
              Apply
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <p className={classes.heading}>Claim Header</p>
          <Divider />
          <div>
            <TextField variant="outlined" onChange={handleChange} size="small" label="BatchId" name="BatchId" value={row.BatchId || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="DocId" name="DocId" value={row.DocId || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="ClientNo" name="ClientNo" value={row.ClientNo || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="ServiceCenterNo" name="ServiceCenterNo" value={row.ServiceCenterNo || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="ServiceRequestNo" name="ServiceRequestNo" value={row.ServiceRequestNo || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="RepairEnvelopeNo" name="RepairEnvelopeNo" value={row.RepairEnvelopeNo || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="StoreNoSending" name="StoreNoSending" value={row.StoreNoSending || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="StoreNoPurchase" name="StoreNoPurchase" value={row.StoreNoPurchase || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="Register" name="Register" value={row.Register || ''} />
            
            <CustomDatePicker onChange={handleChange} size="small" label="PurchaseDate" name="PurchaseDate" field="PurchaseDate" request={row} value={row.PurchaseDate || ''} />
            
            <TextField variant="outlined" onChange={handleChange} size="small" label="TransactionNo" name="TransactionNo" value={row.TransactionNo || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="CustFirstName" name="CustFirstName" value={row.CustFirstName || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="CustLastName" name="CustLastName" value={row.CustLastName || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="StreetAddress" name="StreetAddress" value={row.StreetAddress || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="StreetAddress2" name="StreetAddress2" value={row.StreetAddress2 || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="StreetAddress3" name="StreetAddress3" value={row.StreetAddress3 || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="City" name="City" value={row.City || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="State" name="State" value={row.State || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="ZipCode" name="ZipCode" value={row.ZipCode || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="Country" name="Country" value={row.Country || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="AreaCode" name="AreaCode" value={row.AreaCode || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="PhoneNo" name="PhoneNo" value={row.PhoneNo || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="ProductDesc" name="ProductDesc" value={row.ProductDesc || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="ProductSKU" name="ProductSKU" value={row.ProductSKU || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="Brand" name="Brand" value={row.Brand || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="PurchasePrice" name="PurchasePrice" value={row.PurchasePrice || ''} />
            
            <CustomDatePicker request={row} variant="outlined" onChange={handleChange} size="small" field="FailureDate" name="FailureDate" value={row.FailureDate || ''} />
            <CustomDatePicker request={row} variant="outlined" onChange={handleChange} size="small" field="ServiceCenterReceivedDate" name="ServiceCenterReceivedDate" value={row.ServiceCenterReceivedDate || ''} />
            <CustomDatePicker request={row} variant="outlined" onChange={handleChange} size="small" field="ServiceCenterShipDate" name="ServiceCenterShipDate" value={row.ServiceCenterShipDate || ''} />
            
            <TextField variant="outlined" onChange={handleChange} size="small" label="WorkToBeDone" name="WorkToBeDone" value={row.WorkToBeDone || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="AuthorizationNo" name="AuthorizationNo" value={row.AuthorizationNo || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="FailureReasonCode" name="FailureReasonCode" value={row.FailureReasonCode || ''} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="TotalCost" name="TotalCost" value={row.TotalCost || ''} InputProps={{
              readOnly: true,
            }} />
            <TextField variant="outlined" onChange={handleChange} size="small" label="Notes" name="Notes" value={row.Notes || ''} />
          </div>


          <p className={classes.heading}>Claim Amounts</p>
          {row.claimsLines.map((amt, amtIndex) =>
            <React.Fragment key={`amt_${amtIndex}`}>
              <Divider />
              <div>
                <TextField variant="outlined" onChange={(event) => handleAmtChange(event, amt, amtIndex)} size="small" label="Repair Code" name="repairCode" value={amt.repairCode || ''} />
                <TextField variant="outlined" type="number" onChange={(event) => handleAmtChange(event, amt, amtIndex)} size="small" label="Labor Amount" name="laborAmt" value={amt.laborAmt || ''} />
                <TextField variant="outlined" type="number" onChange={(event) => handleAmtChange(event, amt, amtIndex)} size="small" label="Parts Amount" name="partsAmt" value={amt.partsAmt || ''} />
                <TextField variant="outlined" type="number" onChange={(event) => handleAmtChange(event, amt, amtIndex)} size="small" label="Shipping Amount" name="shipAmt" value={amt.shipAmt || ''} />
                <TextField variant="outlined" type="number" onChange={(event) => handleAmtChange(event, amt, amtIndex)} size="small" label="Tax Amount" name="taxAmt" value={amt.taxAmt || ''} />
              </div>
            </React.Fragment>
          )}

        </div>
      </Dialog>
    </div>
  );
}
