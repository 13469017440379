import React from 'react';
import StepLoading from './StepLoading';
import ErrorDisplay from './ValidationErrorDisplay';
import ValidDisplay from './ValidationValidDisplay';

import { connect } from 'react-redux';
import { moveStepUp, setErrors } from './uploaderActions'


function FileContentValidatorComponent({ data = [], validation, callback }) {
  const requestArr = data.claimHeaderList;

  React.useEffect(() => {
    // console.log('validator use effect');
    validate(requestArr, data).then((result) => {
      callback(result);
    });
  }, [data, requestArr, callback]);

  if (validation.running) {
    return <StepLoading message={`Validating contents...`} />;
  }

  if (validation.errors && validation.errors.length) {
    return <ErrorDisplay />
  } else {
    return <ValidDisplay />
  }
}

function validate(requestArr = [], data = {}) {
  return new Promise((resolve, reject) => {
    const result = [];

    requestArr.forEach((request, index) => {

      const validator = {
        errors: []
      };

      if ("" + request.clientNumber !== "" + data.clientId) {

        validator.errors.push({
          message: "Invalid Client Number",
          errorCode: "DT-002",
          column: "clientNumber"
        })
      }

      if (!request.transactionNumber) {

        validator.errors.push({
          message: "Invalid Transaction Number",
          errorCode: "DT-002",
          column: "transactionNumber"
        })
      }

      if (validator.errors && validator.errors.length) {
        result.push({
          index: index,
          row: request,
          errors: validator.errors
        });
      }
      resolve(result);
    });
  });
}

const mapStateToProps = (state) => ({
  data: state.entitlement.parsed,
  validation: state.entitlement.validation
});

const mapDispatchToProps = (dispatch) => ({
  callback: (errors) => {
    if (errors && errors.length) {
      dispatch(setErrors(errors));
    } else {
      dispatch(moveStepUp());
    }
  }
});

const FileContentValidator = connect(mapStateToProps, mapDispatchToProps)(FileContentValidatorComponent);
export default FileContentValidator;
