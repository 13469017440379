import config from '../../services/config.json';
import caller from '../../services/caller';
import userManager from '../../services/userManager';

export const servicerService = (function () {
  const service = {
    getServicerDetails: async (clientId) => {
      try {
        const user = userManager.getUser();
        let servicerDetails = [];

        if (user.isServicer() && user.scNumber!== '-1') {
          const sc = await service.getClientServicerBySCNumber(user.scNumber);
          servicerDetails = sc ? [sc] : [];
        } else if (clientId) {
          servicerDetails = await service.getClientServicerDetails(clientId);
        }

        return servicerDetails;

      } catch (error) {
        console.error(error);
      }

      return [];
    },
    getClientServicerBySCNumber: async (scNumber) => {
      try {
        const url = `${config.url.servicerDetails}?scNumber=${scNumber}`;
        const servicerDetails = await caller.get(url);
        const parsed = JSON.parse(servicerDetails);
        return parsed[0];

      } catch (error) {
        console.error(error);
        return null;
      }
    },
    getClientServicerDetails: async (clientId) => {
      try {
        const url = `${config.url.servicerDetails}?clientId=${clientId}`;
        const servicerDetails = await caller.get(url);
        return JSON.parse(servicerDetails);

      } catch (error) {
        console.error(error);
        return [];
      }
    }
  }
  return service;
})();
